import "./App.css";
import "./responsive.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";

import SignUp from "./component/pages/SignUp";
import Main from "./component/layout/main";
import Pricing from "./component/pages/Pricing";
import Cover from "./component/authentication/signup/Cover";
import Illustration from "./component/authentication/signup/Illustration";
import PageFound from "./component/pages/PageFound";
import Parse from "parse";
import { UserProvider } from "./context/UserContext";
import SignIn from "./component/pages/SignIn";
import PasswordRecovery from "./component/pages/PasswordRecovery";
import AcceptQuote from "./component/pages/sales/components/AcceptQuote";
import {ClientSignature} from "./component/pages/inventory";
import Privacy from "./component/pages/Privacy";
import Terms from "./component/pages/Terms";

const libraries = ["places"];

// Initialize Parse
Parse.initialize(
  "0fd71bab-a87f-4534-af06-a59d173902b1",
  "77279e63-3d12-4b83-9d64-4ee439e118e8"
);
Parse.serverURL = "https://app.dasnerpetcare.com/parse";

// Create the router
const router = createBrowserRouter([
  {
    path: "/*",
    element: <Main />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/password-recovery",
    element: <PasswordRecovery />,
  },
  {
    path: "/sales/AcceptQuote/:uuid",
    element: <AcceptQuote />,
  },
  {
    path: "/deliveries/signature/:id",
    element: <ClientSignature />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
]);

function App() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCVDVOKrLpK3K7Lg-EfIYDPNm3LloJ1FuU", // Add your API key here
    libraries,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className="App">
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </div>
  );
}

export default App;