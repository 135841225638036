import React from 'react';

const PoliticaPrivacidad = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', background: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6, color: '#333' }}>
            <h1>Política de Privacidad</h1>
            <p>En Dasner Petcare valoramos tu privacidad y estamos comprometidos con proteger tu información personal. Esta política describe cómo recopilamos, usamos y protegemos tus datos.</p>

            <h2>1. Información que Recopilamos</h2>
            <p>Podemos recopilar la siguiente información cuando utilizas nuestra aplicación:</p>
            <ul>
                <li>Información personal como nombre, correo electrónico y datos de contacto.</li>
                <li>Datos sobre el uso de la aplicación, incluyendo interacción y preferencias.</li>
            </ul>

            <h2>2. Uso de la Información</h2>
            <p>Usamos la información recopilada para:</p>
            <ul>
                <li>Proporcionar y mejorar nuestros servicios.</li>
                <li>Personalizar la experiencia del usuario.</li>
                <li>Contactarte con fines administrativos o de soporte.</li>
            </ul>

            <h2>3. Compartir Información</h2>
            <p>No compartimos tu información personal con terceros, excepto cuando sea necesario para:</p>
            <ul>
                <li>Cumplir con obligaciones legales.</li>
                <li>Proteger nuestros derechos legales.</li>
                <li>Proveer servicios en colaboración con socios de confianza.</li>
            </ul>

            <h2>4. Seguridad</h2>
            <p>Implementamos medidas de seguridad adecuadas para proteger tu información contra accesos no autorizados, alteraciones o divulgaciones.</p>

            <h2>5. Tus Derechos</h2>
            <p>Tienes derecho a acceder, modificar o eliminar tu información personal en cualquier momento. Contáctanos a través de <a href="mailto:emanuel@devtek.mx">emanuel@devtek.mx</a> para ejercer tus derechos.</p>

            <h2>6. Cambios a Esta Política</h2>
            <p>Podemos actualizar esta política de privacidad ocasionalmente. Te recomendamos revisar esta página periódicamente para estar informado sobre cualquier cambio.</p>

            <h2>7. Contacto</h2>
            <p>Si tienes preguntas sobre esta política de privacidad, no dudes en contactarnos a través de <a href="mailto:emanuel@devtek.mx">emanuel@devtek.mx</a>.</p>

            <p>Fecha de última actualización: 21 de enero de 2025</p>
        </div>
    );
};

export default PoliticaPrivacidad;
