import Parse from "parse";

// Update the production order with the event
export const updateProductionOrder = async ({ production, productionEvent, productionCost, weights }) => {
    const totalWeight = weights.ingredientsWeight + weights.waitingHopperWeight;
    const baseCost = productionCost / totalWeight;

    try {
        const query = new Parse.Query("ProductionOrder");
        const productionResult = await query.get(production.objectId);

        if (productionResult) {
            const previousEvents = productionResult.get("productionEvents") || [];
            productionResult.set("productionEvents", [
                ...previousEvents,
                { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
            ]);
            productionResult.set("produced", totalWeight);
            productionResult.set("totalCost", productionCost);
            productionResult.set("baseCost", baseCost);
            productionResult.set("confirmed", true);

            await productionResult.save();
        }
    } catch (error) {
        console.error("Error updating production order:", error);
        throw new Error("Failed to update production order");
    }
};

// Save the weight mixing hopper event
export const saveEvent = async ({ production, weights }) => {
    try {
        const totalWeight = weights.ingredientsWeight + weights.mixingHopperWeight;
        const ProductionEvent = Parse.Object.extend("ProductionEvents");
        const productionEvent = new ProductionEvent();

        productionEvent.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        productionEvent.set("event", "confirmProduction");
        productionEvent.set("warehouse", "Producción");
        productionEvent.set("produced", totalWeight);

        return await productionEvent.save();
    } catch (error) {
        console.error("Error saving event:", error);
        throw new Error("Failed to save production event");
    }
};

// Set hopper content
export const setHopperContent = async (production, weights, hopper) => {
    try {
        const query = new Parse.Query("ProductionItems");
        const hopperResult = await query.get(hopper.objectId);

        if (hopperResult) {
            hopperResult.set("product", { __type: "Pointer", className: "Products", objectId: production.product.objectId });
            hopperResult.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
            hopperResult.set("content", weights.storageHopperWeight);

            await hopperResult.save();
        }
    } catch (error) {
        console.error("Error adding to hopper:", error);
        throw new Error("Failed to set hopper content");
    }
};

// Add stock
export const addStock = async ({ production, weights, productionCost }) => {
    const totalWeight = weights.ingredientsWeight + weights.waitingHopperWeight;
    const baseCost = productionCost / totalWeight;

    try {
        const query = new Parse.Query("Product");
        const productResult = await query.get(production.product.objectId);

        if (productResult) {
            const productCost = productResult.get("baseCost") || 0;
            const productStock = productResult.get("stock") || 0;

            const newCost = ((productCost * productStock) + (baseCost * totalWeight)) / (productStock + totalWeight);

            productResult.set("baseCost", newCost);
            productResult.increment("stock", totalWeight);

            await productResult.save();
        }
    } catch (error) {
        console.error("Error adding stock:", error);
        throw new Error("Failed to add stock");
    }
};

// Add stock movement
export const addStockMovement = async ({ production, weights, productionEvent }) => {
    const totalWeight = weights.ingredientsWeight + weights.waitingHopperWeight;

    try {
        const query = new Parse.Query("Product");
        const product = await query.get(production.product.objectId);

        if (product) {
            const StockMovement = Parse.Object.extend("StockMovement");
            const stockMovement = new StockMovement();

            stockMovement.set("product", { __type: "Pointer", className: "Product", objectId: product.objectId });
            stockMovement.set("quantity", totalWeight);
            stockMovement.set("type", "Producción");
            stockMovement.set("previousStock", product.get("stock"));
            stockMovement.set("currentStock", product.get("stock") + totalWeight);
            stockMovement.set("user", Parse.User.current());
            stockMovement.set("warehouse", "Producción");
            stockMovement.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
            stockMovement.set("productionEvent", { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id });

            await stockMovement.save();
        }
    } catch (error) {
        console.error("Error updating stock or movements:", error);
        throw new Error("Failed to add stock movement");
    }
};

export const calculateCost = async ({ production }) => {
    console.log("Calculating cost for production:", production);
    try {
        // Filter ingredient-related events
        const ingredientEvents = production?.productionEvents?.filter((event) => event.event === "addIngredients");
        console.log("Ingredient Events:", ingredientEvents);

        // Extract unique ingredient IDs from events
        const ingredientIds = [
            ...new Set(
                ingredientEvents.flatMap((event) => event.ingredients?.map((ingredient) => ingredient.objectId))
            )
        ];
        console.log("Ingredient IDs:", ingredientIds);

        if (ingredientIds.length === 0) {
            console.warn("No ingredient IDs found in events. Returning cost as 0.");
            return 0;
        }

        // Fetch ingredient details from Parse server
        const ingredients = await new Parse.Query("Ingredient").containedIn("objectId", ingredientIds).find();
        console.log("Fetched Ingredients:", ingredients);

        // Map ingredient IDs to their costs for quick lookup
        const ingredientCostMap = new Map(
            ingredients?.map((ingredient) => [ingredient.id, ingredient.get("baseCost") || 0])
        );

        // Calculate total cost
        const totalCost = ingredientEvents.reduce((acc, event) => {
            const eventCost = event.ingredients.reduce((eventAcc, ingredient) => {
                const cost = ingredientCostMap.get(ingredient.objectId) || 0;
                return eventAcc + ingredient.quantity * cost;
            }, 0);
            return acc + eventCost;
        }, 0);

        console.log("Total Cost:", totalCost);
        return totalCost;
    } catch (error) {
        console.error("Error calculating cost:", error);
        throw new Error("Failed to calculate production cost");
    }
};
