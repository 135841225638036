import React from "react";
import { Card, Statistic, Row, Col } from "antd";

const ClientBalance = ({ client, paymentDiff }) => (
  <>
    <Card title="Balance Financiero" style={{ width: 300 }}>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            value={client?.balance}
            valueStyle={{ color: client?.balance < 0 ? "#cf1322" : "#3f8600" }}
            prefix={"$"}
          />
        </Col>
      </Row>
    </Card>
    <Card title="Estadísticas" style={{ width: 300 }}>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title="Pago en tiempo"
            value={`${parseInt(paymentDiff)} días`}
            valueStyle={{ color: paymentDiff < 0 ? "#cf1322" : "#3f8600" }}
          />
        </Col>
      </Row>
    </Card>
  </>
);

export default ClientBalance;