import React, { useState } from "react";
import { Typography, Button, Space, Badge } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NewPresentation from "../modals/NewPresentation";

const { Title, Text } = Typography;

const PresentationList = ({ recipe, presentations, setPresentations }) => {
    const [newPresentationOpen, setNewPresentationOpen] = useState(false);
    const [currentPresentation, setCurrentPresentation] = useState(null);
    const [renderId, setRenderId] = useState(0);

    // Handle card removal
    const handleRemove = (index) => {
        const updatedPresentations = presentations.filter((_, i) => i !== index);
        setPresentations(updatedPresentations);
    };

    // Render individual presentation card
    const renderPresentationCard = (presentation, index) => (
        // <Badge
        //     key={index}
        //     count="X"
        //     onClick={() => handleRemove(index)}
        //     style={{ cursor: "pointer" }}
        // >
            <Button
                style={{
                    width: "180px",
                    height: "240px",
                    padding: "10px",
                    textAlign: "left",
                }}
                onClick={() => {
                    setNewPresentationOpen(true);
                    setCurrentPresentation(presentation);
                }}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Title
                        level={5}
                        ellipsis={{
                            rows: 1,
                            tooltip: presentation.name,
                        }}
                    >
                        {presentation.name}
                    </Title>
                    <Text
                        ellipsis={{
                            rows: 1,
                            tooltip: `${presentation.content} kg`,
                        }}
                    >
                        {presentation.content} kg
                    </Text>
                    <Text level={3} type="secondary">
                        Costo: ${(presentation.baseCost * presentation.content).toFixed(2)}
                    </Text>
                    <Text level={3} type="secondary">
                        Precio mínimo: ${presentation.minPrice.toFixed(2)}
                    </Text>
                    <div>
                        {presentation?.packaging?.map((pack, idx) => (
                            <Text
                                key={idx}
                                ellipsis={{
                                    rows: 1,
                                    tooltip: `${pack.quantity} ${pack.name}`,
                                }}
                                style={{ display: "block" }}
                            >
                                {pack.quantity} {pack.name}
                            </Text>
                        ))}
                    </div>
                </Space>
            </Button>
        // </Badge>
    );

    return (
        <Space direction="horizontal" style={{ width: "100%", flexWrap: "wrap" }}>
            {/* New Presentation Modal */}
            <NewPresentation
                open={newPresentationOpen}
                setOpen={setNewPresentationOpen}
                addPresentation={(presentation) => {
                    // If presentation exists, update it
                    setPresentations((prev) =>
                        currentPresentation
                            ? prev.map((p, i) => (i === renderId ? presentation : p))
                            : [...prev, presentation]
                    );
                    setRenderId(renderId + 1);
                }}
                recipe={recipe}
                item={currentPresentation}
            />

            {/* Presentation Cards */}
            {presentations?.map((presentation, index) =>
                renderPresentationCard(presentation, index)
            )}

            {/* Add New Presentation Button */}
            <Button
                style={{ width: "180px", height: "240px" }}
                type="dashed"
                onClick={() => {
                    setNewPresentationOpen(true)
                    setCurrentPresentation(null)
                }}
            >
                <Space direction="vertical">
                    <PlusOutlined />
                    <Title level={5} style={{ margin: 0 }}>
                        Agregar presentación
                    </Title>
                </Space>
            </Button>
        </Space>
    );
};

export default PresentationList;