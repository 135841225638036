import React, { useEffect, useState } from "react";
import { Button, Table, Input, message, Card, Row, Col } from "antd";
import { allowSave } from "./utils";
import { saveEvent } from "./services";
import MixingHopperIngredients from "./MixingHopperIngredients";

const MixingHopper = ({ production, refresh }) => {
    const [weights, setWeights] = useState([]);
    

    useEffect(() => {
        if (production) {
            const weightMixingHopper = production?.productionEvents?.find((event) => event.event === "weightMixingHopper");
            if (weightMixingHopper) {
                setWeights({ ...weightMixingHopper.weights, fromEvent: true });
                return;
            }
            const initialIngredients = production.recipe.ingredients
                .filter((ingredient) => ingredient.warehouse === "Forrajes")
                .map((ingredient) => ({
                    ...ingredient,
                    pending: 0,
                    adding: 0,
                }));

            const expectedWeight = initialIngredients.reduce((acc, ingredient) => acc + ingredient.quantity /  production?.recipe?.finalWeight * production?.productionQuantity, 0);
            const ingredientsWeight = production?.mixingHopper?.ingredients.reduce((acc, ingredient) => acc + ingredient.quantity, 0);
            setWeights({ expectedWeight, ingredientsWeight, totalWeight: 0 });
        }
    }, [production]);

    const handleSave = async () => {
        if (!allowSave(weights)) {
            message.error("La diferencia en el peso no puede ser mayor al 10%");
            return;
        }
        try {
            await saveEvent(production, weights);
            message.success("Evento guardado correctamente");
            refresh();
        } catch (error) {
            console.error("Error saving event:", error);
            message.error("Error al guardar el evento.");
        }
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card title={production.mixingHopper ? production.mixingHopper.name : "Sin tolva asignada"}>
                    <MixingHopperIngredients ingredients={production?.mixingHopper?.ingredients} />
                </Card>
            </Col>
            <Col span={8}>
                <Card title="Peso esperado">{weights?.expectedWeight} kg</Card>
            </Col>
            <Col span={8}>
                <Card title="Peso en ingredientes">{weights?.ingredientsWeight} kg</Card>
            </Col>
            <Col span={8}>
                <Card title="Peso total">
                    {weights.fromEvent ? (
                        `${weights?.totalWeight || 0} kg`
                    ) : (
                        <Input
                            style={{ width: "80%" }}
                            placeholder="Cantidad"
                            type="number"
                            onChange={(e) => setWeights({ ...weights, totalWeight: parseFloat(e.target.value) })}
                        />
                    )}
                </Card>
            </Col>
            <Button
                type="primary"
                onClick={handleSave}
                style={{ marginTop: "20px", alignSelf: "flex-end" }}
                disabled={!weights.totalWeight || weights.fromEvent}
            >
                Guardar
            </Button>
        </Row>
    );
};

export default MixingHopper;