import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select, message, Popconfirm} from "antd";
import Parse from "parse";
import DebounceSelect from "../../../form/DebounceSelect";
import ProductList from "./ProductList";
import { fetchClient, fetchProducts, fetchSeller } from "../utils/query";
import AddressAutocomplete from "../../../form/PlacesSelect";
import { padStart } from "@fullcalendar/core/internal";
import { UserContext } from "../../../../context/UserContext";
import { v4 as uuidv4 } from 'uuid';
const { Title } = Typography;

function toTwoDecimal(input) {
    // Convert the input to a float
    const number = parseFloat(input);

    // Check if it's a valid number, return 0 if not
    if (isNaN(number)) {
        return 0;
    }

    // Round to two decimal places and return
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

const fetchPresentations  = async (products) => {
    const Presentation = Parse.Object.extend("Presentation");
    const query = new Parse.Query(Presentation);
    query.containedIn("product", products);
    query.include("product");
    const results = await query.find();
    return results.map((result) => result.toJSON());
};


const transformProducts = (products) => {
    return products.map((product) => {
        return {
            "objectId": product.objectId,
            "name": product.name,
            "minPrice": product.minPrice,
            "price": toTwoDecimal(product.price),
            "quantity": parseInt(product.quantity),
            "total": toTwoDecimal(product.total),
            "presentation": { __type: "Pointer", className: "Presentation", objectId: product.presentation.objectId, name: product.presentation.name },
        }
    })
}

const NewQuote = ({open, setOpen, refresh, editing, sale, refreshSale, versionNumber}) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { permissions, user } = useContext(UserContext);


  const allowSetSeller = () => {
    console.log("permissions", permissions);
    console.log("permissions", permissions.find(p => p?.group === 'quotes' && p?.action === 'setSeller') ? true : false);
    return permissions.find(p => p?.group === 'quotes' && p?.action === 'setSeller') ? true : false;
  }

    
    const handleCancel = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({ products: [] });
        if (user) {
            form.setFieldsValue({ seller: {
                key: user.objectId,
                label: user.fullName,
                value: user.fullName,
                ...user
            }});
        }

        if (editing) {
            console.log("editing", sale);
            form.setFieldsValue({
                client: {
                    key: sale.client.objectId,
                    label: sale.client.fullName,
                    value: sale.client.fullName,
                    ...sale.client
                },
                seller: {
                    key: sale.seller.objectId,
                    label: sale.seller.fullName,
                    value: sale.seller.fullName,
                    ...sale.seller
                },
                // deliveryDate: new Date(sale.deliveryDate?.iso),
                deliveryPlace: sale.deliveryPlace,
                deliveryAddress: sale.deliveryAddress,
                deliveryPrice: sale.deliveryPrice,
                deliveryNotes: sale.deliveryNotes,
                products: sale.products,
                notes: sale.notes
            })
        }
    }, [open, user, editing]);

    useEffect(() => {
        allowSave();
    }, [form.getFieldsValue()]);

    const allowSave = () => {
       let error = false;
        const values = form.getFieldsValue();
        console.log("values", values);
        if (!values.client || !values.deliveryDate || !values.deliveryPlace || !values.products || !values.products.length || (editing && !values.modificationComment)) {
            error = true;
        }
        setDisableSave(error);
    };
    

   const calculateTotal = () => {
        const products = form.getFieldValue("products");
        const deliveryPrice = form.getFieldValue("deliveryPrice") || 0;
        let total = products?.reduce((acc, product) => acc + product.total, 0) + Number(deliveryPrice);
        total = total.toFixed(2);
        return !isNaN(total) ? total : 0;
    }

    const handleAddressSelect = (value, place, setValue, clearSuggestions, getGeocode, getLatLng) => {
        let deliveryAddress = {}
        console.log("📍 Address: ", place);
        const { description, terms } = place;
        setValue(description, false);
        clearSuggestions();
    
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            // console.log("📍 Coordinates: ", { lat, lng });
            deliveryAddress = {
                address: description,
                lat: lat,
                lng: lng,
                terms
            };
            console.log("deliveryAddress", deliveryAddress);
            form.setFieldsValue({ deliveryAddress });
            setDeliveryAddress(deliveryAddress);
        })
        .catch((error) => {
            console.log("😱 Error: ", error);
        });

        

    };

    console.log("form", form.getFieldValue("deliveryAddress"));

    const saveNewQuote = async () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values);

        const Index = Parse.Object.extend("Index");
        const query = new Parse.Query(Index);
        query.equalTo("name", "Sale");
        const index = await query.first();

        const Sales = Parse.Object.extend("Sales");
        const sale = new Sales();
        sale.set("client", {
            __type: "Pointer",
            className: "_User",
            objectId: values.client.key,
        });
        sale.set("seller", {
            __type: "Pointer",
            className: "_User",
            objectId: values.seller.key,
        });
        sale.set("deliveryDate", new Date(values.deliveryDate));
        sale.set("deliveryPlace", values.deliveryPlace);
        sale.set("deliveryAddress", values.deliveryAddress);
        sale.set("deliveryPrice", values.deliveryPrice);
        sale.set("deliveryNotes", values.deliveryNotes);
        sale.set("products", transformProducts(values.products));
        sale.set("notes", values.notes);
        sale.set("total", parseFloat(calculateTotal()));
        sale.set("quoteNumber", "C" + padStart((index.get("value")+1).toString(), 6, "0") + "-" + new Date().getFullYear());
        sale.set("status", "Cotización");
        sale.set("uuid", uuidv4());
        sale.set("versionNumber", 1);
        sale.set("active", true);
        
        sale.save().then(
            (result) => {
                index.increment("value");
                index.save();
                console.log("Sales created", result);
                message.success("Cotización creada correctamente");
                setOpen(false);
                refresh();
            },
            (error) => {
                console.error("Error while creating Sales: ", error);
                message.error("Error al crear la cotización");
            }
        );
    };

    const saveEditQuote = async () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values);

        const Sales = Parse.Object.extend("Sales");
        const saleObject = new Sales();

        saleObject.set("client", {__type: "Pointer", className: "_User", objectId: values.client.key});
        saleObject.set("seller", {__type: "Pointer", className: "_User", objectId: values.seller.key});
        saleObject.set("deliveryDate", new Date(values.deliveryDate));
        saleObject.set("deliveryPlace", values.deliveryPlace);
        saleObject.set("deliveryAddress", values.deliveryAddress);
        saleObject.set("deliveryPrice", values.deliveryPrice);
        saleObject.set("deliveryNotes", values.deliveryNotes);
        saleObject.set("products", values.products);
        saleObject.set("notes", values.notes);
        saleObject.set("total", parseFloat(calculateTotal()));
        saleObject.set("quoteNumber", sale.quoteNumber);
        saleObject.set("status", 'Cotización');
        saleObject.set("versionNumber", versionNumber + 1);
        saleObject.set("modificationComment", values.modificationComment);

        const previousSale = new Sales();
        previousSale.set("objectId", sale.objectId);
        previousSale.set("active", false);
        
        Parse.Object.saveAll([saleObject, previousSale]).then(
            (result) => {
                console.log("Sales created", result);
                message.success("Cotización editada correctamente");
                setOpen(false);
                refreshSale();
            },
            (error) => {
                console.error("Error while creating Sales: ", error);
                message.error("Error al crear la cotización");
            }
        );
        setConfirmLoading(false);
    };


    const handleSave = async () => {
        if (editing) {
            setConfirmLoading(true);
        } else {
            setConfirmLoading(false);
            saveNewQuote();
        }
    }
    
    return (
        <Modal
        title={editing ? "Editar cotización" : "Nueva cotización"}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        >
        <Form form={form} layout="vertical">
            <Form.Item label="Cliente" name="client" rules={[{ required: true, message: "Selecciona un cliente" }]}>
            <DebounceSelect
                fetchOptions={fetchClient}
                placeholder="Buscar cliente"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            </Form.Item>
            <Form.Item label="Vendedor" name="seller" rules={[{ required: true, message: "Selecciona un vendedor" }]}>
            <DebounceSelect
                fetchOptions={fetchSeller}
                placeholder="Buscar vendedor"
                style={{ width: "100%" }}
                allowClear
                showSearch
                disabled={!allowSetSeller()}
            />
            </Form.Item>
            <Space direction="horizontal" style={{ width: "100%" }}>
            <Form.Item label="Fecha de entrega" name="deliveryDate" rules={[{ required: true, message: "Selecciona una fecha" }]} shouldUpdate>
                <DatePicker style={{ width: "100%" }} placeholder="Selecciona una fecha" />
            </Form.Item>
            <Form.Item label="Lugar de entrega" name="deliveryPlace" rules={[{ required: true, message: "Selecciona un lugar de entrega" }]}>
                <Select placeholder="Selecciona un lugar de entrega" style={{ width: "100%" }}
                    onChange={(value) => {
                        form.setFieldsValue({ deliveryAddress: undefined, deliveryPrice: undefined });
                        setRenderId((prev) => prev + 1);
                    }} >
                    <Select.Option value="Domicilio">Domicilio</Select.Option>
                    <Select.Option value="Pie de fábrica">Pie de fábrica</Select.Option>
                </Select>
            </Form.Item>
            </Space>

            {form.getFieldValue("deliveryPlace") === "Domicilio" && (
                <>
                <Form.Item label="Dirección" name="deliveryAddress" rules={[{ required: true, message: "Ingresa una dirección" }]}>
                    <AddressAutocomplete onSelect={handleAddressSelect} />
                </Form.Item>
                <Form.Item label="Flete" name="deliveryPrice" rules={[{ required: true, message: "Ingresa un precio para el flete" }]}>
                    <Input onBlur={() => setRenderId((prev) => prev + 1)} type="number" placeholder="Precio del flete" onClick={(e) => e.target.select()} onClear={() => setRenderId((prev) => prev + 1)} />
                </Form.Item>
                <Form.Item label="Notas del domicilio" name="deliveryNotes">
                    <Input placeholder="Información adicional" />
                </Form.Item>
                </>
            )}

           

            <Form.Item label="Productos" name="products" rules={[{ required: true, message: "Selecciona al menos un producto" }]}>
                <ProductList form={form} setRenderId={setRenderId} />
            </Form.Item>

            {!editing ? <Form.Item label="Notas" name="notes">
                <Input.TextArea placeholder="Información adicional" />
            </Form.Item> : <Form.Item label="Motivo de modificación" name="modificationComment">
                <Input placeholder="Motivo de modificación" />
            </Form.Item>}
            
        </Form>
        <Space direction="horizontal" style={{ width: "100%" }}>
            <Title level={4}>Total: ${String(calculateTotal()).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
        </Space>
        <Popconfirm
            title="Editando cotización"
            description="Guardar esta cotización la marcará como activa"
            open={confirmLoading}
            onConfirm={saveEditQuote}
            onCancel={() => setConfirmLoading(false)}
            >
            <Button type="primary" htmlType="submit" style={{ position: "absolute", bottom: 20, right: 20 }} disabled={disableSave} onClick={handleSave}>
                    Guardar
            </Button>
            </Popconfirm>
        </Modal>
    );
    }

export default NewQuote;