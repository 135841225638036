import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Card, Segmented, Select, Row, Col } from "antd";
import Parse from "parse";
import moment from "moment";

import ReactApexChart from "react-apexcharts";
import { MinusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const options = {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#1890FF", "#B37FEB"],
      width: 3,
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
        offsetY: 0,
        formatter: function (val) {
            return val.toLocaleString();
            }
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(0, 0, 0, .2)",
      strokeDashArray: 6,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
    
  };
  

    const queryEntries = async (objectId) => {
        try {
          const query = new Parse.Query("WarehouseEntry");
          query.include(["user", "purchaseOrder"]);
          query.exclude(["images", "productPointers"]);
          query.containedBy('productPointer',[{__type: "Pointer", className: "Product", objectId: objectId}]);
          query.descending("createdAt");
    
          const results = await query.find();
          return results.map((result) => ({
            ...result.toJSON(),
          product: result.get("products")?.find((p) => p.objectId === objectId),
          }));
        } catch (error) {
          console.error("Error while fetching entries", error);
        }
      };

const generateCostSeries = (item, entries, goupBy) => {
    let formatString = "YYYY-MM";
    const costSeries = [{
        name: 'Costo unitario',
        data: [],
        offsetY: 0,
    }];
    let entriesSeries = [{
        name: 'Entradas',
        data: [],
        offsetY: 0,
    }];
    let data = [];
    switch (goupBy) {
        case 'day':
            formatString = "YYYY-MM-DD";
            break;
        case 'month':
            formatString = "YYYY-MM";
            break;
        case 'year':
            formatString = "YYYY";
            break;
        default:
            break;
    }

    const sortedEntries = entries.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));

    console.log("sortedEntries", sortedEntries);

    sortedEntries.forEach((entry) => {
        const product = entry.products?.find((p) => p.objectId === item.objectId);
        console.log("product", product);
        const date = moment(entry.createdAt).format(formatString);
        const cost = product?.baseUnitCost 
        console.log("cost", cost);
        if (!data[date]) {
          data[date] = {totalCost: 0, totalQuantity: 0, entryCount: 0};
        }
        data[date].totalCost += cost * parseInt(product?.baseUnitQuantity) || 0;
        // TODO: Revisar que quantity este siendo cargado correctamente.
        data[date].totalQuantity += product?.baseUnitQuantity ? parseInt(product?.baseUnitQuantity) : 0;
        data[date].entryCount += 1;
        console.log("data", data[date]);
        
    });
    console.log("data", data);

    // // Fill the gaps in the data
    // const startDate = moment(sortedEntries[0].createdAt).startOf(goupBy).add(-1, 'month');
    // const endDate = moment(sortedEntries[sortedEntries.length - 1].createdAt).endOf(goupBy).add(1, 'month');
    // let currentDate = startDate;
    // while (currentDate.isBefore(endDate)) {
    //     const date = currentDate.format(formatString);
    //     if (!data[date]) {
    //         data[date] = {
    //             totalCost: undefined,
    //             totalQuantity: undefined,
    //             entryCount: undefined,
    //         };
    //     }
    //     currentDate = currentDate.add(1, goupBy);
    // }

    Object.keys(data).forEach((key) => {
        costSeries[0].data.push({
            x: key,
            y: parseFloat((data[key].totalCost / data[key].totalQuantity).toFixed(2)),
        });
        entriesSeries[0].data.push({
            x: key,
            y: data[key].totalQuantity,
        });
    });

    return { costSeries, entriesSeries };
}


const IngredientCharts = ({ item }) => {
    const [entries, setEntries] = useState([]);
    const [costSeries, setCostSeries] = useState([]);
    const [entriesSeries, setEntriesSeries] = useState([]);
    const [outputSeries, setOutputSeries] = useState([]);
    const [groupBy, setGroupBy] = useState('month');

    useEffect(() => {
        console.log("item", item);
        if (item) {
            queryEntries(item.objectId).then((results) => {
                console.log("results", results);
                setEntries(results);
            });
        }
    }, [item]);

    useEffect(() => {
        const { costSeries, entriesSeries } = generateCostSeries(item, entries, groupBy);
        console.log("costSeries", costSeries);
        console.log("entriesSeries", entriesSeries);
        setCostSeries(costSeries);
        setEntriesSeries(entriesSeries);
    }, [entries, groupBy]);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="horizontal" style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Estadísticas</h2>
            <Space direction="horizontal">
            <h4>Agrupar por</h4>
            <Select
                defaultValue={groupBy}
                style={{ width: 120 }}
                onChange={(value) => setGroupBy(value)}
            >
                <Select.Option value="day">Día</Select.Option>
                <Select.Option value="month">Mes</Select.Option>
                <Select.Option value="year">Año</Select.Option>
            </Select>
            </Space>
            </Space>
        <Row gutter={[24, 0]}>
            <Col span={12}>
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li><MinusOutlined /> Entradas</li>
                  <li><MinusOutlined /> Salidas</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={{...options, tooltip: {
                y: {
                  formatter: function (val) {
                    return `${val.toLocaleString()}`;
                  },
                },
              }}}
              series={entriesSeries}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
        </Col>
        <Col span={12}>
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li><MinusOutlined /> Costo unitario</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={{...options, tooltip: {
                y: {
                  formatter: function (val) {
                    return `$${val.toLocaleString()}`;
                  },
                },
              }}}
              series={costSeries}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
        </Col>
        </Row>
        </Space>
    );
}

export default IngredientCharts;