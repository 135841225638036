import React from "react";
import { Card, Form, Row, Col, Input } from "antd";

const ClientGeneralInfo = ({ client }) => (
  <Card
    id="basic-info"
    className="mb-24 header-solid"
    bordered={false}
    title={<h5 className="mb-0">Información del cliente</h5>}
  >
    <Form layout="vertical">
      <Row gutter={[30, 20]}>
        <Col span={24} lg={12}>
          <Form.Item label="Nombre del cliente">
            <Input value={client?.fullName} readOnly />
          </Form.Item>
        </Col>
        {client?.clientType === "moral" && (
          <Col span={24} lg={12}>
            <Form.Item label="Persona de contacto">
              <Input value={client?.contactPerson} readOnly />
            </Form.Item>
          </Col>
        )}
        <Col span={24} lg={12}>
          <Form.Item label="Email">
            <Input value={client?.email} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Teléfono">
            <Input value={client?.phoneNumber} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Card>
);

export default ClientGeneralInfo;