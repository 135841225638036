import Parse from "parse";

// Update the production order with the event
export const updateProductionOrder = async (production, productionEvent) => {
    try {
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const query = new Parse.Query(ProductionOrder);
        query.equalTo("objectId", production.objectId);
        const productionResult = await query.first();

        if (productionResult) {
            const previousEvents = productionResult.get("productionEvents") || [];
            productionResult.set("productionEvents", [
                ...previousEvents,
                { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
            ]);
            await productionResult.save();
        }
    } catch (error) {
        console.error("Error updating production order:", error);
        throw error;
    }
};

// Save the weight mixing hopper event
export const saveEvent = async (production, weights) => {
    try {
        const ProductionEvents = Parse.Object.extend("ProductionEvents");
        const productionEvent = new ProductionEvents();

        productionEvent.set("event", "weightWaitingHopper");
        productionEvent.set("productionOrder", {
            __type: "Pointer",
            className: "ProductionOrder",
            objectId: production.objectId,
        });
        productionEvent.set("warehouse", "Forrajes");
        productionEvent.set("weights", {...weights, fromEvent: true});

        const savedEvent = await productionEvent.save();
        await updateProductionOrder(production, savedEvent);
    } catch (error) {
        console.error("Error saving event:", error);
        throw error;
    }
};

export const setHopperContent = async (production, weights, hopper) => {
    const ProductionItems = Parse.Object.extend("ProductionItems");
    const query = new Parse.Query(ProductionItems);
    query.equalTo("objectId", hopper.objectId);

    try {
        const hopperResult = await query.first();

        if (hopperResult) {
            hopperResult.set("product", { __type: "Pointer", className: "Products", objectId: production.product.objectId });
            hopperResult.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
            hopperResult.set("content", weights.waitingHopperWeight);
            await hopperResult.save();
        }
    } catch (error) {
        console.error("Error adding to hopper:", error);
        throw error;
    }
}