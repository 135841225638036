import React from "react";
import { Button, Card, Table, Typography, Modal, Image, Row, Col } from "antd";
import moment from "moment";

const { Title, Text } = Typography;

const DeliveryDetail = ({ open, setOpen, delivery, refresh }) => {
    console.log("📦 Order: ", delivery);

    if (!delivery) return null;

    return (
        <Modal
            title="Detalle de Entrega"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={1000}
            bodyStyle={{ padding: "20px" }}
        >
            <Card bordered={false} style={{ marginBottom: "20px" }}>
                <Title level={4}>Información de la Entrega</Title>
                <Row gutter={16}>
                    <Col span={12}>
                        <Text strong>Fecha de Entrega:</Text>{" "}
                        <Text>{moment(delivery.deliveryDate).format("DD/MM/YYYY")}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong>Entregado por:</Text>{" "}
                        <Text>{delivery.deliveredBy?.fullName || "N/A"}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong>Recibido por:</Text>{" "}
                        <Text>{delivery.receivedBy || "N/A"}</Text>
                    </Col>
                    <Col span={24}>
                        <Text strong>Comentarios:</Text>{" "}
                        <Text>{delivery.comments || "Sin comentarios"}</Text>
                    </Col>
                </Row>
            </Card>

            <Title level={4} style={{ marginBottom: "10px" }}>
                Productos
            </Title>
            <Table
                dataSource={delivery?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        align: "center",
                    },
                    {
                        title: "Entregado",
                        dataIndex: "delivered",
                        key: "delivered",
                        align: "center",
                    },
                ]}
                pagination={false}
                rowKey={(record) => record.name}
                style={{ marginBottom: "20px" }}
            />

            <Row gutter={16} style={{ marginBottom: "20px" }}>
                {delivery?.clientSignature && (
                    <Col span={12}>
                        <Card hoverable>
                            <Title level={5} style={{ textAlign: "center" }}>
                                Firma del Cliente
                            </Title>
                            <Image
                                src={delivery.clientSignature.image}
                                alt="Client Signature"
                                style={{ display: "block", margin: "0 auto", width: "80%" }}
                                preview={false}
                            />
                            <Text style={{ display: "block", textAlign: "center", marginTop: "10px" }}>
                                {delivery.clientSignature.name}
                            </Text>
                        </Card>
                    </Col>
                )}
                {delivery?.carrierSignature && (
                    <Col span={12}>
                        <Card hoverable>
                            <Title level={5} style={{ textAlign: "center" }}>
                                Firma del transportista
                            </Title>
                            <Image
                                src={delivery.carrierSignature.image}
                                alt="Carrier Signature"
                                style={{ display: "block", margin: "0 auto", width: "80%" }}
                                preview={false}
                            />
                        </Card>
                    </Col>
                )}
            </Row>

            {delivery?.images?.length > 0 && (
                <div>
                    <Title level={4} style={{ marginBottom: "10px" }}>
                        Imágenes de la Entrega
                    </Title>
                    <Row gutter={[16, 16]}>
                        {delivery.images.map((image, index) => (
                            <Col span={6} key={index}>
                                <Image
                                    src={image}
                                    alt={`Delivery Image ${index + 1}`}
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    preview={true}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </Modal>
    );
};

export default DeliveryDetail;
