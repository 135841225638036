import React from "react";
import { Row, Col, Card, Table, Progress, Flex } from "antd";
import { MinusOutlined } from '@ant-design/icons';
import ReactApexChart from "react-apexcharts";
import { Statistic } from 'antd';
import moment from "moment";


const dataSource = [
  {
    Date: "16/06/2024",
    Order: "D0001",
    Product: 'Croqueta para perro',
    Quantity: '100 kg',
    cost: '$2,450.00',
  },
];

const columns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
  },
  {
    title: "Folio",
    dataIndex: "saleNumber",
    key: "saleNumber",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Producto",
    dataIndex: "products",
    key: "products",
    render: (products) => <h3>{products?.map((product) => product.name).join(", ")}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "products",
    key: "products",
    render: (products) => <h3>{products?.map((product) => product.quantity).join(", ")} pzs</h3>,
  },
  {
    title: "Monto",
    dataIndex: "total",
    key: "total",
    render: (text) => <h3>{`$${text?.toLocaleString()}`}</h3>,
  },
];

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#1890FF", "#B37FEB"],
    width: 3,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: -50,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: [
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
        ],
      },
    },
    categories: [
      "Ene",
      "Feb",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};


const ClientPurchases = ({client}) => {
  const [chartSeries, setChartSeries] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const series = [];
    const categories = [];
    // Sum sales by month
    client?.sales?.forEach((sale) => {
      const month = moment(sale.createdAt).format("MMM");
      const index = categories.indexOf(month);
      if (index === -1) {
        categories.push(month);
        series.push({ name: month, data: [sale.total], offsetY: 0 });
      } else {
        series[index].data.push(sale.total);
      }
    });
    if  (chartSeries.length === 0) setChartSeries(undefined);
    else setChartSeries(series);
    setLoading(false);
  }, [client]);
    
  return (
    <Row gutter={[24, 0]}>
      <Col span={16} lg={16} className="zindex">
        <Card title={<h2>Objetivos</h2>} style={{padding:20}}
        bordered={false}
        className="criclebox cardbody mb-24 header-solid">
              <Flex style={{ padding: 20 }}>
              <Statistic
              style={{ padding: 20, width: "50%" }}
                title="Ventas"
                value={client?.salesGoal}
                precision={2}
                prefix={"$"}
               />
               <Progress
              style={{ padding: 20, width: "50%" }}
              percent={client?.sales?.reduce((acc, sale) => acc + sale.total, 0) / client?.salesGoal * 100}
              format={percent => `${parseFloat(percent).toFixed(2)}%`}
              
               />
                </Flex>
        </Card>
      </Col>
     
      <Col span={32} lg={24} className="zindex">
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Ventas</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li>{<MinusOutlined />} Kilos</li>
                  <li>{<MinusOutlined />} Pesos</li>
                </ul>
              </div>
            </div>

            {chartSeries && chartSeries.length !== 0 && <ReactApexChart
              className="full-width"
              options={options}
              series={chartSeries}
              type="area"
              height={350}
              width={"100%"}
            />}
          </div>
        </Card>
      </Col>
      <Col span={32} lg={24} className="zindex">
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Detalle de ventas</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={client?.sales}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>

  );
};


export { ClientPurchases };


