import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
import { setACL } from "../../../../utils/ACL";
import { findErrorMessage } from "../../../../utils/parseErrors";
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;

const NewSeller = ({ open, setOpen, refresh, seller, editing }) => {
    const [form] = Form.useForm();

    useEffect(() => {
       if (editing && seller) {
           form.setFieldsValue({
               firstName: seller.firstName,
               lastName: seller.lastName,
               email: seller.email,
               phoneNumber: seller.phoneNumber,
                comissionPercentage: seller.comissionPercentage,
                salesGoal: seller.salesGoal,
           });
       } else {
           form.resetFields();
       }
    }, [open, seller, editing]);

    const createSeller = async () => {
        const seller = form.getFieldsValue();
        try {
            const newSeller = new Parse.User();
            newSeller.set("username", seller.email?.trim()?.toLowerCase());
            newSeller.set("password", generateRandomPassword());
            newSeller.set("firstName", seller.firstName?.trim());
            newSeller.set("lastName", seller.lastName?.trim());
            newSeller.set("fullName", `${seller.firstName?.trim()} ${seller.lastName?.trim()}`);
            newSeller.set("email", seller.email?.trim()?.toLowerCase());
            newSeller.set("phoneNumber", seller.phoneNumber?.trim());
            newSeller.set("comissionPercentage", parseInt(seller.comissionPercentage) || 0);
            newSeller.set("salesGoal", parseInt(seller.salesGoal) || 0);
            newSeller.set("uuid", uuidv4());
            newSeller.setACL(setACL("Ventas"));
    
            const result = await newSeller.save();
    
            // Add user to the "Ventas" role
            const roleQuery = new Parse.Query(Role);
            roleQuery.equalTo("name", "Ventas");
            const role = await roleQuery.first();
            if (role) {
                role.getUsers().add(newSeller);
                await role.save();
            } else {
                console.error("Role 'Ventas' not found");
            }
    
            console.log("Seller created", result);
            message.success("Nuevo vendedor agregado correctamente");
            refresh();
            setOpen(false);
        } catch (error) {
            console.error("Error while creating Seller: ", error);
            message.error(findErrorMessage(error) || "Error al agregar el nuevo vendedor");
        }
    };
    

    const updateSeller = () => {
        const values = form.getFieldsValue();
        try {
            const Seller = Parse.Object.extend("_User");
            const sellerObject = new Seller();
            sellerObject.set('objectId', seller.objectId);
            sellerObject.set('firstName', values.firstName?.trim());
            sellerObject.set('lastName', values.lastName?.trim());
            sellerObject.set('fullName', `${values.firstName?.trim()} ${values.lastName?.trim()}`);
            sellerObject.set('email', values.email?.trim()?.toLowerCase());
            sellerObject.set('phoneNumber', values.phoneNumber?.trim());
            sellerObject.set('comissionPercentage', parseInt(values.comissionPercentage));
            sellerObject.set('salesGoal', parseInt(values.salesGoal));
            sellerObject.save().then(
                (result) => {
                    console.log("Seller updated", result);
                    message.success("Vendedor actualizado correctamente");
                    refresh();
                    setOpen(false);
                }
            );
        } catch (error) {
            console.error("Error while updating Seller: ", error);
            message.error("Error al actualizar el vendedor");
        }
    }


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (editing) {
                updateSeller();
            } else {
                createSeller();
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title={editing ? "Editar vendedor" : "Nuevo vendedor"}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="firstName"
                    rules={[{ required: true, message: 'Nombres' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="lastName"
                    rules={[{ required: true, message: 'Nombres' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{ required: true, message: 'Correo electrónico' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Teléfono"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Teléfono' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Porcentaje de comisión"
                    name="comissionPercentage"
                    rules={[{ required: true, message: 'Porcentaje de comisión' }]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    label="Objetivo de ventas"
                    name="salesGoal"
                    rules={[{ required: true, message: 'Objetivo de ventas' }]}
                >
                    <Input type="number" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewSeller;
