// Fiscal Regimes for Personas Físicas
const fiscalRegimesPersonasFisicas = [
    { value: "605", name: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
    { value: "612", name: "Personas Físicas con Actividades Empresariales y Profesionales" },
    { value: "614", name: "Ingresos por intereses" },
    { value: "615", name: "Régimen de los ingresos por obtención de premios" },
    { value: "621", name: "Incorporación Fiscal" },
    { value: "622", name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
    { value: "623", name: "Opcional para Grupos de Sociedades" },
    { value: "630", name: "Enajenación de acciones en bolsa de valores" },
    { value: "606", name: "Régimen Simplificado de Confianza" },
    { value: "629", name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales" }
  ];
  
  // Fiscal Regimes for Personas Morales
  const fiscalRegimesPersonasMorales = [
    { value: "601", name: "General de Ley Personas Morales" },
    { value: "603", name: "Personas Morales con Fines no Lucrativos" },
    { value: "608", name: "Arrendamiento" },
    { value: "607", name: "Régimen de Enajenación o Adquisición de Bienes" },
    { value: "609", name: "Consolidación" },
    { value: "610", name: "Residentes en el Extranjero sin Establecimiento Permanente en México" },
    { value: "611", name: "Ingresos por Dividendos (socios y accionistas)" },
    { value: "616", name: "Sin obligaciones fiscales" },
    { value: "620", name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
    { value: "624", name: "Coordinados" },
    { value: "628", name: "Hidrocarburos" }
  ];

  
    export { fiscalRegimesPersonasFisicas, fiscalRegimesPersonasMorales };