import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Card, Row, Col } from "antd";
import Parse from "parse";
import DebounceSelect from "../../../form/DebounceSelect";
import { v4 as uuidv4 } from "uuid";

const NewPackagingOrder = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [packingSummary, setPackingSummary] = useState({ volume: 0, units: 0 });

    useEffect(() => {
        if (open) {
            form.resetFields();
            setPackingSummary({ volume: 0, units: 0 });
        }
    }, [open]);

    // Update the packing summary dynamically
    const updatePackingSummary = () => {
        const values = form.getFieldsValue();
        const { presentation = {} } = values;
        let volume = 0;
        let units = 0;

        Object.entries(presentation).forEach(([presentationId, { quantity }]) => {
            const selectedPresentation = product.presentations.find((p) => p.objectId === presentationId);
            if (selectedPresentation) {
                volume += (parseInt(quantity) || 0) * selectedPresentation.content;
                units += parseInt(quantity) || 0;
            }
        });

        setPackingSummary({ volume, units });
    };

    // Fetch the index for creating unique orders
    const fetchIndex = async () => {
        const Index = Parse.Object.extend("Index");
        const indexQuery = new Parse.Query(Index);
        indexQuery.equalTo("name", "PackagingOrder");
        indexQuery.equalTo("year", new Date().getFullYear());
        return indexQuery.first();
    };

    // Build the packaging order object
    const buildPackagingOrder = (indexValue) => {
        const PackagingOrder = Parse.Object.extend("PackagingOrder");
        const packagingOrder = new PackagingOrder();

        const presentations = Object.entries(form.getFieldValue("presentation") || {}).map(
            ([presentationId, { quantity }]) => {
                const selectedPresentation = product.presentations.find((p) => p.objectId === presentationId);
                return {
                    objectId: presentationId,
                    quantity: quantity || 0,
                    content: selectedPresentation.content,
                    name: selectedPresentation.name,
                };
            }
        );

        packagingOrder.set("product", { __type: "Pointer", className: "Product", objectId: product.value });
        packagingOrder.set("presentations", presentations);
        packagingOrder.set('presentationPointers', presentations.map(p => ({ __type: "Pointer", className: "Presentation", objectId: p.objectId })));
        packagingOrder.set("packagingQuantity", packingSummary.units);
        packagingOrder.set("totalVolume", packingSummary.volume);
        packagingOrder.set("folio", `P${(indexValue + 1).toString().padStart(4, "0")}-${new Date().getFullYear()}`);
        packagingOrder.set("packaged", 0);
        packagingOrder.set("uuid", uuidv4());

        return packagingOrder;
    };

    // Save the packaging order
    const savePackagingOrder = async (packagingOrder, index) => {
        await packagingOrder.save();
        index.increment("value");
        await index.save();
    };

    const handleSubmit = async () => {
        try {
            const index = await fetchIndex();
            const packagingOrder = buildPackagingOrder(index.get("value"));

            await savePackagingOrder(packagingOrder, index);

            message.success("Orden de envasado guardada correctamente");
            form.resetFields();
            refresh();
            setOpen(false);
        } catch (error) {
            console.error("Error saving packaging order:", error);
            message.error("Error al guardar la orden de envasado.");
        }
    };

    return (
        <Modal
            title="Nueva orden de envasado"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} onValuesChange={updatePackingSummary}>
                <Form.Item
                    label="Producto"
                    name="product"
                    rules={[{ required: true, message: "Por favor seleccione un producto" }]}
                >
                    <DebounceSelect
                        fetchOptions={async (search) => {
                            const Product = Parse.Object.extend("Product");
                            const query = new Parse.Query(Product);
                            query.contains("name", search);
                            query.include(["presentations"]);
                            const results = await query.find();
                            const fetchedProducts = results.map((result) => ({
                                value: result.id,
                                label: result.get("name"),
                                ...result.toJSON(),
                            }));
                            setProducts(fetchedProducts);
                            return fetchedProducts;
                        }}
                        onChange={(selectedProduct) => {
                            const fullProduct = products.find((p) => p.value === selectedProduct.value);
                            setProduct(fullProduct);
                        }}
                        placeholder="Buscar producto"
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                    />
                </Form.Item>

                {product && product.presentations && (
                    <Form.Item label="Presentación">
                        <Row gutter={16}>
                            {product.presentations?.map((presentation) => (
                                <Col span={8} key={presentation.objectId}>
                                    <Card style={{ marginBottom: 10 }} title={presentation.name}>
                                        <Form.Item
                                            name={['presentation', presentation.objectId, 'quantity']}
                                            rules={[{ required: true, message: "Por favor ingrese una cantidad" }]}
                                        >
                                            <Input type="number" placeholder="Cantidad" />
                                        </Form.Item>
                                        <p>Contenido: {presentation.content} kg</p>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Form.Item>
                )}

                <Row>
                    <Col span={12}>
                        <p><strong>Volumen a empacar:</strong> {packingSummary.volume.toFixed(2)} kg</p>
                    </Col>
                    <Col span={12}>
                        <p><strong>Unidades a empacar:</strong> {packingSummary.units}</p>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewPackagingOrder;