import React from 'react';

const TerminosCondiciones = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', background: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6, color: '#333' }}>
            <h1>Términos y Condiciones</h1>
            <p>Bienvenido a Dasner Petcare. Al utilizar nuestra aplicación web, aceptas cumplir con los siguientes términos y condiciones. Por favor, léelos detenidamente.</p>

            <h2>1. Uso del Sitio</h2>
            <p>El contenido de esta aplicación es únicamente para uso general y puede estar sujeto a cambios sin previo aviso.</p>

            <h2>2. Propiedad Intelectual</h2>
            <p>Todos los materiales en esta aplicación, incluyendo texto, imágenes, logotipos y gráficos, están protegidos por derechos de autor y otras leyes aplicables. No se permite su reproducción sin autorización previa por escrito de Dasner Petcare.</p>

            <h2>3. Enlaces a Otros Sitios</h2>
            <p>Esta aplicación puede contener enlaces a sitios web de terceros. No somos responsables del contenido ni de las prácticas de privacidad de esos sitios.</p>

            <h2>4. Privacidad</h2>
            <p>El uso de esta aplicación está sujeto a nuestra política de privacidad, que puedes consultar en el siguiente enlace: <a href="#">Política de Privacidad</a>.</p>

            <h2>5. Limitación de Responsabilidad</h2>
            <p>Dasner Petcare no se hace responsable por cualquier daño directo, indirecto, incidental o consecuente que resulte del uso de esta aplicación.</p>

            <h2>6. Modificaciones</h2>
            <p>Dasner Petcare se reserva el derecho de modificar estos términos en cualquier momento. Se recomienda revisar esta página periódicamente para estar al tanto de cualquier cambio.</p>

            <h2>7. Contacto</h2>
            <p>Si tienes preguntas o inquietudes sobre estos términos y condiciones, contáctanos a través de <a href="mailto:emanuel@devtek.mx">emanuel@devtek.mx</a>.</p>

            <p>Fecha de última actualización: 21 de enero de 2025</p>
        </div>
    );
};

export default TerminosCondiciones;