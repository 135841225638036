import React from "react";
import { Card, Row, Col } from "antd";

const MixingHopperIngredients = ({ ingredients }) => (
    <Row gutter={[16, 16]}>
        {ingredients?.map((ingredient) => (
            <Col key={ingredient.objectId} xs={24} sm={12} md={8} lg={6}>
                <Card
                    bordered
                    style={{
                        borderRadius: "10px",
                        textAlign: "center",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    hoverable
                >
                    <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>{ingredient.name}</h3>
                    <p style={{ fontSize: "14px", color: "#8c8c8c" }}>Cantidad</p>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>{ingredient.quantity} kg</p>
                </Card>
            </Col>
        ))}
    </Row>
);

export default MixingHopperIngredients;