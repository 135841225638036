import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Card, Table, Statistic, FloatButton } from "antd";
import { fetchVisits } from "../utils/query";
import NewVisit from "../components/NewVisit";
import moment from "moment";

const columns = [
    
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => <h3>{moment(date).format("DD/MM/YYYY")}</h3>
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      render: (client) => <h3>{client.fullName}</h3>
    },
    {
      title: 'Comentarios',
      dataIndex: 'notes',
      key: 'notes',
    }
];

const SellerVisits = ({ seller }) => {
  const [data, setDataSource] = useState([]);
  const [newVisitModal, setNewVisitModal] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);



  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };


  useEffect(() => {
    const getVisits = async () => {
      const visits = await fetchVisits(seller.objectId);
      console.log(visits);
      setDataSource(visits);
    };

    getVisits();
  }, []);

    return (
        <Row gutter={[24, 0]}>
          <FloatButton onClick={() => setNewVisitModal(true)}/>
          <NewVisit open={newVisitModal} setOpen={setNewVisitModal} refresh={refresh} seller={seller}/>
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Número de Visitas</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            value={data.length}
                            formatter={(value) => <span>{value} Visitas</span>}
                        />
                    </Col>
                </Row>
                </Card> 
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Historial de Visitas</h2>
                    <Table columns={columns} dataSource={data} rowKey={(record) => record.objectId} />
                </Card>
            </Col>
        </Row>
    )
}

export {SellerVisits};