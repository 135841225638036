import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, message } from "antd";
import { calculatePending, allowSave } from "./utils";
import { addIngredients } from "./services";
import SetHopperModal from "./SetHopperModal";

const ForageAdding = ({ production, refresh }) => {
    const [ingredients, setIngredients] = useState([]);
    const [waitingWeight, setWaitingWeight] = useState(0);
    const [showSetHopper, setShowSetHopper] = useState(false);

    // Load and process data when `production` changes
    useEffect(() => {
        if (production) {
            const waitingEvent = production?.productionEvents?.find(
                (event) => event.event === "weightWaitingHopper"
            );
            const waitingHopperWeight = waitingEvent?.weights?.waitingHopperWeight || 0;
            setWaitingWeight(waitingHopperWeight);

            const initialIngredients = production.recipe.ingredients
                .filter((ingredient) => ingredient.warehouse === "Producción")
                .map((ingredient) => ({
                    ...ingredient,
                    pending: 0,
                    adding: 0,
                }));

            const updatedIngredients = calculatePending({ ingredients: initialIngredients, production });
            setIngredients(updatedIngredients);
        }
    }, [production]);

    const update = () => {
        setShowSetHopper(false);
        refresh();
    }

    // Handle changes to the "Agregar" input field
    const handleInputChange = (e, record) => {
        const value = parseFloat(e.target.value) || 0;
        setIngredients((prev) =>
            prev.map((ingredient) =>
                ingredient.objectId === record.objectId
                    ? { ...ingredient, adding: value }
                    : ingredient
            )
        );
    };

    // Save added ingredients
    const handleSave = async () => {
        const ingredientsToAdd = ingredients
            .filter((ingredient) => ingredient.adding > 0)
            .map((ingredient) => ({
                objectId: ingredient.objectId,
                quantity: ingredient.adding,
                name: ingredient.name,
            }));

        try {
            await addIngredients({ production, ingredients: ingredientsToAdd });
            message.success("Ingredientes agregados correctamente");
            refresh();
        } catch (error) {
            console.error("Error adding ingredients:", error);
            message.error("Error al agregar ingredientes.");
        }
    };

    return (
        <Row gutter={16}>
            {/* Hopper Card */}
            <Col span={24}>
                <Card title={production?.waitingHopper?.name || "Sin tolva asignada"}>
                    {waitingWeight > 0 && (
                        <p style={{ margin: 0 }}>Peso en tolva de espera: {waitingWeight} kg</p>
                    )}
                    {!production?.waitingHopper && (
                        <Button
                            type="primary"
                            onClick={() => setShowSetHopper(true)}
                            style={{ marginTop: "10px" }}
                        >
                            Asignar tolva
                        </Button>
                    )}
                </Card>
            </Col>

            {/* Ingredients Table */}
            <Col span={24}>
                <Table
                    dataSource={ingredients}
                    rowKey="objectId"
                    columns={[
                        {
                            title: "Ingrediente",
                            dataIndex: "name",
                            key: "name",
                        },
                        {
                            title: "Requerido",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (text, record) => `${text} ${record.basePresentation}`,
                        },
                        {
                            title: "Faltante",
                            dataIndex: "pending",
                            key: "pending",
                            render: (text, record) => `${text} ${record.basePresentation}`,
                        },
                        {
                            title: "Agregar",
                            dataIndex: "adding",
                            key: "adding",
                            render: (text, record) => (
                                <Input
                                    disabled={record.pending === 0}
                                    type="number"
                                    suffix="kg"
                                    value={record.adding}
                                    onChange={(e) => handleInputChange(e, record)}
                                />
                            ),
                        },
                    ]}
                    pagination={false}
                />
            </Col>

            {/* Save Button */}
            <Col span={24}>
                <Button
                    type="primary"
                    disabled={!allowSave(ingredients)}
                    onClick={handleSave}
                >
                    Agregar ingredientes
                </Button>
            </Col>

            {/* Set Hopper Modal */}
            <SetHopperModal
                open={showSetHopper}
                setOpen={update}
                production={production}
            />
        </Row>
    );
};

export default ForageAdding;