import React, { useState, useContext } from 'react';
import { Table, Button, Flex, Tag } from 'antd';
import moment from 'moment';
import { UserContext } from '../../../../../context/UserContext';
import DeliveryDetail from './modals/DeliveryDetail';
import NewWarehouseOrder from './modals/NewWarehouseOrder';
import SendSignatureWhatsapp from './modals/SendSignatureWhatsapp';

const DeliveriesTab = ({ sale, refresh }) => {
    const [showDelivery, setShowDelivery] = useState(false);
    const [showWhatsapp, setShowWhatsapp] = useState(false);
    const [delivery, setDelivery] = useState(null);
    const [openWarehouseOrder, setOpenWarehouseOrder] = useState(false);
    const { permissions } = useContext(UserContext);

    const allowSendWarehouseOrder = () => {
        const totalProducts = sale?.products?.reduce((acc, product) => acc + parseInt(product.quantity), 0);
        const totalDelivered = sale?.outlets?.reduce((acc, outlet) => acc + outlet.products.reduce((acc, product) => acc + parseInt(product.delivered), 0), 0);
        console.log("🚚 Total Products: ", totalProducts)
        console.log("🚚 Total Delivered: ", totalDelivered);

        if (!sale) return false;
        if (!sale.active) return false;
        if (sale.status !== 'Venta') return false;
        if (totalProducts === totalDelivered) return false;
        if (permissions.some((p) => p.group === 'warehouses' && p.action === 'createWarehouseRelease')) return true;
        return false;
    }

    const renderDeliveryStatus = (status) => {
        if (status === 'Pendiente') return <Tag color="blue">Pendiente</Tag>;
        if (status === 'Entregado') return <Tag color="green">Entregado</Tag>;
        if (status === 'Cancelado') return <Tag color="orange">Cancelado</Tag>;
        if (status === 'En ruta') return <Tag color="purple">En ruta</Tag>;
    }

    const renderActions = (status, record) => <Flex gap="10px">
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Ver detalles</Button>
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Orden de salida</Button>
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Comprobante de salida</Button>
            {status === 'En ruta' && <Button onClick={() => {setShowWhatsapp(true); setDelivery(record)}}>Enviar por Whatsapp</Button>}
        </Flex>;


  return (
    <Flex justify="end" vertical>
    <DeliveryDetail open={showDelivery} setOpen={setShowDelivery} delivery={delivery} refresh={refresh} />
    <NewWarehouseOrder open={openWarehouseOrder} setOpen={setOpenWarehouseOrder} sale={sale} refresh={refresh} />
    <SendSignatureWhatsapp open={showWhatsapp} setOpen={setShowWhatsapp} delivery={delivery} refresh={refresh} />
    <Table
        dataSource={sale?.outlets}
        rowKey="objectId"
        pagination={false}
        scroll={{ x: true }}
    >
        <Table.Column
            title="Estado"
            dataIndex="status"
            key="status"
            render={(text) => renderDeliveryStatus(text)}
        />
        <Table.Column
            title="Fecha de creación"
            dataIndex="createdAt"
            key="createdAt"
            render={(text) => moment(text).format("DD/MM/YYYY")}
        />
         <Table.Column
            title="Fecha de entrega"
            dataIndex="deliveryDate"
            key="deliveryDate"
            render={(deliveryDate) => deliveryDate ? moment(deliveryDate.iso).format("DD/MM/YYYY") : 'No entregado'}
        />
        <Table.Column
            title="Productos"
            dataIndex="products"
            key="products"
            render={(products) => products.map((product) => (
                <p>{product.delivered} {product.name}</p>
            ))}
        />
        <Table.Column 
            title="Entregado por"
            dataIndex="deliveredBy"
            key="deliveredBy"
            render={(deliveredBy) => deliveredBy ? deliveredBy.fullName : 'Sin asignar'}
        />
        <Table.Column
            title="Acciones"
            dataIndex="status"
            key="status"
            render={(status, record) => renderActions(status, record)}
        />
    </Table>
    {allowSendWarehouseOrder() && <Button type="primary" onClick={() => setOpenWarehouseOrder(true)}>Generar orden de salida</Button>}
    </Flex>
  );
};

export default DeliveriesTab;
