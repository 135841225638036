import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message, DatePicker } from "antd";
import Parse, { Role } from "parse";
import { fetchClients } from "../utils/query";
import moment from "moment";
const { Title } = Typography;


const NewVisit = ({ open, setOpen, refresh, seller }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [clients, setClients] = React.useState([]);

    const getClients = async () => {
        const clients = await fetchClients();
        console.log(clients);
        setClients(clients);
    };

    useEffect(() => {
        getClients();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            order: "",
            notes: "",
            date: undefined,
        });
    }, [open]);


    const createVisit =  async () => {
        console.log("Seller: ", seller);
        const visit = form.getFieldsValue();
        console.log(visit);
        const Visit = Parse.Object.extend("Visits");
        const visitObject = new Visit();
        visitObject.set("client", { __type: "Pointer", className: "_User", objectId: visit.client });
        visitObject.set("seller", { __type: "Pointer", className: "_User", objectId: seller.objectId });
        visitObject.set("notes", visit.notes);

        try {
            await visitObject.save();
            message.success("Visita creada correctamente");
            refresh();
        }
        catch (error) {
            console.error("Error while creating visit: ", error);
            message.error("Error al crear la visita");
        }
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            createVisit();
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title="Nueva Visita"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
               
                <Form.Item
                    label="Fecha"
                    name="date"
                    rules={[{ required: true, message: 'Por favor seleccione una fecha' }]}
                >
                    {/* TODO: Fix date picker going infinite forward */}
                    <DatePicker  ormat="DD-MM-YYYY" />
                </Form.Item>
                <Form.Item
                    label="Cliente"
                    name="client"
                    rules={[{ required: true, message: 'Por favor seleccione un cliente' }]}
                >
                    <Select
                        placeholder="Seleccione un cliente"
                        onChange={(value) => form.setFieldsValue({ client: value })}
                        showSearch 
                        onSearch={getClients}
                    >
                        {clients.map((client) => (
                            <Select.Option key={client.objectId} value={client.objectId}>
                                {client.fullName}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>
                <Form.Item
                    label="Comentarios"
                    name="notes"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea rows={4} placeholder="Comentarios de la visita" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewVisit;
