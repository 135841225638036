import React, { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Typography, Modal, Form, DatePicker, Select, message, Table} from "antd";
import Parse from "parse";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;


const NewWarehouseOrder = ({open, setOpen, sale, refresh}) => {
    const [disableSave, setDisableSave] = useState(false);
    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);

    console.log("📦 Order: ", order)

    const createOrder = async () => {
        console.log("📦 Order1:")
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'WarehouseOutlet');
        query.equalTo('year', new Date().getFullYear());
        const index = await query.first();

        console.log("📦 Index: ", index)
        const Order = Parse.Object.extend('WarehouseOutlet');
        const order = new Order();
        order.set('status', 'Pendiente');
        order.set('products', products.map(product => ({ objectId: product.objectId, quantity: product.added, name: product.presentation.name, presentationId: product.presentation.objectId })));
        order.set('sale', { __type: 'Pointer', className: 'Sales', objectId: sale.objectId });
        order.set('productPointers', products.map(product => ({ __type: 'Pointer', className: 'Product', objectId: product.objectId })));
        order.set('createdBy', Parse.User.current());
        order.set('orderNumber', 'S' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());
        order.set('uuid', uuidv4());

        console.log("📦 Order2: ", order)
        
        const Sales = Parse.Object.extend('Sales');
        const saleObject = new Sales();
        saleObject.set('objectId', sale.objectId);

        console.log("📦 Sale: ", sale)
        try {
            const outlet = await order.save();
            saleObject.addUnique('outlets', outlet);
            await saleObject.save();
            index.increment('value');
            await index.save();

            console.log("📦 Outlet: ", outlet

            )
            message.success("Orden creada correctamente");
            refresh();
            setOpen(false);
        }
        catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        let totalDelivered = {};
        sale?.outlets?.forEach(outlet => {
            outlet.products.forEach(product => {
                console.log("📦 Product: ", product)
                if (!totalDelivered[product?.presentationId]) totalDelivered[product?.presentationId] = 0;
                totalDelivered[product.presentationId] += product.quantity;
            })
        })
        
        let products = sale?.products?.map(product => {
            return {
                objectId: product.objectId,
                name: product.name,
                presentationId: product.presentation.objectId,
                presentation: product.presentation,
                quantity: product.quantity - (totalDelivered[product.presentation.objectId] || 0),
                pending: product.quantity - (totalDelivered[product.presentation.objectId] || 0),
                added: 0,
            }
        })

        console.log("📦 Products", products)
        setProducts(products);
        setOrder(sale)
    }, [sale])

    if (!sale) return null;

    const allowSave = () => {
        if (!sale) return false;
        if (!order) return false;
        if (order?.length === 0) return false;
        if (products?.some(product => product?.pending - product?.added < 0)) return false;
        return true;
    }

    if (!sale) return null;

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
    >
        <Title level={4}>Nueva orden de almacén</Title>
        <Table dataSource={products} columns={[
            {
                title: 'Producto',
                dataIndex: 'name',
                key: 'name',
                render: (name, record) => <p>{name}</p>
            },
            {
                title: 'Presentación',
                dataIndex: 'presentation',
                key: 'presentation',
                render: (presentation, record) => <p>{presentation?.name}</p>
            },
            {
                title: 'Pendiente',
                dataIndex: 'pending',
                key: 'pending',
                render: (text, record) => <p>{record?.quantity} pieza{record?.quantity > 1 ? 's' : ''}</p>
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (text, record) => <Input type="number" value={record?.added} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value > record?.pending) {
                        message.error("La cantidad no puede ser mayor a la pendiente");
                        return;
                    }
                    setProducts(products.map(product => product.objectId === record.objectId ? { ...product, added: value } : product))
                }
                } />
            }
        ]} />
        <Flex justify="end" gap={20}>
            <Button type="default" onClick={() => setOpen(false)}>Cancelar</Button>
            <Button type="primary" onClick={createOrder} disabled={!allowSave()}>Guardar</Button>
        </Flex>
    </Modal>
}

export default NewWarehouseOrder;