import React, { useContext } from 'react';
import { Table, Card, Flex, Typography, Button, message, Row, Col } from 'antd';
import moment from 'moment';
import Parse from 'parse';
import { UserContext } from '../../../../../context/UserContext';

const { Title } = Typography;

const SummaryTab = ({ sale, refresh }) => {
  const { permissions } = useContext(UserContext);

  // Check permissions for confirming the sale
  const allowConfirm = () => {
    if (!sale) return false;
    if (sale.status !== 'Cotización' || !sale.active) return false;
    if (permissions.some(p => p.group === 'quotes' && p.action === 'confirmAll')) return true;
    if (
      permissions.some(p => p.group === 'quotes' && p.action === 'confirmOwn') &&
      sale.seller.objectId === Parse.User.current().id
    ) return true;
    return false;
  };

  // Check permissions for authorizing the sale
  const allowAuthorize = () => {
    if (!sale) return false;
    if (sale.status !== 'Confirmada' || !sale.active) return false;
    if (permissions.some(p => p.group === 'quotes' && p.action === 'authorizeAll')) return true;
    return false;
  };

  // Handle sale confirmation
  const confirmSale = async () => {
    const Sale = Parse.Object.extend('Sales');
    const saleObject = new Sale();
    saleObject.set('objectId', sale.objectId);
    saleObject.set('status', 'Confirmada');
    saleObject.set('confirmationDate', new Date());

    try {
      await saleObject.save();
      message.success('Venta confirmada correctamente');
      refresh();
    } catch (error) {
      console.error('Error while confirming Sale: ', error);
      message.error('Error al confirmar la venta');
    }
  };

  // Handle sale authorization
  const authorizeSale = async () => {
    const indexQuery = new Parse.Query('Index');
    indexQuery.equalTo('name', 'Sale');
    indexQuery.equalTo('year', new Date().getFullYear());
    const index = await indexQuery.first();

    const Sale = Parse.Object.extend('Sales');
    const saleObject = new Sale();
    saleObject.set('objectId', sale.objectId);
    saleObject.set('status', 'Venta');
    saleObject.set('authorizationDate', new Date());
    saleObject.set(
      'saleNumber',
      'V' + (index.get('value') + 1).toString().padStart(4, '0') + '-' + new Date().getFullYear()
    );

    const _User = Parse.Object.extend('_User');
    const user = new _User();
    user.set('objectId', sale.client.objectId);
    user.decrement('balance', sale.total);
    user.addUnique('sales', saleObject);

    try {
      await saleObject.save();
      index.increment('value');
      await index.save();
      await user.save();
      message.success('Venta autorizada correctamente');
      refresh();
    } catch (error) {
      console.error('Error authorizing Sale:', error);
      message.error('Error al autorizar la venta');
    }
  };

  return (
    <Card title="Resumen de venta" style={{ marginBottom: 10, padding: '20px' }}>
  {/* Customer and Seller Info */}
  <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
    <Col span={8}>
      <Title level={5}>Cliente</Title>
      <a href={`/clients/${sale?.client?.uuid}`}>{sale?.client?.fullName}</a>
    </Col>
    <Col span={8}>
      <Title level={5}>Vendedor</Title>
      <p>{sale?.seller?.fullName}</p>
    </Col>
    <Col span={8}>
      <Title level={5}>Fecha de entrega</Title>
      <p>{moment(sale?.deliveryDate?.iso).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>

  {/* Delivery Info */}
  <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
    <Col span={8}>
      <Title level={5}>Lugar de entrega</Title>
      <p>{sale?.deliveryPlace === 'Domicilio' ? sale?.deliveryAddress?.address : 'Pie de fábrica'}</p>
    </Col>
    {sale?.deliveryPrice && <Col span={8}>
      <Title level={5}>Precio de entrega</Title>
      <p>${sale?.deliveryPrice?.toLocaleString()}</p>
    </Col>}
    <Col span={8}>
      <Title level={5}>Notas de entrega</Title>
      <p>{sale?.deliveryNotes || 'Sin notas'}</p>
    </Col>
  </Row>

  {/* Products Table */}
  <Title level={5} style={{ marginBottom: 10 }}>Productos</Title>
  <Table
    dataSource={sale?.products}
    rowKey="objectId"
    pagination={false}
    scroll={{ x: true }}
    size="small"
    bordered
  >
    <Table.Column title="Producto" dataIndex="name" key="name" />
    <Table.Column title="Presentación" dataIndex={["presentation", "name"]} key="presentation" />
    <Table.Column title="Cantidad" dataIndex="quantity" key="quantity" />
    <Table.Column 
      title="Precio" 
      dataIndex="price" 
      key="price" 
      render={(price) => `$${price?.toLocaleString()}`} 
    />
    <Table.Column
      title="Total"
      key="total"
      render={(text, product) => `$${(product.price * product.quantity)?.toLocaleString()}`}
    />
  </Table>

  {/* Totals */}
  <Row gutter={[16, 16]} style={{ marginTop: 20, textAlign: 'center' }}>
    <Col span={12}>
      <Title level={5}>Total</Title>
      <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>${sale?.total?.toLocaleString()}</p>
    </Col>
    <Col span={12}>
      <Title level={5}>Pagado</Title>
      <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>${sale?.totalPaid?.toLocaleString()}</p>
    </Col>
  </Row>

  {/* Action Buttons */}
  <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
    <Col span={12}>
      {allowConfirm() && (
        <Button type="primary" block onClick={confirmSale}>
          Confirmar venta
        </Button>
      )}
    </Col>
    <Col span={12}>
      {allowAuthorize() && (
        <Button type="primary" block onClick={authorizeSale}>
          Autorizar venta
        </Button>
      )}
    </Col>
  </Row>
</Card>
  );
};

export default SummaryTab;
