import React, { useEffect, useState } from "react";
import { Button, Space, Typography, Modal, Form, Input, message, Table } from "antd";
import Parse from "parse";
import DebounceSelect from "../../../form/DebounceSelect";
import { parse, v4 as uuidv4 } from "uuid";

const { Title } = Typography;


const fetchIngredients = async (recipe) => {
    const Ingredient = Parse.Object.extend("Ingredient");
    const query = new Parse.Query(Ingredient);
    query.containedIn("objectId", recipe.ingredients?.map((ingredient) => ingredient.objectId));
    const results = await query.find();
    return results.map((result) => result.toJSON());
};

const NewProductionOrder = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [recipe, setRecipe] = useState(null);

    useEffect(() => {
        if (open) {
            form.resetFields();
            setRecipe(null);
        }
    }, [open]);

    useEffect(() => {
        const updateIngredients = async (selectedProduct) => {
            const ingredients = await fetchIngredients(selectedProduct.recipe);
            const updatedIngredients = selectedProduct.recipe.ingredients?.map((ingredient) => {
                const matchedIngredient = ingredients.find((i) => i.objectId === ingredient.objectId);
                return {
                    ...ingredient,
                    stock: matchedIngredient.stock,
                    baseCost: matchedIngredient.baseCost,
                };
            });

            setRecipe({ ...selectedProduct.recipe, ingredients: updatedIngredients });
            setProduct({ ...selectedProduct, recipe: { ...selectedProduct.recipe, ingredients: updatedIngredients } });
        };

        if (product?.recipe) {
            updateIngredients(product);
        }
    }, [product]);

    // Separate smaller functions
    const fetchIndex = async () => {
        const Index = Parse.Object.extend("Index");
        const indexQuery = new Parse.Query(Index);
        indexQuery.equalTo("name", "ProductionOrder");
        indexQuery.equalTo("year", new Date().getFullYear());
        return indexQuery.first();
    };

    const buildProductionOrder = (recipeCopy, indexValue) => {
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const productionOrder = new ProductionOrder();

        productionOrder.set("product", { __type: "Pointer", className: "Product", objectId: product.value });
        productionOrder.set("productionQuantity", parseInt(form.getFieldValue("quantity"), 10));       
        productionOrder.set("recipePointer", { __type: "Pointer", className: "Recipe", objectId: recipeCopy.objectId });
        productionOrder.set('recipe', recipeCopy);
        productionOrder.set("ingredientPointers", recipeCopy.ingredients?.map((ing) => ({
            __type: "Pointer",
            className: "Ingredient",
            objectId: ing.objectId,
        })));
        productionOrder.set("folio", `P${(indexValue + 1).toString().padStart(4, "0")}-${new Date().getFullYear()}`);
        productionOrder.set("produced", 0);
        productionOrder.set("uuid", uuidv4());

        return productionOrder;
    };

    const saveProductionOrder = async (productionOrder, index) => {
        await productionOrder.save();
        index.increment("value");
        await index.save();
    };

    const handleSubmit = async () => {
        try {
            const recipeCopy = { ...product.recipe };
            delete recipeCopy.ingredientPointers;
            delete recipeCopy.__type;

            const index = await fetchIndex();
            const productionOrder = buildProductionOrder(recipeCopy, index.get("value"));

            await saveProductionOrder(productionOrder, index);

            message.success("Orden de producción guardada correctamente");
            form.resetFields();
            refresh();
            setOpen(false);
        } catch (error) {
            console.error("Error saving production order:", error);
            message.error("Error al guardar la orden de producción.");
        }
    };

    return (
        <Modal
            title="Nueva orden de producción"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form}>
                <Form.Item
                    label="Producto"
                    name="product"
                    rules={[{ required: true, message: "Por favor seleccione un producto" }]}
                >
                    <DebounceSelect
                        fetchOptions={async (search) => {
                            const Product = Parse.Object.extend("Product");
                            const query = new Parse.Query(Product);
                            query.contains("name", search);
                            query.include(["recipe"]);
                            const results = await query.find();
                            const fetchedProducts = results.map((result) => ({
                                value: result.id,
                                label: result.get("name"),
                                ...result.toJSON(),
                            }));
                            setProducts(fetchedProducts);
                            return fetchedProducts;
                        }}
                        onChange={(selectedProduct) => {
                            const fullProduct = products.find((p) => p.value === selectedProduct.value);
                            setProduct(fullProduct);
                        }}
                        placeholder="Buscar producto"
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                    />
                </Form.Item>

                <Form.Item
                    label="Cantidad a producir"
                    name="quantity"
                    rules={[{ required: true, message: "Por favor ingrese la cantidad a producir" }]}
                >
                    <Input type="number" suffix={product?.recipe?.baseUnit} />
                </Form.Item>

                {product?.recipe && (
                    <>
                        <Title level={4}>Receta: {product.recipe.name}</Title>
                        <Table
                            dataSource={product.recipe.ingredients}
                            rowKey="objectId"
                            pagination={false}
                            scroll={{ x: true }}
                        >
                            <Table.Column title="Ingrediente" dataIndex="name" key="name" />
                            <Table.Column
                                title="Cantidad"
                                render={(text, record) => (
                                    <p>
                                        {((record.quantity * form.getFieldValue("quantity")) / product.recipe.finalWeight).toFixed(2)}{" "}
                                        {record.basePresentation}
                                    </p>
                                )}
                            />
                        </Table>
                    </>
                )}

            </Form>
        </Modal>
    );
};

export default NewProductionOrder;