import React, { useEffect, useState } from "react";
import { Tabs, Space } from "antd";
import SummaryTab from './SummaryTab';
import PaymentTab from './PaymentTab';
import DeliveriesTab from './DeliveriesTab';
import CommentsTab from './CommentsTab';
import Parse from "parse";

const { TabPane } = Tabs;

const fetchSales = async (objectId) => {
  const Sale = Parse.Object.extend("Sales");
  const query = new Parse.Query(Sale);
  query.equalTo("objectId", objectId);
  query.include(["seller", "client", "client.address", "client.fiscalInformation", "payments", "deliveries", "comments"]);
  const result = await query.first();
  console.log("📦 Resultttt: ", result)
  return result.toJSON();
};


const TabsContainer = ({ sale, setOpenPayment, setPayment, setShowDelivery, setDelivery, setOpenWarehouseOrder, refresh, renderId, setRefresh }) => {
  const [data, setData] = useState(sale);
  const [tab, setTab] = useState('summary');
  const [refreshInfo, setRefreshInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSales(sale.objectId);
      setData(result);
      refresh();
    };
    fetchData();

  }, [refreshInfo]);

  const refreshModal = () => {
    setRefreshInfo(!refreshInfo);
    refresh();
  }

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Tabs defaultActiveKey="summary" onChange={(key) => setTab(key)}>
          <TabPane tab="Resumen" key="summary" />
          <TabPane tab="Pagos" key="payments" />
          <TabPane tab="Entregas" key="deliveries" />
          <TabPane tab="Comentarios" key="comments" />
        </Tabs>
      </Space>
      {tab === "summary" && <SummaryTab sale={data} refresh={refreshModal} />}
      {tab === "payments" && <PaymentTab sale={data} setOpenPayment={setOpenPayment} setPayment={setPayment} refresh={refreshModal} />}
      {tab === "deliveries" && (
        <DeliveriesTab
          refresh={refreshModal}
          sale={data}
          setShowDelivery={setShowDelivery}
          setDelivery={setDelivery}
          setOpenWarehouseOrder={setOpenWarehouseOrder}
        />
      )}
      {tab === "comments" && <CommentsTab sale={data} refresh={refreshModal} />}
    </>
  );
};

export default TabsContainer;
