import React, { useEffect, useState } from "react";
import { Modal, Input, Button, message } from "antd";
import Parse from "parse";

const sendWhatsapp = async (phoneNumber, delivery) => {
    const link = 'deliveries/signature/' + delivery.uuid;
    try {
        await Parse.Cloud.run("clientSignature", { phoneNumber, link });
        message.success("Mensaje enviado correctamente");
    }
    catch (error) {
        message.error(error.message);
    }
}


const SendSignatureWhatsapp = ({ open, setOpen, delivery }) => {
    const [whatsappNumber, setWhatsappNumber] = useState("");

    useEffect(() => {
        if (!open) {
            setWhatsappNumber("");
        }
    }, [open]);

    return (
        <Modal
            title="Enviar mensaje para firma por WhatsApp"
            visible={open}
            onOk={() => {
                sendWhatsapp(whatsappNumber, delivery)
                setOpen(false);
            }}
            onCancel={() => setOpen(false)}
            okText="Enviar"
            cancelText="Cancelar"
        >
            <Input
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                placeholder="Número de WhatsApp"
            />
            
        </Modal>
    );
}

export default SendSignatureWhatsapp;