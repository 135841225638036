import React, { useEffect, useState } from "react";
import { Form, Typography, Button, Space, Input, message, Modal } from "antd";
import PackagingList from "../components/PackagingList";

const { Title } = Typography;

const calculateTotalCost = (presentation, recipe, content) => {
    let packagingCost = presentation?.packaging?.reduce(
        (acc, item) => acc + parseFloat(item.baseCost || 0) * parseFloat(item.quantity || 0),
        0
    );

    if (isNaN(packagingCost)) packagingCost = 0;

    let contentCost = (recipe?.baseCost || 0) * parseFloat(content || 0);

    if (isNaN(contentCost)) contentCost = 0;

    return parseFloat(packagingCost + contentCost).toFixed(2);
};

const NewPresentation = ({ open, setOpen, addPresentation, recipe, item }) => {
    const [presentation, setPresentation] = useState({}); // State to store the presentation object
    const [totalCost, setTotalCost] = useState(0); // State to store the dynamically calculated total cost
    const [content, setContent] = useState(0); // State to store the content of the presentation
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            console.log('Open');
            if (item && !presentation.objectId) {
                console.log("populate");
                // Populate form and state with existing presentation data
                form.setFieldsValue({
                    name: item.name,
                    content: item.content,
                    minPrice: item.minPrice,
                });
                setPresentation(item);
            }
            else {
                console.log("reset");
                // Reset form and state for new presentation
                form.resetFields();
                setPresentation({});
            }
        }
    }, [open, item]);

    // Effect to recalculate total cost whenever presentation or form content changes
    useEffect(() => {
        const total = calculateTotalCost(presentation, recipe, content);
        setTotalCost(total);
        
    }, [presentation, form, recipe, content]);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                const presentationObject = {
                    ...values,
                    packaging: presentation.packaging,
                    baseCost: parseFloat(recipe?.baseCost || 0),
                    totalCost: parseFloat(totalCost || 0),
                    minPrice: parseFloat(values.minPrice || 0),
                };

                addPresentation(presentationObject);
                setOpen(false);
            })
            .catch((error) => {
                console.error("Validation Error:", error);
            });
    };

    return (
        <Modal
            title="Nueva presentación"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} name="newPresentationForm">
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: "Por favor ingrese el nombre de la presentación" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contenido"
                    name="content"
                    rules={[{ required: true, message: "Por favor ingrese el contenido de la presentación" }]}
                    onChange={(e) => {
                        setContent(e.target.value);
                        form.setFieldsValue({ content: e.target.value });
                    }}
                    value={content}
                >
                    <Input suffix="kg" />
                </Form.Item>

                <Form.Item
                    label="Precio mínimo"
                    name="minPrice"
                    rules={[{ required: true, message: "Por favor ingrese el precio de la presentación" }]}
                >
                    <Input />
                </Form.Item>

                <Title level={5}>Empaque</Title>
                <PackagingList
                    recipe={recipe}
                    presentation={presentation}
                    setPresentation={setPresentation}
                />
            </Form>

            <Title level={5}>Costo total: ${totalCost}</Title>
        </Modal>
    );
};

export default NewPresentation;