import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message, Flex, Tabs, Checkbox } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
import AddressAutocomplete from "../../../form/PlacesSelect";
import {setACL} from "../../../../utils/ACL";
import { fiscalRegimesPersonasFisicas, fiscalRegimesPersonasMorales } from "../utils/fiscalRegimes";
import { v4 as uuidv4 } from 'uuid';


const NewClient = ({ open, setOpen, refresh, client, editing }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [clientType, setClientType] = React.useState("physical");
    const [fiscalType, setFiscalType] = React.useState("physical");
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (editing && open) {

            setClientType(client.clientType);
            setFiscalType(client.fiscalInformation.personType);

            if (client) form.setFieldsValue({
                email: client.email,
                firstName: client.firstName,
                lastName: client.lastName,
                fullName: client.fullName,
                clientType: client.clientType,
                contactPerson: client.contactPerson,
                phoneNumber: client.phoneNumber,
            });
            if (client?.address) form.setFieldsValue({
                address: client.address.description,
                street: client.address.street,
                extNumber: client.address.extNumber,
                intNumber: client.address.intNumber,
                zipCode: client.address.zipCode,
                neighborhood: client.address.neighborhood,
                city: client.address.city,
                state: client.address.state,
                geoPoint: client.address.geoPoint,
            });
            if (client?.fiscalInformation) form.setFieldsValue({
                fiscalStreet: client.fiscalInformation.address.street,
                fiscalExtNumber: client.fiscalInformation.address.extNumber,
                fiscalIntNumber: client.fiscalInformation.address.intNumber,
                fiscalZipCode: client.fiscalInformation.address.zipCode,
                fiscalNeighborhood: client.fiscalInformation.address.neighborhood,
                fiscalCity: client.fiscalInformation.address.city,
                fiscalState: client.fiscalInformation.address.state,
                fiscalAddress: client.fiscalInformation.address.description,
                fiscalGeoPoint: client.fiscalInformation.address.geoPoint,
                rfc: client.fiscalInformation.rfc,
                fiscalRegime: client.fiscalInformation.fiscalRegime,
                fiscalType: client.fiscalInformation.personType,
                businessName: client.fiscalInformation.businessName,
            });

        } else {
        form.setFieldsValue({
            email: '',
            fistName: '',
            lastName: '',
            businessName: '',
            clientType: 'physical',
        });
        }
    }, [open, client, editing, form, renderId]);

    const handleAddressSelect = async (
        value,
        place,
        setValue,
        clearSuggestions,
        getGeocode,
        getLatLng,
      ) => {
        console.log("📍 Selected Place: ", place);
      
        const { description } = place;
      
        // Set the input value and clear autocomplete suggestions
        setValue(description, false);
        clearSuggestions();
      
        try {
          // Get geocode results using the selected description
          const results = await getGeocode({ address: description });
          const geocodeResult = results[0];
      
          if (!geocodeResult) {
            console.error("No geocode results found");
            return;
          }
      
          const { address_components, geometry } = geocodeResult;
      
          // Extract structured address details
          const getAddressComponent = (type) =>
            address_components.find((component) => component.types.includes(type))?.long_name || "";
      
          const addressDetails = {
            street: getAddressComponent("route"),
            extNumber: getAddressComponent("street_number"),
            neighborhood: getAddressComponent("sublocality"),
            city: getAddressComponent("locality"),
            state: getAddressComponent("administrative_area_level_1"),
            zipCode: getAddressComponent("postal_code"),
            country: getAddressComponent("country"),
          };
      
          const { lat, lng } = await getLatLng(geocodeResult);
      
          // Complete address object
          const address = {
            description,
            lat,
            lng,
            ...addressDetails,
          };
      
          console.log("📍 Final Address Details: ", address);
      
          // Update form fields with extracted address details
          form.setFieldsValue({ address, ...addressDetails, geoPoint: new Parse.GeoPoint({ latitude: lat, longitude: lng }) });
        } catch (error) {
          console.error("Error fetching address details:", error);
        }
      };

      const handleFiscalAddressSelect = async (
        value,
        place,
        setValue,
        clearSuggestions,
        getGeocode,
        getLatLng,
        ) => {
        console.log("📍 Selected Place: ", place)
            
        const { description } = place;

        // Set the input value and clear autocomplete suggestions
        setValue(description, false);
        clearSuggestions();

        try {
            // Get geocode results using the selected description
            const results = await getGeocode({ address: description });
            const geocodeResult = results[0];

            if (!geocodeResult) {
                console.error("No geocode results found");
                return;
            }

            const { address_components, geometry } = geocodeResult;

            // Extract structured address details
            const getAddressComponent = (type) =>
                address_components.find((component) => component.types.includes(type))?.long_name || "";
                
            const addressDetails = {
                fiscalStreet: getAddressComponent("route"),
                fiscalExtNumber: getAddressComponent("street_number"),
                fiscalNeighborhood: getAddressComponent("sublocality"),
                fiscalCity: getAddressComponent("locality"),
                fiscalState: getAddressComponent("administrative_area_level_1"),
                fiscalZipCode: getAddressComponent("postal_code"),
                fiscalCountry: getAddressComponent("country"),
            };

            const { lat, lng } = await getLatLng(geocodeResult);

            // Complete address object
            const address = {
                description,
                lat,
                lng,
                ...addressDetails,
            };

            console.log("📍 Final Address Details: ", address)
                
            // Update form fields with extracted address details
            form.setFieldsValue({ fiscalAddress: address, ...addressDetails, fiscalGeoPoint: new Parse.GeoPoint({ latitude: lat, longitude: lng }) });
        } catch (error) {
            console.error("Error fetching address details:", error);
        }
    };


      

    const moralPerson = () => {
        return <Flex vertical>
        <Form.Item
            label="Nombre"
            name="fullName"
            rules={[{ required: true, message: 'Nombre' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Persona de contacto"
            name="contactPerson"
            rules={[{ required: true, message: 'Persona de contacto' }]}
        >
            <Input />
        </Form.Item>
        </Flex>
    };

    const physicalPerson = () => {
        return <Flex vertical>
            <Form.Item
                label="Nombre"
                name="firstName"
                rules={[{ required: true, message: 'Nombre' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Apellidos"
                name="lastName"
                rules={[{ required: true, message: 'Apellidos' }]}
            >
                <Input />
            </Form.Item>
        </Flex>
    };


// Helper function to handle the creation of an Address
const createAddress = async (addressData) => {
    const Address = Parse.Object.extend("Address");
    const newAddress = new Address();

    console.log("📍 Address Data: ", addressData)

    if (addressData.objectId) {
        console.log("📍 Updating Address: ", addressData.objectId);
        newAddress.set("objectId", addressData.objectId);
    }
    newAddress.set("street", addressData.street);
    newAddress.set("extNumber", addressData.extNumber);
    newAddress.set("intNumber", addressData.intNumber);
    newAddress.set("zipCode", addressData.zipCode);
    newAddress.set("neighborhood", addressData.neighborhood);
    newAddress.set("city", addressData.city);
    newAddress.set("state", addressData.state);
    newAddress.set("country", "México");
    newAddress.set("geoPoint", addressData.geoPoint);
    newAddress.set("description", addressData.description?.description);

    const addressACL = new Parse.ACL();
    addressACL.setPublicReadAccess(true);
    addressACL.setPublicWriteAccess(true);
    newAddress.setACL(addressACL);

    try {
        return await newAddress.save();
    } catch (error) {
        console.error("Error while creating Address: ", error);
        throw error;
    }
};

// Helper function to save Fiscal Information
const createFiscalInformation = async ({ values, address, objectId, fiscalAddressObjectId }) => {
    const FiscalInformation = Parse.Object.extend("FiscalInformation");
    const newFiscalInformation = new FiscalInformation();

    console.log("sameAddress", values.sameAddress);

    if (objectId) {
        console.log("📍 Updating Fiscal Information: ", objectId);
        newFiscalInformation.set("objectId", objectId);
    }
    newFiscalInformation.set("rfc", values.rfc);
    newFiscalInformation.set("fiscalRegime", values.fiscalRegime);
    newFiscalInformation.set("personType", values.clientType);
    newFiscalInformation.set("businessName", values.businessName);

    const fiscalInformationACL = new Parse.ACL();
    fiscalInformationACL.setPublicReadAccess(true);
    fiscalInformationACL.setPublicWriteAccess(true);
    newFiscalInformation.setACL(fiscalInformationACL);

    try {
        const fiscalAddress = await createAddress({
            street: values.fiscalStreet,
            extNumber: values.fiscalExtNumber,
            intNumber: values.fiscalIntNumber,
            zipCode: values.fiscalZipCode,
            neighborhood: values.fiscalNeighborhood,
            city: values.fiscalCity,
            state: values.fiscalState,
            geoPoint: values.fiscalGeoPoint,
            description: values.fiscalAddress,
            objectId: fiscalAddressObjectId
        });
        newFiscalInformation.set("address", fiscalAddress);
        
        return await newFiscalInformation.save();
    } catch (error) {
        console.error("Error saving Fiscal Information: ", error);
        throw error;
    }
};

// Helper function to save Client
const saveClient = async (client) => {
    const Client = Parse.Object.extend("_User");
    const newClient = new Client();

    if (client.objectId) {
        console.log("📍 Updating Client: ", client.objectId);
        newClient.set("objectId", client.objectId);
    }
    newClient.set("username", client.email);
    newClient.set("password", generateRandomPassword());
    newClient.set("email", client.email);
    newClient.set("firstName", client.firstName?.trim());
    newClient.set("lastName", client.lastName?.trim());
    newClient.set("businessName", client.businessName?.trim());
    newClient.set('fullName', client.clientType === 'moral' ? client.fullName?.trim() : `${client.firstName?.trim()} ${client.lastName?.trim()}`);
    newClient.set("contactPerson", client.contactPerson?.trim());
    newClient.set("clientType", client.clientType);
    newClient.set("phoneNumber", client.phoneNumber);
    newClient.set("whatsappNumber", client.whatsappNumber);
    newClient.set('address', client.address);
    newClient.set('fiscalInformation', client.fiscalInformation);
    newClient.set('uuid', client.uuid);

    const acl = setACL("Cliente");
    newClient.setACL(acl);

    try {
        const savedClient = await newClient.save();
        const Role = Parse.Object.extend("_Role");
        const query = new Parse.Query(Role);
        query.equalTo("name", "Cliente");
        const role = await query.first();
        role.getUsers().add(savedClient);
        await role.save();
        return savedClient;
    } catch (error) {
        console.error("Error while creating Client: ", error);
        throw error;
    }
};

// Main createClient function orchestrating all helpers
const createClient = async () => {
    const values = form.getFieldsValue();
    setLoading(true);
    try {
        const address = await createAddress({
            street: values.street,
            extNumber: values.extNumber,
            intNumber: values.intNumber,
            zipCode: values.zipCode,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            geoPoint: values.geoPoint,
            description: values.address
        });

        const fiscalInformation = await createFiscalInformation({
            values,
            address
        });

        const newClient = await saveClient({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            contactPerson: values.contactPerson,
            clientType: values.clientType,
            phoneNumber: values.phoneNumber,
            whatsappNumber: values.whatsappNumber,
            fiscalInformation,
            address,
            uuid: uuidv4()
        });
        editing ? message.success("Cliente actualizado correctamente") :
        message.success("Cliente agregado correctamente");
        setOpen(false);
        refresh();
        setLoading(false);
    } catch (error) {
        console.error("Error while creating the client process: ", error);
        editing ? message.error("Error al actualizar el cliente") :
        message.error("Error al agregar el nuevo cliente");
        setLoading(false);
    }
};

const updateClient = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    console.log("📍Address objectId", client.address.objectId)

    try {
        const address = await createAddress({
            street: values.street,
            extNumber: values.extNumber,
            intNumber: values.intNumber,
            zipCode: values.zipCode,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            geoPoint: values.geoPoint,
            description: values.address,
            objectId: client.address.objectId
        });

        const fiscalInformation = await createFiscalInformation({ 
            values, 
            address, 
            objectId: client.fiscalInformation.objectId, 
            fiscalAddressObjectId: values.fiscalAddress?.objectId
         });

        const newClient = await saveClient({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: values.fullName || `${values.firstName} ${values.lastName}`,
            contactPerson: values.contactPerson,
            clientType: values.clientType,
            phoneNumber: values.phoneNumber,
            whatsappNumber: values.whatsappNumber,
            fiscalInformation,
            address,
            objectId: client.objectId
        });

        message.success("Cliente agregado correctamente");
        setOpen(false);
        refresh();
        setLoading(false);
    } catch (error) {
        console.error("Error while creating the client process: ", error);
        message.error("Error al agregar el nuevo cliente");
        setLoading(false);
    }
};

const setSameAddress = () => {
    form.setFieldsValue({
        fiscalStreet: form.getFieldValue("street"),
        fiscalExtNumber: form.getFieldValue("extNumber"),
        fiscalIntNumber: form.getFieldValue("intNumber"),
        fiscalZipCode: form.getFieldValue("zipCode"),
        fiscalNeighborhood: form.getFieldValue("neighborhood"),
        fiscalCity: form.getFieldValue("city"),
        fiscalState: form.getFieldValue("state"),
        fiscalAddress: form.getFieldValue("address"),
        fiscalGeoPoint: form.getFieldValue("geoPoint"),
    });
};



    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            if (editing) {
                updateClient();
            } else {
                createClient();
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title={editing ? "Editar cliente" : "Nuevo cliente"}
            open={open}
            onCancel={() => setOpen(false)}
            width={800}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Información general" key="1">
                        
                <Form.Item
                    label="Tipo de cliente"
                    name="clientType"
                    rules={[{ required: true, message: 'Tipo' }]}
                    shouldUpdate
                >
                    <Select shouldUpdate onChange={(value) => {
                        if (value === "physical") {
                            form.setFieldsValue({ clientType: "physical" });
                            setClientType("physical");
                        } else {
                            form.setFieldsValue({ clientType: "moral" });
                            setClientType("moral");
                        }
                    }}
                    value={clientType}
                    >
                        <Select.Option value="physical">Persona física</Select.Option>
                        <Select.Option value="moral">Persona moral</Select.Option>
                    </Select>
                </Form.Item>
               {form.getFieldValue("clientType") === "physical" ? physicalPerson() : moralPerson()}
               <Form.Item
                    label="Teléfono"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Teléfono' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="WhatsApp"
                    name="whatsappNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{ required: true, message: 'Correo electrónico' }]}
                >
                    <Input />
                </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Domicilio" key="2">
               <Form.Item label="Dirección" name="address" rules={[{ required: true, message: "Ingresa una dirección" }]}>
                    <AddressAutocomplete onSelect={handleAddressSelect} parentValue={form.getFieldValue("address")} />
                </Form.Item>
                <Form.Item
                    label="Calle"
                    name="street"
                        rules={[{ required: true, message: 'Calle' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Número exterior"
                        name="extNumber"
                        rules={[{ required: true, message: 'Número exterior' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Número interior"
                        name="intNumber"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Código postal"
                        name="zipCode"
                        rules={[{ required: true, message: 'Código postal' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Colonia"
                        name="neighborhood"
                        rules={[{ required: true, message: 'Colonia' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Ciudad"
                        name="city"
                        rules={[{ required: true, message: 'Ciudad' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Estado"
                        name="state"
                        rules={[{ required: true, message: 'Estado' }]}
                    >
                        <Input />
                    </Form.Item>
                </Tabs.TabPane>
               
                <Tabs.TabPane tab="Información fiscal" key="4">
                <Form.Item
                    label="Tipo de persona"
                    name="fiscalType"
                    rules={[{ required: true, message: 'Tipo de persona' }]}    
                >
                    <Select onChange={(value) => {
                        if (value === "physical") {
                            form.setFieldsValue({ fiscalType: "physical" });
                            form.setFieldsValue({ fiscalRegime: "" });
                            setFiscalType("physical");
                        } else {
                            form.setFieldsValue({ fiscalType: "moral" });
                            form.setFieldsValue({ fiscalRegime: "" });
                            setFiscalType("moral");
                        }
                    }}
                    value={fiscalType}
                    >
                        <Select.Option value="physical">Persona física</Select.Option>
                        <Select.Option value="moral">Persona moral</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Razón social"
                    name="businessName"
                    rules={[{ required: true, message: 'Razón social' }]}
                >
                    <Input />
                </Form.Item>
                 <Form.Item
                    label="RFC"
                    name="rfc"
                    rules={[{ required: true, message: 'RFC' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Régimen fiscal"
                    name="fiscalRegime"
                    rules={[{ required: true, message: 'Régimen fiscal' }]}
                >
                    <Select showSearch onSearch={(value) => {
                        if (form.getFieldValue("fiscalType") === "physical") {
                            return fiscalRegimesPersonasFisicas.filter((regime) => regime.name.toLowerCase().includes(value.toLowerCase()));
                        }
                        return fiscalRegimesPersonasMorales.filter((regime) => regime.name.toLowerCase().includes(value.toLowerCase()));
                    }}
                    onChange={(value, item) => {
                        console.log("📍 Selected Fiscal Regime: ", item)
                        form.setFieldsValue({ fiscalRegime: { ...item } });
                        }}>
                        {form.getFieldValue("fiscalType") === "physical" ? fiscalRegimesPersonasFisicas.map((regime) => (
                            <Select.Option key={regime.value} value={regime.value}>{regime.name}</Select.Option>
                        )) : fiscalRegimesPersonasMorales.map((regime) => (
                            <Select.Option key={regime.value} value={regime.value}>{regime.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button onClick={() => setSameAddress()}>
                    Usar el mismo domicilio
                </Button>
                <Flex vertical>
                <Form.Item
                    label="Dirección fiscal"
                    name="fiscalAddress"
                    rules={[{ required: true, message: 'Dirección fiscal' }]}
                >
                    <AddressAutocomplete onSelect={handleFiscalAddressSelect} parentValue={form.getFieldValue("fiscalAddress")} />
                </Form.Item>
                <Form.Item
                    label="Calle"
                    name="fiscalStreet"
                    rules={[{ required: true, message: 'Calle' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Número exterior"
                    name="fiscalExtNumber"
                    rules={[{ required: true, message: 'Número exterior' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Número interior"
                    name="fiscalIntNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Código postal"
                    name="fiscalZipCode"
                    rules={[{ required: true, message: 'Código postal' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Colonia"
                    name="fiscalNeighborhood"
                    rules={[{ required: true, message: 'Colonia' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ciudad"
                    name="fiscalCity"
                    rules={[{ required: true, message: 'Ciudad' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Estado"
                    name="fiscalState"
                    rules={[{ required: true, message: 'Estado' }]}
                >
                    <Input />
                </Form.Item>
                </Flex>


                </Tabs.TabPane>
                </Tabs> 
            </Form>
        </Modal>
    );
}

export default NewClient;
