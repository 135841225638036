import Parse from "parse";

const fetchSuppliers = async (filters) => {
    return new Promise((resolve, reject) => {
        const Role = Parse.Object.extend("_Role");
        const roleQuery = new Parse.Query(Role);
        roleQuery.equalTo("name", "Proveedor");
        roleQuery.first()
            .then((role) => {
                const relation = role.relation("users");
                const query = relation.query();
                query.include(["address", "fiscalInformation", "fiscalInformation.address"]);
                if (filters) {
                    if (filters.name) {
                        query.matches("fullName", filters.name, "i");
                    }
                    if (filters.email) {
                        query.matches("email", filters.email, "i");
                    }
                    if (filters.phoneNumber) {
                        query.matches("phoneNumber", filters.phoneNumber, "i");
                    }
                    // if (filters.address) {
                    //     query.matches("address.address", filters.address, "i");
                    // }
                }
                return query.find();
            })
            .then((results) => {
                resolve(results.map((result) => {
                    return {
                        ...result.toJSON(),
                        value: result.get("fullName"),
                        label: result.get("fullName"),
                        key: result.id,
                    };
                }));
            })
            .catch((error) => {
                console.error("Error al obtener los proveedores:", error); // Verifica si hay algún error
                reject(error);
            });
    });
};

export { fetchSuppliers };
