import Parse from "parse";
import { message } from "antd";
// Function to update stock and save stock movements
const updateStockAndMovements = async (ingredients, productionEvent) => {
    const Ingredient = Parse.Object.extend("Ingredient");
    const StockMovement = Parse.Object.extend("StockMovement");

    const updates = ingredients?.map(async (ingredient) => {
        try {
            const ingredientQuery = new Parse.Query(Ingredient);
            ingredientQuery.equalTo("objectId", ingredient.objectId);
            const ingredientResult = await ingredientQuery.first();

            if (ingredientResult) {
                const previousStock = ingredientResult.get("stock");
                const newStock = previousStock - ingredient.quantity;

                ingredientResult.set("stock", newStock);
                await ingredientResult.save();

                const stockMovement = new StockMovement();
                stockMovement.set("ingredient", { __type: "Pointer", className: "Ingredient", objectId: ingredient.objectId });
                stockMovement.set("quantity", ingredient.quantity);
                stockMovement.set("type", "Agregado a producción");
                stockMovement.set("warehouse", "Forrajes");
                stockMovement.set("productionEvent", { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id });
                stockMovement.set("previousStock", previousStock);
                stockMovement.set("currentStock", newStock);
                stockMovement.set("user", Parse.User.current());

                await stockMovement.save();
            }
        } catch (error) {
            console.error("Error updating stock or movements:", error);
            throw error;
        }
    });

    return Promise.all(updates);
};

// Function to add ingredients to the hopper
const addToHopper = async (ingredients, hopper, production) => {
    const ProductionItems = Parse.Object.extend("ProductionItems");
    const query = new Parse.Query(ProductionItems);
    query.equalTo("objectId", hopper.objectId);

    try {
        const hopperResult = await query.first();

        if (hopperResult) {
            const currentIngredients = hopperResult.get("ingredients") || [];
            const updatedIngredients = currentIngredients?.map((ingredient) => {
                const addedIngredient = ingredients.find((i) => i.objectId === ingredient.objectId);
                return addedIngredient
                    ? { ...ingredient, quantity: ingredient.quantity + addedIngredient.quantity }
                    : ingredient;
            });

            const newIngredients = ingredients.filter(
                (ingredient) => !currentIngredients.some((i) => i.objectId === ingredient.objectId)
            );

            hopperResult.set("ingredients", [...updatedIngredients, ...newIngredients]);
            hopperResult.set("product", { __type: "Pointer", className: "Products", objectId: production.product.objectId });
            hopperResult.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
            
            await hopperResult.save();
        }
    } catch (error) {
        console.error("Error adding to hopper:", error);
        throw error;
    }
};

// Function to update the production order
const updateProductionOrder = async (production, productionEvent) => {
    const ProductionOrder = Parse.Object.extend("ProductionOrder");
    const query = new Parse.Query(ProductionOrder);
    query.equalTo("objectId", production.objectId);

    try {
        const productionOrder = await query.first();

        if (productionOrder) {
            const previousEvents = productionOrder.get("productionEvents") || [];
            productionOrder.set("productionEvents", [
                ...previousEvents,
                { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
            ]);
            await productionOrder.save();
        }
    } catch (error) {
        console.error("Error updating production order:", error);
        throw error;
    }
};

// Main function to handle adding ingredients
export const addIngredients = async (production, ingredients) => {
    try {
        const ProductionEvent = Parse.Object.extend("ProductionEvents");
        const productionEvent = new ProductionEvent();

        productionEvent.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        productionEvent.set("event", "addIngredients");
        productionEvent.set("warehouse", "Forrajes");
        productionEvent.set("ingredients", ingredients);
        productionEvent.set("user", Parse.User.current());

        const savedEvent = await productionEvent.save();

        await updateStockAndMovements(ingredients, savedEvent);
        await addToHopper(ingredients, production.mixingHopper, production);
        await updateProductionOrder(production, savedEvent);

        message.success("Ingredientes agregados correctamente");
    } catch (error) {
        console.error("Error adding ingredients:", error);
        message.error("Error al agregar ingredientes. Por favor, intente nuevamente.");
    }
};