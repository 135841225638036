import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row, Table, Typography, Tag, Input, FloatButton } from "antd";
import NewSeller from "./components/NewSeller";
import { fetchSellers } from "./utils/query";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import debounce from "lodash.debounce";

const { Title } = Typography;

const SellersList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [newSellerModal, setNewSellerModal] = useState(false);
  const [filters, setFilters] = useState({});
  const { permissions } = useContext(UserContext);
  const navigate = useNavigate();

  // Fetch sellers based on filters
  useEffect(() => {
    const getSellers = async () => {
      try {
        const sellers = await fetchSellers(filters);
        setDataSource(sellers);
      } catch (error) {
        console.error("Error fetching sellers:", error);
      }
    };

    getSellers();
  }, [filters]);

  // Check if user can create sellers
  const allowCreateSeller = () =>
    permissions?.some((p) => p.group === "sellers" && p.action === "create");

  // Columns for the Table
  const columns = [
    // {
    //   dataIndex: "img",
    //   key: "img",
    //   render: (img) => (
    //     <img
    //       src={img || "https://via.placeholder.com/45"}
    //       alt="avatar"
    //       className="avatar-sm mr-10"
    //       style={{ width: "45px", borderRadius: "50%" }}
    //     />
    //   ),
    // },
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
      render: (name, seller) => (
        <a onClick={() => navigate(`/sellers/${seller.uuid}`)}>
          <h3>{name}</h3>
        </a>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Número de teléfono",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Volumen de ventas",
      dataIndex: "sales",
      key: "sales",
      render: (sales) => {
        const totalSales = sales?.reduce((acc, sale) => acc + sale.total, 0) || 0;
        return <Tag color="blue">${totalSales.toLocaleString()}</Tag>;
      },
    },
  ];

  // Render Filters with Debounce
  const debouncedSetFilters = debounce((newFilters) => setFilters(newFilters), 300);

  const renderFilters = () => (
    <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
      <Input
        placeholder="Nombre"
        onChange={(e) => debouncedSetFilters({ ...filters, name: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Correo electrónico"
        onChange={(e) => debouncedSetFilters({ ...filters, email: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Número de teléfono"
        onChange={(e) => debouncedSetFilters({ ...filters, phoneNumber: e.target.value })}
        style={{ width: 200 }}
      />
    </div>
  );

  return (
    <div className="layout-content">
      {allowCreateSeller() && (
        <FloatButton
          onClick={() => setNewSellerModal(true)}
          tooltip="Nuevo vendedor"
          style={{ right: 20, bottom: 20 }}
        />
      )}
      <NewSeller open={newSellerModal} setOpen={setNewSellerModal} refresh={() => setFilters({ ...filters })} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
            <h2 className="p-15 h2">Vendedores</h2>
            {renderFilters()}
          </div>
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <Table
              dataSource={dataSource}
              columns={columns}
              rowKey={(record) => record.objectId || record.uuid}
              pagination={true}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SellersList;