import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select, message, Popconfirm} from "antd";
import Parse from "parse";
import DebounceSelect from "../../../form/DebounceSelect";
import IngredientsList from "./IngredientsList";
import { fetchClient, fetchProducts, fetchSeller } from "../utils/query";
import AddressAutocomplete from "../../../form/PlacesSelect";
import { padStart } from "@fullcalendar/core/internal";
import { UserContext } from "../../../../context/UserContext";
import { set } from "parse/lib/browser/CoreManager";
import { fetchSuppliers } from "../../suppliers/utils/query";
const { Title } = Typography;

const transformProduct = (product) => {
    return [{
      name: product.name,
      basePresentation: product.basePresentation,
      warehouse: product.warehouse,
      objectId: product.objectId,
      quantity: parseInt(product.quantity, 10),
      presentation: {
        name: product.presentation.label,
        quantity: parseInt(product.presentation.quantity, 10)
      },
      unitPrice: parseFloat(product.unitPrice),
      total: product.total,
      purchaseUnitQuantity: parseInt(product.quantity, 10),
      baseUnitQuantity: product.quantity * product.presentation.quantity,
    }];
  };

const NewPurchaseOrder = ({open, setOpen, refresh, editing, sale, refreshSale, versionNumber}) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { permissions, user } = useContext(UserContext);


    
    const handleCancel = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({ products: [] });
        if (user) {
            form.setFieldsValue({ seller: {
                key: user.objectId,
                label: user.fullName,
                value: user.fullName,
                ...user
            }});
        }

        if (editing) {
            console.log("editing", sale);
            form.setFieldsValue({
                client: {
                    key: sale.client.objectId,
                    label: sale.client.fullName,
                    value: sale.client.fullName,
                    ...sale.client
                },
                seller: {
                    key: sale.seller.objectId,
                    label: sale.seller.fullName,
                    value: sale.seller.fullName,
                    ...sale.seller
                },
                // deliveryDate: new Date(sale.deliveryDate?.iso),
                deliveryPlace: sale.deliveryPlace,
                deliveryAddress: sale.deliveryAddress,
                deliveryPrice: sale.deliveryPrice,
                deliveryNotes: sale.deliveryNotes,
                products: sale.products,
                notes: sale.notes
            })
        }
    }, [open, user, editing]);

    useEffect(() => {
        allowSave();
    }, [form.getFieldsValue()]);

    const allowSave = () => {
       let error = false;
        const values = form.getFieldsValue();
        console.log("values", values);
        if (!values.supplier || !values.products || !values.products.length || (editing && !values.modificationComment)) {
            error = true;
        }
        setDisableSave(error);
    };
    

   const calculateTotal = () => {
        const products = form.getFieldValue("products");
        const deliveryPrice = form.getFieldValue("deliveryPrice") || 0;
        let total = products?.reduce((acc, product) => acc + product.total, 0) + Number(deliveryPrice);
        total = total.toFixed(2);
        return !isNaN(total) ? total : 0;
    }

    const handleAddressSelect = (value, place, setValue, clearSuggestions, getGeocode, getLatLng) => {
        let deliveryAddress = {}
        console.log("📍 Address: ", place);
        const { description, terms } = place;
        setValue(description, false);
        clearSuggestions();
    
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            // console.log("📍 Coordinates: ", { lat, lng });
            deliveryAddress = {
                address: description,
                lat: lat,
                lng: lng,
                terms
            };
            console.log("deliveryAddress", deliveryAddress);
            form.setFieldsValue({ deliveryAddress });
            setDeliveryAddress(deliveryAddress);
        })
        .catch((error) => {
            console.log("😱 Error: ", error);
        });

        

    };

    console.log("form", form.getFieldValue("deliveryAddress"));

    const saveNewPurchaseOrder = async () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values);

        const products = values.products.map((product) => transformProduct(product)).flat();

        const Index = Parse.Object.extend("Index");
        const query = new Parse.Query(Index);
        query.equalTo("name", "PurchaseOrder");
        const index = await query.first();

        const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
        const purchaseOrder = new PurchaseOrder();
        purchaseOrder.set("supplier", {
            __type: "Pointer",
            className: "_User",
            objectId: values.supplier.key,
        });
        purchaseOrder.set("createdBy", Parse.User.current());
        purchaseOrder.set("products", products);
        purchaseOrder.set("productsPointers", values.products.map((product) => ({ __type: "Pointer", className: "Ingredient", objectId: product.objectId })));
        purchaseOrder.set("notes", values.notes);
        purchaseOrder.set("total", parseFloat(calculateTotal()));
        purchaseOrder.set("purchaseOrderNumber", "OC" + padStart((index.get("value")+1).toString(), 6, "0") + "-" + new Date().getFullYear());
        purchaseOrder.set("status", "Pendiente");
        
        purchaseOrder.save().then(
            (result) => {
                index.increment("value");
                index.save();
                console.log("PurchaseOrder created", result);
                message.success("Orden de compra creada correctamente");
                setOpen(false);
                refresh();
            },
            (error) => {
                console.error("Error while creating PurchaseOrder: ", error);
                message.error("Error al crear la orden de compra");
            }
        );
    };

    const saveEditQuote = async () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values);

        const Sales = Parse.Object.extend("Sales");
        const saleObject = new Sales();

        saleObject.set("client", {__type: "Pointer", className: "_User", objectId: values.client.key});
        saleObject.set("seller", {__type: "Pointer", className: "_User", objectId: values.seller.key});
        saleObject.set("deliveryDate", new Date(values.deliveryDate));
        saleObject.set("deliveryPlace", values.deliveryPlace);
        saleObject.set("deliveryAddress", values.deliveryAddress);
        saleObject.set("deliveryPrice", values.deliveryPrice);
        saleObject.set("deliveryNotes", values.deliveryNotes);
        saleObject.set("products", values.products);
        saleObject.set("notes", values.notes);
        saleObject.set("total", parseFloat(calculateTotal()));
        saleObject.set("quoteNumber", sale.quoteNumber);
        saleObject.set("status", 'Cotización');
        saleObject.set("versionNumber", versionNumber + 1);
        saleObject.set("modificationComment", values.modificationComment);

        const previousSale = new Sales();
        previousSale.set("objectId", sale.objectId);
        previousSale.set("active", false);
        
        Parse.Object.saveAll([saleObject, previousSale]).then(
            (result) => {
                console.log("Sales created", result);
                message.success("Cotización editada correctamente");
                setOpen(false);
                refreshSale();
            },
            (error) => {
                console.error("Error while creating Sales: ", error);
                message.error("Error al crear la cotización");
            }
        );
        setConfirmLoading(false);
    };


    const handleSave = async () => {
        if (editing) {
            setConfirmLoading(true);
        } else {
            setConfirmLoading(false);
            saveNewPurchaseOrder();
        }
    }
    
    return (
        <Modal
        title={editing ? "Editar orden de compra" : "Nueva orden de compra"}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        >
        <Form form={form} layout="vertical">
            <Form.Item label="Proveedor" name="supplier" rules={[{ required: true, message: "Selecciona un proveedor" }]}>
            <DebounceSelect
                fetchOptions={fetchSuppliers}
                placeholder="Buscar proveedor"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            </Form.Item>
            
            {/* <Space direction="horizontal" style={{ width: "100%" }}>
            <Form.Item label="Fecha de entrega" name="deliveryDate" rules={[{ required: true, message: "Selecciona una fecha" }]} shouldUpdate>
                <DatePicker style={{ width: "100%" }} placeholder="Selecciona una fecha" />
            </Form.Item>
           
            </Space> */}
           

            <Form.Item label="Productos" name="products" rules={[{ required: true, message: "Selecciona al menos un producto" }]}>
                <IngredientsList form={form} setRenderId={setRenderId} />
            </Form.Item>

            {!editing ? <Form.Item label="Notas" name="notes">
                <Input.TextArea placeholder="Información adicional" />
            </Form.Item> : <Form.Item label="Motivo de modificación" name="modificationComment">
                <Input placeholder="Motivo de modificación" />
            </Form.Item>}
            
        </Form>
        <Space direction="horizontal" style={{ width: "100%" }}>
            <Title level={4}>Total: ${String(calculateTotal()).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
        </Space>
        <Popconfirm
            title="Editando cotización"
            description="Guardar esta cotización la marcará como activa"
            open={confirmLoading}
            onConfirm={saveEditQuote}
            onCancel={() => setConfirmLoading(false)}
            >
            <Button type="primary" htmlType="submit" style={{ position: "absolute", bottom: 20, right: 20 }} disabled={disableSave} onClick={handleSave}>
                    Guardar
            </Button>
            </Popconfirm>
        </Modal>
    );
    }

export default NewPurchaseOrder;