import React from "react";
import { Card, Form, Row, Col, Input } from "antd";
import Title from "antd/lib/typography/Title";

const SupplierAddress = ({ supplier }) => (
  <Card
    id="supplier-sales"
    className="mb-24 header-solid"
    bordered={false}
    title={<h5 className="mb-0">Domicilio</h5>}
  >
    <Form layout="vertical">
      <Row gutter={[30, 20]}>
      <Card id="supplier-sales" className="mb-24 header-solid" bordered={false}>
        <Form layout="vertical">
          <Row gutter={[30, 20]}>
            <Col span={24} lg={12}>
              <Form.Item label="Calle" colon={false}>
                <Input value={supplier?.address?.street} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Número exterior" colon={false}>
                <Input value={supplier?.address?.extNumber} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Número interior" colon={false}>
                <Input value={supplier?.address?.intNumber} />
              </Form.Item>
            </Col>
          
            <Col span={24} lg={12}>
              <Form.Item label="Colonia" colon={false}>
                <Input value={supplier?.address?.neighborhood} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Municipio" colon={false}>
                <Input value={supplier?.address?.city} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Estado" colon={false}>
                <Input value={supplier?.address?.state} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Código Postal" colon={false}>
                <Input value={supplier?.address?.zipCode} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card id="supplier-sales" className="mb-24 header-solid" bordered={false} title={<h5 className="mb-0" key={0}>Información fiscal</h5>}>
        <Form layout="vertical">
          <Row gutter={[30, 20]}>
          <Col span={24} lg={12}>
              <Form.Item label="Razón social" colon={false}>
                <Input value={supplier?.fiscalInformation?.businessName} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="RFC" colon={false}>
                <Input value={supplier?.fiscalInformation?.rfc} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Régimen fiscal" colon={false}>
                <Input value={supplier?.fiscalInformation?.fiscalRegime?.children} />
              </Form.Item>
            </Col>
            <Col span={24} lg={24}>
              <Title level={5}>Dirección fiscal</Title>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Calle" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.street} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Número exterior" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.extNumber} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Número interior" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.intNumber} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Colonia" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.neighborhood} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Municipio" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.city} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Estado" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.state} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Código Postal" colon={false}>
                <Input value={supplier?.fiscalInformation?.address?.zipCode} />
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Card>
      </Row>
    </Form>
  </Card>
);

export default SupplierAddress;