import React, { useState, useContext } from 'react';
import { Table, Button, Flex, Tag } from 'antd';
import moment from 'moment';
import { UserContext } from '../../../../../context/UserContext';
import DeliveryDetail from './modals/DeliveryDetail';
import NewWarehouseOrder from './modals/NewWarehouseOrder';
import AdjustPurchaseTotal from './modals/AdjustPurchaseTotal';

const EntriesTab = ({ purchaseOrder, refresh }) => {
    const [showDelivery, setShowDelivery] = useState(false);
    const [delivery, setDelivery] = useState(null);
    const [openWarehouseOrder, setOpenWarehouseOrder] = useState(false);
    const [openAdjustTotal, setOpenAdjustTotal] = useState(false);
    const { permissions } = useContext(UserContext);

    const allowSendWarehouseOrder = () => {
        const totalProducts = purchaseOrder?.products?.reduce((acc, product) => acc + parseInt(product.purchaseUnitQuantity), 0) || 0;
        const totalDelivered = purchaseOrder?.entries?.reduce((acc, entry) => acc + entry.products.reduce((acc, product) => acc + parseInt(product.purchaseUnitReceived), 0), 0) || 0;
        console.log("🚚 Total Products: ", totalProducts)
        console.log("🚚 Total Delivered: ", totalDelivered);

        if (!purchaseOrder) return false;
        if (purchaseOrder.status !== 'Confirmada') return false;
        if (totalProducts === totalDelivered) return false;
        if (purchaseOrder.closed) return false;
        if (permissions.some((p) => p.group === 'warehouses' && p.action === 'createWarehouseRelease')) return true;
        return false;
    }

    const allowAdjustTotal = () => {
        if (purchaseOrder.closed) return false;
        return true;

    }

    const renderDeliveryStatus = (status) => {
        if (status === 'Pendiente') return <Tag color="purple">Pendiente</Tag>;
        if (status === 'Recibido') return <Tag color="blue">Recibido</Tag>;
        if (status === 'Confirmado') return <Tag color="green">Confirmado</Tag>;
        return <Tag color="red">Cancelado</Tag>;
    }

    const renderActions = (status, record) => <Flex gap="10px">
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Ver detalles</Button>
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Orden de entrada</Button>
            <Button onClick={() => {setShowDelivery(true); setDelivery(record)}}>Comprobante de entrada</Button>
        </Flex>;


  return (
    <Flex justify="end" vertical>
    <DeliveryDetail open={showDelivery} setOpen={setShowDelivery} delivery={delivery} refresh={refresh} />
    <NewWarehouseOrder open={openWarehouseOrder} setOpen={setOpenWarehouseOrder} purchaseOrder={purchaseOrder} refresh={refresh} />
    <AdjustPurchaseTotal open={openAdjustTotal} setOpen={setOpenAdjustTotal} purchaseOrder={purchaseOrder} refresh={refresh} />
    <Table
        dataSource={purchaseOrder?.entries}
        rowKey="objectId"
        pagination={false}
        scroll={{ x: true }}
    >
        <Table.Column
            title="Estado"
            dataIndex="status"
            key="status"
            render={(text) => renderDeliveryStatus(text)}
        />
        <Table.Column
            title="Fecha de creación"
            dataIndex="createdAt"
            key="createdAt"
            render={(text) => moment(text).format("DD/MM/YYYY")}
        />
         <Table.Column
            title="Fecha de recepción"
            dataIndex="receptionDate"
            key="receptionDate"
            render={(receptionDate) => receptionDate ? moment(receptionDate.iso).format("DD/MM/YYYY") : 'No recibido'}
        />
        <Table.Column
            title="Productos"
            dataIndex="products"
            key="products"
            render={(products) => products.map((product) => (
                <p>{product.delivered} {product.name}</p>
            ))}
        />
        <Table.Column 
            title="Recibido por"
            dataIndex="receivedBy"
            key="receivedBy"
            render={(receivedBy) => receivedBy ? receivedBy.fullName : 'Sin asignar'}
        />
        <Table.Column 
            title="Confirmado por"
            dataIndex="confirmedBy"
            key="confirmedBy"
            render={(confirmedBy) => confirmedBy ? confirmedBy.fullName : 'Sin asignar'}
        />
        <Table.Column
            title="Acciones"
            dataIndex="status"
            key="status"
            render={(status, record) => renderActions(status, record)}
        />
    </Table>
    <Flex gap="10px">
        {allowSendWarehouseOrder() && <Button type="primary" onClick={() => setOpenWarehouseOrder(true)}>Generar orden de entrada</Button>}
        {allowAdjustTotal() && <Button type="primary" onClick={() => setOpenAdjustTotal(true)}>Ajustar total de compra</Button>}
    </Flex>
    </Flex>
  );
};

export default EntriesTab;
