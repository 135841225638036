import React, { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Typography, Modal, Form, DatePicker, Select, message, Table} from "antd";
import Parse from "parse";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;

const transformProducts = (products) => {
    return products.map(product => {
        return {
            objectId: product.objectId,
            name: product.name,
            warehouse: product.warehouse,
            basePresentation: product.basePresentation,
            presentation: product.presentation,
            purchaseUnitQuantity: product.purchaseUnitQuantity,
            purchaseUnitReceived: 0,
            baseUnitQuantity: product.quantity * product.presentation.quantity,
            baseUnitReceived: 0,
            baseUnitCost: product.unitPrice,
        }
    })
}



const NewWarehouseOrder = ({open, setOpen, purchaseOrder, refresh}) => {
    const [disableSave, setDisableSave] = useState(false);
    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);

    console.log("📦 Order: ", order)

    const createOrder = async () => {
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'WarehouseEntry');
        query.equalTo('year', new Date().getFullYear());
        const index = await query.first();

        const Order = Parse.Object.extend('WarehouseEntry');
        const order = new Order();
        order.set('status', 'Pendiente');
        order.set('products', transformProducts(products));
        order.set('purchaseOrder', { __type: 'Pointer', className: 'PurchaseOrder', objectId: purchaseOrder.objectId });
        order.set('productPointers', products.map(product => ({ __type: 'Pointer', className: 'Ingredient', objectId: product.objectId })));
        order.set('createdBy', Parse.User.current());
        order.set('orderNumber', 'S' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());
        order.set('uuid', uuidv4());

        const PurchaseOrder = Parse.Object.extend('PurchaseOrder');
        const purchaseOrderObject = new PurchaseOrder();
        purchaseOrderObject.set('objectId', purchaseOrder.objectId);

        try {
            const outlet = await order.save();
            purchaseOrderObject.addUnique('entries', outlet);
            await purchaseOrderObject.save();
            index.increment('value');
            await index.save();

            message.success("Orden creada correctamente");
            refresh();
            setOpen(false);
        }
        catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        let totalReceived = {};
        purchaseOrder?.entries?.forEach(entry => {
            entry.products.forEach(product => {
                const productName = product?.name + product?.presentation?.name;
                console.log("📦 Product: ", product)
                if (!totalReceived[productName]) totalReceived[productName] = 0;
                totalReceived[productName] += product.purchaseUnitReceived;
            })
        })
        
        let products = purchaseOrder?.products?.map(product => {
            const productName = product?.name + product?.presentation?.name;
            return {
                objectId: product.objectId,
                presentation: product.presentation,
                pending: product.quantity - (totalReceived[productName] || 0),
                purchaseUnitQuantity: 0,
                ...product
            }
        })

        console.log('Total receivedddd', totalReceived)

        console.log("📦 Productsss", products)
        setProducts(products);
        setOrder(purchaseOrder)
    }, [purchaseOrder])

    if (!purchaseOrder) return null;

    const allowSave = () => {
        if (!purchaseOrder) return false;
        if (!order) return false;
        if (order?.length === 0) return false;
        if (products?.some(product => product?.pending - product?.purchaseUnitQuantity < 0)) return false;
        return true;
    }

    if (!purchaseOrder) return null;

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
        width="60%"
    >
        <Title level={4}>Nueva entrada de almacén</Title>
        <Table dataSource={products} columns={[
              {
                title: 'Producto',
                dataIndex: "name",
                key: 'name',
            },
            {
                title: 'Unidad',
                dataIndex: ["presentation", "name"],
                key: 'unit',
            },
            {
                title: 'Contenido',
                dataIndex: "presentation",
                key: 'content',
                render: (presentation, product) => `${product.quantity * presentation.quantity} ${product.basePresentation}`
            },
            {
                title: 'Pendiente',
                dataIndex: 'pending',
                key: 'pending',
                render: (text, record) => <p>{text} {record.presentation?.name}{text !== 1 ? 's' : ''}</p>
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (text, record) => <Input type="number" value={record?.purchaseUnitReceived} onChange={(e) => {
                    const value = e.target.value;
                    const newProducts = products.map(product => {
                        if (product.objectId === record.objectId) {
                            product.purchaseUnitQuantity = parseInt(value);
                        }
                        return product;
                    })
                    setProducts(newProducts);
                }
                } />
            },
                    
        ]} />
        <Flex justify="end" gap={20}>
            <Button type="default" onClick={() => setOpen(false)}>Cancelar</Button>
            <Button type="primary" onClick={createOrder} disabled={!allowSave()}>Guardar</Button>
        </Flex>
    </Modal>
}

export default NewWarehouseOrder;