import React from "react";
import { Card, Form, Row, Col, Input } from "antd";

const SupplierDetails = ({ supplier }) => (
  <Card
    id="basic-info"
    className="mb-24 header-solid"
    bordered={false}
    title={<h5 className="mb-0">Información del proveedor</h5>}
  >
    <Form layout="vertical">
      <Row gutter={[30, 20]}>
        <Col span={24} lg={12}>
          <Form.Item label="Nombre del proveedor" colon={false}>
            <Input value={supplier?.fullName} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Email" colon={false}>
            <Input value={supplier?.email} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Teléfono" colon={false}>
            <Input value={supplier?.phoneNumber} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Card>
);

export default SupplierDetails;