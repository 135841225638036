import React, { useState, useEffect, useContext } from "react";
import { Tabs, FloatButton } from "antd";
import { SellerInfo, SellerSales, SellerComissions, SellerVisits } from "./Tabs";
import { Card, Statistic, Row, Col } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Parse from "parse/lib/browser/Parse";
import { UserContext } from "../../../../context/UserContext";
import NewSeller from "../components/NewSeller";

const items = [
  {
    key: "1",
    label: `Información`,
    children: SellerInfo,
  },
  {
    key: "2",
    label: `Ventas`,
    children: SellerSales,
  },
  {
    key: "3",
    label: `Comisiones`,
    children: SellerComissions,
  },
  {
    key: "4",
    label: `Visitas`,
    children: SellerVisits,
  },
];
const StatisticsCard = () => {
  <Card title="Estadísticas" style={{ width: 300 }}>
    <Row gutter={16}>
      <Col span={12}>
        <Statistic
          title="Activos"
          value={1128}
          valueStyle={{ color: "#3f8600" }}
          prefix={<ArrowUpOutlined />}
          suffix="%"
        />
      </Col>
      <Col span={12}>
        <Statistic
          title="Inactivos"
          value={93}
          valueStyle={{ color: "#cf1322" }}
          prefix={<ArrowDownOutlined />}
          suffix="%"
        />
      </Col>
    </Row>
  </Card>;
};

const fetchSeller = async (uuid) => {
  const Role = Parse.Object.extend("_Role");
  const query = new Parse.Query(Role);
  query.equalTo("name", "Ventas");
  const role = await query.first();
  const relation = role.relation("users");
  const queryUser = relation.query();
  queryUser.equalTo("uuid", uuid);
  queryUser.include(["sales", "payments"]);
  const user = await queryUser.first();
  return user.toJSON();
};

const SellerDetails = () => {
  const [seller, setSeller] = useState(null);
  const [newSellerModal, setNewSellerModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { uuid } = useParams();
  const { user, permissions } = useContext(UserContext);

  const allowEdit = () => {
    return permissions?.some((p) => p.group === "sellers" && p.action === "create");
  };

  

  useEffect(() => {
    const getSeller = async () => {
      const seller = await fetchSeller(uuid);
      setSeller(seller);
    };
    getSeller();
  }, [uuid, refresh]);

  const update = () => {
    setRefresh(!refresh);
  };

  return (
    <div className="layout-content">
      {allowEdit() && <FloatButton onClick={() => setNewSellerModal(true)} />}
      <NewSeller open={newSellerModal} setOpen={setNewSellerModal} seller={seller} editing refresh={update} />
      <Card title={seller?.fullName} style={{ marginBottom: 20 }}>
        
      </Card>
      <Tabs defaultActiveKey="1">
        {items.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            <item.children seller={seller} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}


export default SellerDetails;