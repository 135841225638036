import React, { useEffect, useState } from "react";
import { Modal, Select, message } from "antd";
import Parse from "parse";

const SetHopperModal = ({ open, setOpen, production }) => {
    const [hoppers, setHoppers] = useState([]);
    const [selectedHopper, setSelectedHopper] = useState(null);

    useEffect(() => {
        const fetchHoppers = async () => {
            const ProductionItems = Parse.Object.extend("ProductionItems");
            const query = new Parse.Query(ProductionItems);
            query.equalTo("type", "Waiting Hopper");

            try {
                const results = await query.find();
                setHoppers(results.map((result) => result.toJSON()));
            } catch (error) {
                console.error("Error fetching hoppers:", error);
                message.error("Error al cargar las tolvas.");
            }
        };

        if (open) fetchHoppers();
    }, [open]);

    const handleSave = async () => {
        try {
            const ProductionOrder = Parse.Object.extend("ProductionOrder");
            const query = new Parse.Query(ProductionOrder);
            query.equalTo("objectId", production.objectId);

            const productionOrder = await query.first();

            if (productionOrder) {
                productionOrder.set("waitingHopper", { __type: "Pointer", className: "ProductionItems", objectId: selectedHopper.objectId });
                await productionOrder.save();

                message.success("Tolva asignada correctamente");
                setOpen(false);
            }
        } catch (error) {
            console.error("Error setting hopper:", error);
            message.error("Error al asignar la tolva.");
        }
    };

    return (
        <Modal
            title="Asignar Tolva"
            open={open}
            onOk={handleSave}
            onCancel={() => setOpen(false)}
        >
            <Select
                style={{ width: "100%" }}
                placeholder="Seleccione una tolva"
                onChange={(value) => setSelectedHopper(hoppers.find((h) => h.objectId === value))}
            >
                {hoppers.map((hopper) => (
                    <Select.Option key={hopper.objectId} value={hopper.objectId}>
                        {hopper.name}
                    </Select.Option>
                ))}
            </Select>
        </Modal>
    );
};

export default SetHopperModal;