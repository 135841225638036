import React, { useContext } from 'react';
import { Table, Card, Flex, Typography, Button, message, Row, Col } from 'antd';
import moment from 'moment';
import Parse from 'parse';
import { UserContext } from '../../../../../context/UserContext';

const { Title } = Typography;

const SummaryTab = ({ purchaseOrder, refresh }) => {
  const { permissions } = useContext(UserContext);

  // Check permissions for confirming the purchaseOrder
  const allowConfirm = () => {
    if (!purchaseOrder) return false;
    if (purchaseOrder.status !== 'Pendiente') return false;
    return true
    if (permissions.some(p => p.group === 'quotes' && p.action === 'confirmAll')) return true;
    if (
      permissions.some(p => p.group === 'quotes' && p.action === 'confirmOwn') &&
      purchaseOrder.seller.objectId === Parse.User.current().id
    ) return true;
    return false;
  };

  // Check permissions for authorizing the purchaseOrder
  const allowAuthorize = () => {
    if (!purchaseOrder) return false;
    if (purchaseOrder.status !== 'Confirmada' || !purchaseOrder.active) return false;
    if (permissions.some(p => p.group === 'quotes' && p.action === 'authorizeAll')) return true;
    return false;
  };

  // Handle purchaseOrder confirmation
  const confirmPurchaseOrder = async () => {
    const PurchaseOrder = Parse.Object.extend('PurchaseOrder');
    const purchaseOrderObject = new PurchaseOrder();
    purchaseOrderObject.set('objectId', purchaseOrder.objectId);
    purchaseOrderObject.set('status', 'Confirmada');
    purchaseOrderObject.set('confirmationDate', new Date());
    purchaseOrderObject.set('confirmedBy', Parse.User.current());

    const Supplier = Parse.Object.extend('_User');
    const supplier = new Supplier();
    supplier.set('objectId', purchaseOrder.supplier.objectId);
    supplier.increment('balance', -(parseFloat(purchaseOrder.total) - parseFloat(purchaseOrder.totalPaid)));

    try {
      await purchaseOrderObject.save();
      await supplier.save();
      message.success('Compra confirmada correctamente');
      refresh();
    } catch (error) {
      console.error('Error while confirming PurchaseOrder: ', error);
      message.error('Error al confirmar la compra');
    }
  };
  

  return (
    <Card title="Resumen de orden de compra" style={{ marginBottom: 10, padding: '20px' }}>
  {/* Customer and Seller Info */}
  <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
    <Col span={8}>
      <Title level={5}>Proveedor</Title>
      <a href={`/suppliers/${purchaseOrder?.supplier?.uuid}`}>{purchaseOrder?.supplier?.fullName}</a>
    </Col>
   
   
  </Row>

  
  {/* Products Table */}
  <Title level={5} style={{ marginBottom: 10 }}>Productos</Title>
  <Table
    dataSource={purchaseOrder?.products}
    rowKey="objectId"
    pagination={false}
    scroll={{ x: true }}
    size="small"
    bordered
  >
    <Table.Column title="Producto" dataIndex="name" key="name" />
    <Table.Column title="Presentación" dataIndex={["presentation", "name"]} key="presentation" />
    <Table.Column title="Cantidad" dataIndex="quantity" key="quantity" />
    <Table.Column title="Contenido" dataIndex={"presentation"} key="content" render={(presentation, product) => `${product.quantity * presentation.quantity} ${product.basePresentation}`} />
    <Table.Column 
      title="Precio" 
      dataIndex="unitPrice" 
      key="unitPrice" 
      render={(price) => `$${price?.toLocaleString()}`} 
    />
    <Table.Column
      title="Total"
      key="total"
      dataIndex="total"
      render={(total) => `$${total?.toLocaleString()}`}
    />
  </Table>

  {purchaseOrder.products.some(p => p.adjustedPrice) && (
    <Flex
      style={{ marginTop: 20 }}
      vertical
    >
  <Title level={5} style={{ marginBottom: 10 }}>Ajuste realizado por {purchaseOrder?.closedBy?.fullName} el {moment(purchaseOrder?.closedAt).format('DD/MM/YYYY')}</Title>
  <Table
    dataSource={purchaseOrder?.products}
    rowKey="objectId"
    pagination={false}
    scroll={{ x: true }}
    size="small"
    bordered
  >
    <Table.Column title="Producto" dataIndex="name" key="name" />
    <Table.Column title="Presentación" dataIndex={["presentation", "name"]} key="presentation" />
    <Table.Column title="Cantidad" dataIndex="totalReceived" key="totalReceived" />
    <Table.Column title="Contenido" dataIndex={"receivedVolume"} key="content" render={(receivedVolume, product) => `${receivedVolume} ${product.basePresentation}`} />
    <Table.Column 
      title="Precio" 
      dataIndex="unitPrice" 
      key="unitPrice" 
      render={(price) => `$${price?.toLocaleString()}`} 
    />
    <Table.Column
      title="Total"
      key="adjustedPrice"
      dataIndex="adjustedPrice"
      render={(total) => `$${total?.toLocaleString()}`}
    />
  </Table>
    </Flex>
  )}

  {/* Totals */}
  <Row gutter={[16, 16]} style={{ marginTop: 20, textAlign: 'center' }}>
    <Col span={12}>
      <Title level={5}>Total</Title>
      <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>${purchaseOrder?.total?.toLocaleString()}</p>
    </Col>
    <Col span={12}>
      <Title level={5}>Pagado</Title>
      <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>${purchaseOrder?.totalPaid?.toLocaleString()}</p>
    </Col>
  </Row>

  {/* Action Buttons */}
  <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
    <Col span={12}>
      {allowConfirm() && (
        <Button type="primary" block onClick={confirmPurchaseOrder}>
          Confirmar compra
        </Button>
      )}
    </Col>
  </Row>
</Card>
  );
};

export default SummaryTab;
