import React from "react";
import { Card, Form, Row, Col, Input } from "antd";

const ClientAddress = ({ client }) => (
  <Card
    id="client-sales"
    className="mb-24 header-solid"
    bordered={false}
    title={<h5 className="mb-0">Domicilio</h5>}
  >
    <Form layout="vertical">
      <Row gutter={[30, 20]}>
        <Col span={24} lg={12}>
          <Form.Item label="Calle">
            <Input value={client?.address?.street} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Número exterior">
            <Input value={client?.address?.extNumber} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Número interior">
            <Input value={client?.address?.intNumber} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Colonia">
            <Input value={client?.address?.neighborhood} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Municipio">
            <Input value={client?.address?.city} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Estado">
            <Input value={client?.address?.state} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Código Postal">
            <Input value={client?.address?.zipCode} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Card>
);

export default ClientAddress;