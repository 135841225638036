import React, { useEffect, useState, useContext } from "react";
import { Card, Row, Table, Typography, Input, FloatButton, Tooltip, Flex, Tag } from "antd";
import { fetchClients } from "./utils/query";
import NewClient from "./components/NewClient";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const ClientsList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [newClientModal, setNewClientModal] = useState(false);
  const [filters, setFilters] = useState({});
  const { permissions } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getClients = async () => {
      try {
        const clients = await fetchClients(filters);
        setDataSource(clients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    getClients();
  }, [filters]);

  const allowAddClient = () =>
    permissions?.some((permission) => permission.group === "clients" && permission.action === "create");

  const renderBalance = (text) => (
    <Tag color={text < 0 ? "red" : "green"}>
      ${text?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
    </Tag>
  );

  const renderAddress = (address) => (
    <Tooltip
      title={
        address && (
          <>
            {address.street} {address.extNumber}
            {address.intNumber && `, ${address.intNumber}`}<br />
            {address.zipCode}<br />
            {address.neighborhood}<br />
            {address.city}<br />
            {address.state}
          </>
        )
      }
    >
      <p>{address?.state || "N/A"}</p>
    </Tooltip>
  );

  const columns = [
    // {
    //   dataIndex: "img",
    //   key: "img",
    //   render: (img) => (
    //     <img
    //       src={img || "https://via.placeholder.com/45"}
    //       alt="avatar"
    //       className="avatar-sm mr-10"
    //       style={{ width: "45px", borderRadius: "50%" }}
    //     />
    //   ),
    // },
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, client) => (
        <a onClick={() => navigate(`/clients/${client.uuid}`)}>
          <h3>{text}</h3>
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: "address",
      key: "address",
      render: (address) => renderAddress(address),
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Número de teléfono",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      render: (seller) => <p>{seller?.fullName || "N/A"}</p>,
    },
    {
      title: "Volumen de ventas",
      dataIndex: "sales",
      key: "sales",
      render: (sales) => <p>{sales?.length || 0}</p>,
    },
    {
      title: "Balance financiero",
      dataIndex: "balance",
      key: "balance",
      render: (text) => renderBalance(text),
    },
  ];

  const renderFilters = () => (
    <Flex justify="end" gap="10px">
      <Input
        placeholder="Nombre"
        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Correo electrónico"
        onChange={(e) => setFilters({ ...filters, email: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Número de teléfono"
        onChange={(e) => setFilters({ ...filters, phoneNumber: e.target.value })}
        style={{ width: 200 }}
      />
    </Flex>
  );

  return (
    <div className="layout-content">
      {allowAddClient() && (
        <FloatButton
          onClick={() => setNewClientModal(true)}
          tooltip="Nuevo cliente"
          style={{ right: 20, bottom: 20 }}
        />
      )}
      <NewClient open={newClientModal} setOpen={setNewClientModal} refresh={() => setFilters({})} />
      <Row>
        <Flex justify="space-between" align="center">
          <h2 className="p-15 h2">Clientes</h2>
          {renderFilters()}
        </Flex>
      </Row>
      <Row>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.uuid}
            pagination={true}
          />
        </Card>
      </Row>
    </div>
  );
};

export default ClientsList;