import React, { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Typography, Modal, Form, DatePicker, Select, message, Table, Checkbox} from "antd";
import Parse from "parse";
import moment from "moment";

const { Title } = Typography;


const AdjustPurchaseTotal = ({open, setOpen, purchaseOrder, refresh}) => {
    const [disableSave, setDisableSave] = useState(false);
    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);
    const [finishContract, setFinishContract] = useState(false);

    console.log("📦 Order: ", order)

    const updateOrder = async () => {

        const updateUserBalance = async () => {
            const User = Parse.Object.extend('_User');
            const user = new User();
            user.set('objectId', purchaseOrder?.supplier?.objectId);
            user.increment('balance', order.originalTotal - order.adjustedTotal);
            await user.save();
        }

        const Order = Parse.Object.extend('PurchaseOrder');
        const orderObject = new Order();
        orderObject.set('objectId', purchaseOrder.objectId);
        orderObject.set('closed', true);
        orderObject.set('closedBy', Parse.User.current());
        orderObject.set('closedAt', new Date());
        
        orderObject.set('total', order.adjustedTotal);
        orderObject.set('products', products?.map(product => ({ ...product, received: product.totalReceived, receivedVolume: product.receivedVolume })));
        await updateUserBalance();
        try {
            await orderObject.save();
            message.success("Orden actualizada correctamente");
            setOpen(false);
            refresh();
        }
        catch (error) {
            message.error(error.message);
        }
    }


    useEffect(() => {
        let totalReceived = {};
        purchaseOrder?.entries?.forEach(entry => {
            entry.products.forEach(product => {
                console.log("📦 Product: ", product)
                if (!totalReceived[product?.objectId]) totalReceived[product?.objectId] = 0;
                totalReceived[product.objectId] += product.purchaseUnitReceived;
            })
        })

        let receivedVolume = {};
        purchaseOrder?.entries?.forEach(entry => {
            entry.products.forEach(product => {
                console.log("📦 Product: ", product)    
                if (!receivedVolume[product?.objectId]) receivedVolume[product?.objectId] = 0;
                receivedVolume[product.objectId] += product.baseUnitReceived;
            })
        })

        let adjustedPrice = {};
        purchaseOrder?.products?.forEach(product => {
            console.log("📦 Product: ", product)
                console.log("📦 Product: ", product.price / product.presentation.quantity)
            
            adjustedPrice[product.objectId] = parseFloat(product.unitPrice) * receivedVolume[product.objectId];
        })


        
        let products = purchaseOrder?.products?.map(product => {
            return {
                objectId: product.objectId,
                presentationId: product.presentation.objectId,
                presentation: product.presentation,
                totalReceived: totalReceived[product.objectId] || 0,
                receivedVolume: receivedVolume[product.objectId] || 0,
                adjustedPrice: adjustedPrice[product.objectId] || 0,
                ...product
            }
        })

        let originalTotal = products.reduce((acc, curr) => acc + parseFloat(curr.total), 0);
        let adjustedTotal = products.reduce((acc, curr) => acc + parseFloat(curr.adjustedPrice), 0);

        console.log("📦 Products", products)
        setProducts(products);
        setOrder({ ...purchaseOrder, originalTotal, adjustedTotal });
    }, [purchaseOrder])

    if (!purchaseOrder) return null;

    const allowSave = () => {
        if (!purchaseOrder) return false;
        if (!order) return false;
        if (order?.length === 0) return false;
        if (products?.some(product => product?.pending - product?.added < 0)) return false;
        return true;
    }

    if (!purchaseOrder) return null;

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
        width="60%"
    >
        <Title level={4}>Ajustar total de compra</Title>
        <Table dataSource={products} columns={[
              {
                title: 'Producto',
                dataIndex: "name",
                key: 'name',
            },
            {
                title: 'Unidad',
                dataIndex: ["presentation", "name"],
                key: 'unit',
            },
           
            {
                title: 'Ordenado',
                dataIndex: 'pending',
                key: 'pending',
                render: (text, record) => <p>{record?.quantity}</p>
            },
            {
                title: 'Recibido',
                dataIndex: 'totalReceived',
                key: 'totalReceived',
                render: (totalReceived, record) => <p>{totalReceived}</p>
            },
            {
                title: 'Volumen total',
                dataIndex: "presentation",
                key: 'content',
                render: (presentation, product) => `${product.quantity * presentation.quantity} ${product.basePresentation}`
            },
            {
                title: 'Volumen recibido',
                dataIndex: 'receivedVolume',
                key: 'receivedVolume',
                render: (text, record) => <p>{text}</p>
            },
            {
                title: 'Precio inicial',
                dataIndex: 'total',
                key: 'total',
                render: (total, record) => <p>{total?.toLocaleString()}</p>
            },
            {
                title: 'Precio ajustado',
                dataIndex: 'adjustedPrice',
                key: 'adjustedPrice',
                render: (text, record) =>  <p>{text?.toLocaleString()}</p>
            },
        ]} />
        <Flex align="end" vertical style={{ marginTop: 20 }}>
            <Title level={4}>Total original: ${order.originalTotal?.toLocaleString()}</Title>
            <Title level={4}>Total ajustado: ${order.adjustedTotal?.toLocaleString()}</Title>
        </Flex>
        <Flex justify="end" gap={20}>
            <Button type="default" onClick={() => setOpen(false)}>Cancelar</Button>
            <Button type="primary" onClick={updateOrder} disabled={!allowSave()}>Guardar</Button>
        </Flex>
    </Modal>
}

export default AdjustPurchaseTotal;