import React from "react";
import { Card, Row, Col, Statistic } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

// TODO: Add the credit payments time

const renderBalance = (supplier) => {
    if (!supplier) return null;
    const balance = supplier?.balance?.toFixed(2);
    if (supplier.balance >= 0) return <Statistic value={`$${balance}`} valueStyle={{ color: "#3f8600" }} />
    if (supplier.balance < 0) return <Statistic value={`$${balance}`} valueStyle={{ color: "#cf1322" }} />
    };

const SupplierStatistics = ({ supplier }) => (
  <>
    <Card title="Balance Financiero" style={{ width: 300 }}>
        {renderBalance(supplier)}
    </Card>
    {/* <Card title="Estadísticas" style={{ width: 300 }}>
      <Row gutter={16}>         
        <Col span={12}>
          <Statistic
            title="Pago en tiempo"
            value={"-5 días"}
            valueStyle={{ color: "#cf1322" }}
            prefix={<ArrowDownOutlined />}
          />
        </Col>
      </Row>
    </Card> */}
  </>
);

export default SupplierStatistics;