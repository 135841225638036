import React, { useContext, useState } from 'react';
import { Table, Button, Flex, Typography, Tag, Space, message, notification } from 'antd';
import moment from 'moment';
import { UserContext } from '../../../../../context/UserContext';
import Parse from 'parse/lib/browser/Parse';
import { v4 as uuidv4 } from 'uuid';
import NewPayment from './modals/NewPayment';

const { Title } = Typography;


const renderStatus = (status, payment) => {
  if (status === 'Pendiente'){
      if (moment(payment?.dueDate?.iso).isBefore(moment())) return <Tag color="red">Vencido</Tag>;
      return <Tag color="orange">Pendiente</Tag>;
  }
  if (status === 'Pagado') return <Tag color="green">Pagado</Tag>;
  if (status === 'Cancelado') return <Tag color="purple">Cancelado</Tag>;
}


const PaymentsTab = ({ sale, refresh }) => {
  const [openPayment, setOpenPayment] = useState(false);
  const [payment, setPayment] = useState(null);
  const [schedulePayment, setSchedulePayment] = useState(false);
  const { permissions } = useContext(UserContext);


  const allowAddPayment = () => {
    if (!sale) return false;
    if (!sale.active) return false;
    if (permissions.some((p) => p.group === 'receivableAccounts' && p.action === 'addPayment')) return true;
    return false;
}


const discardPayment = async (payment) => {
  const Payment = Parse.Object.extend('Payment');
  const paymentObject = new Payment();

  paymentObject.set('objectId', payment.objectId);
  paymentObject.set('status', 'Cancelado');

  try {
      await paymentObject.save();
      message.success('Pago cancelado correctamente');
      refresh();
  }
  catch (error) {
      console.error('Error while cancelling Payment: ', error);
      message.error('Error al cancelar el pago');
  }
}

const printInvoice = async (payment) => {
  console.log('Address: ', sale.client.address);
  const params = {
      customerName: sale.client.fullName,
      customerAddress: sale.client.address,
      paymentDate: payment.paymentDate?.iso,
      saleId: sale.saleNumber,
      paymentId: payment.paymentNumber,
      dueDate: payment.dueDate?.iso,
      paymentMethod: payment.method,
      notes: payment.notes,
      type: 'receipt',
      useCardStyle: true,
      status: payment.status,
      paymentAmount: parseFloat(payment.amount),
  };

  try {
  const response = await fetch('http://localhost:3001/print/invoice', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
  });
  const data = await response.json();
  console.log("📦 Data: ", data)
  const pdfWindow = window.open();
  pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
  );
  }
  catch (error) {
      // Catch if pop-up is blocked
      console.error('Error opening PDF:', error);
      notification.error({
          message: 'Error',
          description: 'Please allow pop-ups for this website',
      });
  }
}

const paymentActions = (status, record, index) => {
  if (status === 'Pendiente' || status === 'Vencido') {
      return <Space>
          <Button onClick={() => {
              setOpenPayment(true);
              setPayment(record);
          }}>Registrar pago</Button>
          <Button onClick={() => printInvoice(record)}>Descargar recibo</Button>
          <Button onClick={() => discardPayment(record)}>Cancelar</Button>
      </Space>
  }
  else if (status === 'Pagado') {
      return <Button onClick={() => printInvoice(record)}>Descargar recibo</Button>
  } else if (status === 'Cancelado') {
      return <Tag color="red">Cancelado</Tag>
  }
  return <Button onClick={() => printInvoice(record)}>Descargar recibo</Button>
}

const generatePendingPayments = async () => {
  const Payment = Parse.Object.extend('Payment');
  const payments = [];
  const credit = sale.client.credit;

  const Index = Parse.Object.extend('Index');
  const query = new Parse.Query(Index);
  query.equalTo('name', 'Payment');
  query.equalTo('year', new Date().getFullYear());
  const index = await query.first();

  console.log(sale)
  credit.forEach((c, objectIndex) => {
      let paymentDate
      if (c.from === 'authorizationDate') {
          paymentDate = moment(sale.authorizationDate?.iso).add(c.days, 'days').toDate();
      }
      else if (c.from === 'deliveryDate') {
          paymentDate = moment(sale.deliveryDate?.iso).add(c.days, 'days').toDate();
      }

      let payment = {
          dueDate: paymentDate,
          amount: sale.total * (parseInt(c.value) / 100),
          credit: true,
          pending: true,
          sale: {__type: 'Pointer', className: 'Sales', objectId: sale.objectId},
          status: 'Pendiente',
          uuid: uuidv4(),
          paymentNumber: 'P' + (index.get('value') + 1 + objectIndex).toString().padStart(4, '0') + '-' + new Date().getFullYear(),
          client: {__type: 'Pointer', className: '_User', objectId: sale.client.objectId},
      }
      const paymentObject = new Payment(payment);
      payments.push(paymentObject);
  });
  console.log("📦 Payments: ", payments)
  index.increment('value', credit.length);

  try {
      const paymentResults = await Parse.Object.saveAll(payments);
      console.log("📦 Payment Results: ", paymentResults)
      await index.save();
      const Sale = Parse.Object.extend('Sales');
      const saleObject = new Sale();
      saleObject.set('objectId', sale.objectId);
      paymentResults.forEach(payment => {
            saleObject.addUnique('payments', payment);
        });
      await saleObject.save();

      message.success('Pagos generados correctamente');
      refresh();
  }
  catch (error) {
      console.error('Error while generating payments: ', error);
      message.error('Error al generar los pagos');
  }
}



  return (
    <Flex justify="end" vertical>
    <NewPayment open={openPayment} setOpen={setOpenPayment} object={payment} refresh={refresh} sale={sale} pendingPayment={payment} schedulePayment={schedulePayment} />
    <Table  
        dataSource={sale?.payments}
        rowKey="objectId"
        pagination={false}
        scroll={{ x: true }}
    >
         <Table.Column
            title="Folio"
            dataIndex="paymentNumber"
            key="paymentNumber"
            render={(paymentNumber) => paymentNumber}
        />
         <Table.Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, payment) => renderStatus(status, payment)}
        />
        <Table.Column
            title="Fecha de pago"
            dataIndex="paymentDate"
            key="paymentDate"
            render={(date) => date ? moment(date?.iso).format("DD/MM/YYYY") : 'Pendiente'}
        />
        <Table.Column
            title="Fecha de vencimiento"
            dataIndex="dueDate"
            key="dueDate"
            render={(date) => date ? moment(date?.iso).format("DD/MM/YYYY") : 'Sin fecha'}
        />
        <Table.Column
            title="Método"
            dataIndex="method"
            key="method"
            render={(method) => method ? method : 'Pendiente'}
        />
        <Table.Column
            title="Monto"
            dataIndex="amount"
            key="amount"
        />
        <Table.Column
            title="Crédito"
            dataIndex="credit"
            key="credit"
            render={(credit) => credit ? 'Sí' : 'No'}
        />
         <Table.Column
            title="Acciones"
            key="status"
            dataIndex="status"
            render={(status, record, index) => paymentActions(status, record, index)}
        />
    </Table>
    <Title level={4}>Saldo: ${(sale.total - sale.totalPaid).toLocaleString()}</Title>
    <Flex justify="end" style={{ marginBottom: 10 }} gap={10}>
    {allowAddPayment() && <Button type="primary" onClick={() => {setOpenPayment(true); setPayment(null); setSchedulePayment(false)}}>Agregar pago</Button>}
    {allowAddPayment() && <Button type="secondary" onClick={() => {setOpenPayment(true); setPayment(null); setSchedulePayment(true)}}>Programar pago</Button>}
    {allowAddPayment() && !sale.payments?.length && <Button type="secondary" onClick={() => generatePendingPayments()}>Generar pagos con base a crédito</Button>}
    </Flex>
</Flex>
  );
};

export default PaymentsTab;
