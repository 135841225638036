import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Avatar, Switch, Card, Statistic, Table, Tabs, FloatButton } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import ReactApexChart from "react-apexcharts";
import { fetchProduct, fetchProductSales, updateProductStatus } from '../services/index.js';
import NewProduct  from '../modals/NewProduct';
import { useParams } from "react-router-dom";
import Parse from 'parse';

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#1890FF", "#B37FEB"],
    width: 3,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: 0,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
    },
    categories: [
      "Ene",
      "Feb",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

const InventoryDetails = () => {
  const { uuid } = useParams();
  const [productSales, setProductSales] = useState([]);
  const [product, setProduct] = useState([]);
  const [productSalesFiltered, setProductSalesFiltered] = useState([]);
  const [currentPresentation, setCurrentPresentation] = useState('General');
  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [baseCost, setBaseCost] = useState('N/A');
  const [editProductModal, setEditProductModal] = useState(false);

  const getProductData = async () => {
    setLoading(true);
    const productSales = await fetchProductSales(uuid);
    const productFound = await fetchProduct(uuid);
    setProduct(productFound[0]);
    setProductSales(productSales);
    setProductSalesFiltered(productSales);
    setLoading(false);
  };

  const refresh = async () => {
    getProductData();
    setEditProductModal(false);
  };

  useEffect(() => {
    

    getProductData();
  }, [uuid]);

  useEffect(() => {
    const filterProductSales = async () => {
      setLoading(true);
      const productSalesFiltered = (currentPresentation === 'General')
        ? productSales
        : productSales.filter((sale) =>
          sale.products.some((product) =>
            product.presentation?.name === currentPresentation
          )
        );
      setProductSalesFiltered(productSalesFiltered);
      if (currentPresentation === 'General') {
        setBaseCost(product.baseCost);
        setMinPrice(product.minPrice);
        setStock(product.stock);
      } else {
        const selectedPresentation = product.presentations?.find(
          (presentation) => presentation.name === currentPresentation
        );

        setBaseCost(selectedPresentation?.baseCost || 0);
        setMinPrice(selectedPresentation?.minPrice || 0);
        setStock(selectedPresentation?.stock || 0);
      }
      setLoading(false);
    };

    filterProductSales();
  }, [currentPresentation, product, productSales]);

  const items = [
    {
      key: "General",
      label: `General`,
      children: <h1 />
    },
    ...(Array.isArray(product?.presentations)
      ? product.presentations?.map((presentation, index) => ({
        key: presentation.name,
        label: presentation.name,
        children: <h1 />
      }))
      : [{
        key: "2",
        label: `Error`,
        children: <h1 />
      }]),
  ];

  const columns = [
    {
      title: "Orden de compra",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Presentacion",
      dataIndex: "products",
      key: "presentation",
      render: (products, record) => {
        const matchingProducts = products.filter(product => product.uuid === uuid);
        const presentations = matchingProducts.map(product => product.presentation?.name).join(", ");
        return presentations || "Error buscando las presentaciones";
      },
    },
    {
      title: "Cantidad",
      dataIndex: "products",
      key: "quantity",
      render: (products, record) => {
        const matchingProducts = products.filter(product => product.uuid === uuid);
        const quantity = matchingProducts.map(product => product?.quantity).join(", ");
        return quantity || "Error buscando las cantidades";
      },
    },
    {
      title: "Precio",
      dataIndex: "total",
      key: "price",
      render: (text) => <h3>{text}</h3>,
    },
  ];

  const series = [
    {
      name: "Ventas en pesos",
      data: (productSalesFiltered != null) ? productSalesFiltered.map(sale => sale.total) : [],
      offsetY: 0,
    },
    {
      name: "Producción en Kilos",
      data: productSalesFiltered,
      offsetY: 0,
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={8} md={12} lg={12}>
        <Card
          id="profile"
          className="card-profile-head"
          bordered={false}
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  {/* TODO: Link avatar image to product image from server */}
                  <Avatar size={74} shape="square" src="https://http2.mlstatic.com/D_NQ_NP_970716-MLM76966899128_062024-O.webp" />
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{product.name}</h4>                    
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
              </Col>
            </Row>
          }
        ></Card>
      </Col>
      

      <Col span={24}>
        <Tabs defaultActiveKey="General" onChange={(value) => setCurrentPresentation(value)} >
          {items.map((item) => (
            <Tabs.TabPane tab={item.label} key={item.key}>
              {/* {uuid && <item.children presentation={product.presentations} />} */}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <Row gutter={[24, 24]}>
        <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Existencia" style={{ width: "100%" }}>
            <Statistic
              title={currentPresentation === 'General' ? "Kgs" : "Unidades"}
              value={stock}
              valueStyle={{ color: '#a7c957', fontSize: 18 }} />
        </Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Costo" style={{ width: "100%" }}>
            <Statistic
              title="Costo"
              value={"$ " + baseCost}
              valueStyle={{ fontSize: 18 }} />
        </Card>
      </Col>
      {currentPresentation !== 'General' ? <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Precio Mínimo" style={{ width: "100%" }}>
            <Statistic
              title="Precio Mínimo"
              value={"$ " + minPrice}
              valueStyle={{ fontSize: 18 }} />
        </Card>
      </Col> : <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Valor de almacén" style={{ width: "100%" }}>
            <Statistic
              title="Valor de almacén"
              value={"$ " + minPrice}
              valueStyle={{ fontSize: 18 }} />
        </Card>
      </Col>}
      </Row>
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Relación de producción y ventas</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li><MinusOutlined />Producción</li>
                  <li><MinusOutlined />Ventas</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={options}
              series={series}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Ventas</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={productSalesFiltered}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
      <NewProduct open={editProductModal} setOpen={setEditProductModal} product={product} refresh={refresh} />
      <FloatButton onClick={() => setEditProductModal(true)} />
    </Row>
  );
};

export default InventoryDetails;
