export const calculatePending = (ingredients, production) => {
    if (!ingredients || !production) {
        console.warn("Invalid input: ingredients or production is missing.");
        return [];
    }

    const { productionEvents, recipe, productionQuantity } = production;
    const recipeFinalWeight = recipe?.finalWeight;

    if (!recipeFinalWeight || !productionQuantity) {
        console.warn("Recipe final weight or production quantity is missing.");
        return ingredients.map((ingredient) => ({
            ...ingredient,
            pending: ingredient.quantity,
            quantity: ingredient.quantity,
        }));
    }

    const calculateBaseQuantity = (ingredient) =>
        (ingredient.quantity / recipeFinalWeight) * productionQuantity;

    // If no production events, calculate pending amounts based solely on recipe
    if (!productionEvents || productionEvents.length === 0) {
        return ingredients.map((ingredient) => ({
            ...ingredient,
            pending: calculateBaseQuantity(ingredient),
            quantity: calculateBaseQuantity(ingredient),
        }));
    }

    // Calculate used quantities from production events and derive pending amounts
    return ingredients.map((ingredient) => {
        const usedQuantity = productionEvents
            .filter((event) => event.warehouse === "Forrajes")
            .reduce((acc, event) => {
                const eventIngredient = event.ingredients?.find((i) => i.objectId === ingredient.objectId);
                return acc + (eventIngredient?.quantity || 0);
            }, 0);

        const baseQuantity = calculateBaseQuantity(ingredient);
        return {
            ...ingredient,
            pending: baseQuantity - usedQuantity,
            quantity: baseQuantity,
        };
    });
};

export const allowSave = (ingredients) => {
    if (!ingredients || !Array.isArray(ingredients)) {
        console.warn("Invalid input: ingredients must be an array.");
        return false;
    }

    if (ingredients.every((ingredient) => ingredient.pending <= 0)) {
        return false;
    }

    return ingredients.every((ingredient) => {
        const { adding = 0, pending = 0 } = ingredient;
        return adding <= pending && adding >= 0;
    });
};