import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col } from "antd";
import { saveEvent, setHopperContent } from "./services";
import SetHopperModal from "./SetHopperModal";

const ForageAdding = ({ production, refresh }) => {
    const [hopper, setHopper] = useState(null);
    const [showSetHopper, setShowSetHopper] = useState(false);
    const [weights, setWeights] = useState([]);

    useEffect(() => {
        if (production) {
            setHopper(production.waitingHopper);

            const mixingHopperWeightEvent = production?.productionEvents?.find((event) => event.event === "weightMixingHopper");
            const mixingHopperWeight = mixingHopperWeightEvent ? mixingHopperWeightEvent.weights.totalWeight : 0;
            const waitingHopperWeightEvent = production?.productionEvents?.find((event) => event.event === "weightWaitingHopper");
            if (waitingHopperWeightEvent) {
                setWeights(waitingHopperWeightEvent.weights);
                return;
            }
            setWeights({ mixingHopperWeight, waitingHopperWeight: 0 });
        }
    }, [production]);

    const update = () => {
        setShowSetHopper(false);
        refresh();
    }

   
    const handleSave = async () => {
        await setHopperContent(production, weights, hopper);
        await saveEvent(production, weights);
        refresh();
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card title={hopper ? hopper.name : "Sin tolva asignada"}>
                    {!hopper && (
                        <Button type="primary" onClick={() => setShowSetHopper(true)}>
                            Asignar tolva
                        </Button>
                    )}
                </Card>
            </Col>
            <Col span={8}>
                <Card title="Peso en tolva de mezcla">{weights?.mixingHopperWeight} kg</Card>
            </Col>
            <Col span={8}>
            {weights?.fromEvent ? (
                <Card title="Peso en tolva de espera">{weights?.waitingHopperWeight} kg</Card>
            ) : (
                <Card title="Peso en tolva de espera">
                    <Input
                    style={{ width: "80%" }}
                    placeholder="Cantidad"
                    type="number"
                    onChange={(e) => setWeights({ ...weights, waitingHopperWeight: parseFloat(e.target.value) })}
                />
            </Card>
            )}
            </Col>
            <Col span={24}>
                <Button
                type="primary"
                onClick={handleSave}
                style={{ marginTop: "20px", alignSelf: "flex-end" }}
                disabled={!weights.waitingHopperWeight || weights.fromEvent}
            >
                Guardar
            </Button>
            </Col>
            <SetHopperModal
                open={showSetHopper}
                setOpen={update}
                production={production}
            />
        </Row>
    );
};

export default ForageAdding;