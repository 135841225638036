import React, { useEffect } from "react";
import {
  Tabs
} from "antd";
import { SupplierInfo, SupplierPurchases, SupplierPayments } from "./Tabs";
import { Card, Statistic, Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import Parse from "parse";
import Title from "antd/es/typography/Title";

const items = [
  {
    key: "1",
    label: `Información`,
    children: SupplierInfo
  },
  {
    key: "2",
    label: `Compras`,
    children: SupplierPurchases
  },
  {
    key: "3",
    label: `Pagos`,
    children: SupplierPayments
}
]


const fetchPurchases = async (supplier) => {
  try {
    const Purchase = Parse.Object.extend("PurchaseOrder");
    const query = new Parse.Query(Purchase);
    query.equalTo("supplier", supplier);
    query.include("supplier");
    query.descending("createdAt");

    const results = await query.find();
    return results.map((result) => result.toJSON());
  } catch (error) {
    console.error("Error fetching purchases:", error);
    throw new Error("Unable to fetch purchases.");
  }
};

const fetchSupplier = async (uuid) => {
  try {
    // Fetch the "Proveedor" role
    const Role = Parse.Object.extend("_Role");
    const roleQuery = new Parse.Query(Role);
    roleQuery.equalTo("name", "Proveedor");
    const role = await roleQuery.first();

    if (!role) {
      throw new Error("Role 'Proveedor' not found.");
    }

    // Query users related to the "Proveedor" role
    const relation = role.relation("users");
    const supplierQuery = relation.query();
    supplierQuery.include([
      "address",
      "fiscalInformation",
      "fiscalInformation.address",
      "payments",
      "purchases",
      "outpayments",
    ]);
    supplierQuery.equalTo("uuid", uuid);

    const supplierObject = await supplierQuery.first();

    if (!supplierObject) {
      throw new Error("Supplier not found.");
    }

    // Fetch associated purchases
    const purchases = await fetchPurchases(supplierObject);

    // Combine supplier data with purchases
    const supplier = { ...supplierObject.toJSON(), purchases };
    console.log("Supplier:", supplier);

    return supplier;
  } catch (error) {
    console.error("Error fetching supplier:", error);
    throw new Error("Unable to fetch supplier.");
  }
};

const SupplierDetails = () => {
  const [supplier, setSupplier] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { uuid } = useParams();

  useEffect(() => {
    const getSupplier = async () => {
      setLoading(true);
      const supplier = await fetchSupplier(uuid);
      setSupplier(supplier);
      setLoading(false);
    };

    getSupplier();
  }, [uuid]);

  const getSupplier = () => {
    fetchSupplier(uuid).then((supplier) => {
      setSupplier(supplier);
    });
  }
  
  return (
    <div className="layout-content">
      <Card title={<Title level={2}>{supplier?.fullName}</Title>}>
       {supplier?.supplierType === 'physical' ? <p>Persona física</p> : <p>Persona moral</p>}
      </Card>
      <Tabs defaultActiveKey="1">
        {items.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {supplier && <item.children supplier={supplier} refresh={getSupplier} />}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default SupplierDetails;