import { React, useState } from 'react';
import { Button, Modal, Input } from 'antd';

const ConfirmProductionModal = ({ open, setOpen, production, onConfirm, weights }) => {
    const [confirming, setConfirming] = useState(false);
    const [password, setPassword] = useState('');

    const handleConfirm = async () => {
        setConfirming(true);
        await onConfirm(production, password);
        setConfirming(false);
    };

    return (
        <Modal
            title="Confirmar producción"
            visible={open}
            onOk={handleConfirm}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" loading={confirming} onClick={handleConfirm}>
                    Confirmar
                </Button>,
            ]}
        >
            <p>Confirmando la producción de:</p>
            <p>{production.product.name}</p>
            <p>Con un peso total de:</p>
            <p>{weights.waitingHopperWeight + weights.ingredientsWeight} kg</p>
            
        </Modal>
    );
}

export default ConfirmProductionModal;
