import Parse from "parse";

// Fetch Products
export const fetchProducts = async () => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.include(["recipe", "presentations"]);
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

// Fetch Product by UUID
export const fetchProduct = async (uuid) => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.include(["presentations", "recipe"]);
  query.equalTo("uuid", uuid);
  const results = await query.find();
  return results.map((result) => ({
    ...result.toJSON(),
    value: result.get("name"),
    label: result.get("name"),
    key: result.id,
  }));
};

// Fetch Product Sales by UUID
export const fetchProductSales = async (uuid) => {
  const Sales = Parse.Object.extend("Sales");
  const query = new Parse.Query(Sales);
  query.contains("products.uuid", uuid);
  query.include(["products", "products.presentation"]);
  const results = await query.find();
  return results.map((result) => ({
    ...result.toJSON(),
    value: result.get("name"),
    label: result.get("name"),
    key: result.id,
  }));
};

// Fetch Inventory
export const fetchInventory = async () => {
  const Inventory = Parse.Object.extend("Ingredient");
  const query = new Parse.Query(Inventory);
  const results = await query.find();
  return results.map((entry) => ({
    value: entry.id,
    ...entry.toJSON(),
  }));
};

// Fetch Ingredients
export const fetchIngredients = async () => {
  const Ingredients = Parse.Object.extend("Ingredient");
  const query = new Parse.Query(Ingredients);
  query.limit(10);
  const results = await query.find();
  return results.map((result) => ({
    key: result.id,
    label: result.get("name"),
    value: result.id,
    ...result.toJSON(),
  }));
};

// Fetch Packaging
export const fetchPackaging = async (value) => {
  const Ingredients = Parse.Object.extend("Ingredient");
  const query = new Parse.Query(Ingredients);
  query.equalTo("warehouse", "Envasado");
  if (value) query.matches("name", value, "i");
  query.limit(10);
  const results = await query.find();
  return results.map((result) => ({
    key: result.id,
    label: result.get("name"),
    value: result.id,
    ...result.toJSON(),
  }));
};