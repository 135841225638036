import React, { useEffect, useState, useContext } from "react";
import { Card, Row, Table, Typography, Input, FloatButton, Tooltip, Space, Flex } from "antd";
import { fetchSuppliers } from "./utils/query";
import NewSupplier from "./components/NewSupplier";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const SuppliersList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [newSupplierModal, setNewSupplierModal] = useState(false);
  const [filters, setFilters] = useState({});
  const { permissions } = useContext(UserContext);
  const navigate = useNavigate();

  const fetchAndSetSuppliers = async () => {
    try {
      const suppliers = await fetchSuppliers(filters);
      setDataSource(suppliers);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  useEffect(() => {
    fetchAndSetSuppliers();
  }, [filters]);

  const refresh = async () => {
    await fetchAndSetSuppliers();
  };

  const allowAddSupplier = () =>
    permissions.some((permission) => permission.group === "suppliers" && permission.action === "create");

  const renderAddress = (address) => (
    <Tooltip
      title={
        address && (
          <Space direction="vertical">
            {address.street} {address.extNumber}
            {address.intNumber && `, ${address.intNumber}`}
            <br />
            {address.zipCode}
            <br />
            {address.neighborhood}
            <br />
            {address.city}
            <br />
            {address.state}
          </Space>
        )
      }
    >
      <p>{address?.state || "Sin estado"}</p>
    </Tooltip>
  );

  const columns = [
    // {
    //   dataIndex: "img",
    //   key: "img",
    //   render: (img) => (
    //     <img src={img || "/default-avatar.png"} alt="supplier" className="avatar-sm mr-10" style={{ width: "45px" }} />
    //   ),
    // },
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, supplier) => (
        <a onClick={() => navigate(`/suppliers/${supplier.uuid}`)} style={{ color: "#1890ff" }}>
          <h3>{text}</h3>
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: "address",
      key: "address",
      render: renderAddress,
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      render: (email) => <p>{email || "Sin correo"}</p>,
    },
    {
      title: "Número de teléfono",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phone) => <p>{phone || "Sin teléfono"}</p>,
    },
    {
      title: "Volumen de compra",
      dataIndex: "purchases",
      key: "purchases",
      render: (purchases) => <p>{purchases?.length || 0}</p>,
    },
    {
      title: "Balance financiero",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => <p>${parseFloat(balance || 0).toFixed(2)}</p>,
    },
  ];

  const renderFilters = () => (
    <Flex justify="end" gap="10px">
      <Input
        placeholder="Nombre"
        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Correo electrónico"
        onChange={(e) => setFilters({ ...filters, email: e.target.value })}
        style={{ width: 200 }}
      />
      <Input
        placeholder="Número de teléfono"
        onChange={(e) => setFilters({ ...filters, phoneNumber: e.target.value })}
        style={{ width: 200 }}
      />
    </Flex>
  );

  return (
    <div className="layout-content">
      {allowAddSupplier() && <FloatButton onClick={() => setNewSupplierModal(true)} />}
      <NewSupplier open={newSupplierModal} setOpen={setNewSupplierModal} refresh={refresh} />
      <Row>
        <Flex justify="space-between" align="center" style={{ marginBottom: 20 }}>
          <Title level={2} className="p-15">
            Proveedores
          </Title>
          {renderFilters()}
        </Flex>
      </Row>
      <Row>
        <Card bordered={false} bodyStyle={{ padding: "0px" }} className="criclebox cardbody mb-24 header-solid">
          <div className="ant-list-box table-responsive">
            <Table
              dataSource={dataSource}
              columns={columns}
              rowKey={(record) => record.uuid}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default SuppliersList;