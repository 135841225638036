import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, FloatButton, Button, Progress, Input, DatePicker, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import NewPackagingOrder from "./components/NewPackagingOrder";
import Parse from "parse";
import moment from "moment";

const fetchProducts = async (name) => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.matches("name", name, "i");
  const results = await query.find();
  return results;
};

const fetchPresentations = async (name) => {
  const Presentation = Parse.Object.extend("Presentation");
  const query = new Parse.Query(Presentation);
  query.matches("name", name, "i");
  const results = await query.find();
  return results;
};

// Fetch packaging orders without presentations
const fetchPackagingOrders = async (filters) => {
  const PackagingOrder = Parse.Object.extend("PackagingOrder");
  const query = new Parse.Query(PackagingOrder);
  query.include(["recipePointer", "ingredientPointers", "product"]);
  if (filters) {  
    if (filters.folio) query.matches("folio", filters.folio, "i");
    if (filters.product) {
      const products = await fetchProducts(filters.product);
      query.containedIn("product", products);
    }
    if (filters.presentation) {
      const presentations = await fetchPresentations(filters.presentation);
      query.containedIn("presentationPointers", presentations);
    }
    if (filters.date) {
      const start = moment(filters.date[0].$d).startOf("day").toDate();
      const end = moment(filters.date[1].$d).endOf("day").toDate();
      query.greaterThanOrEqualTo("createdAt", start);
      query.lessThanOrEqualTo("createdAt", end);
    }
  }
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

const columns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
  },
  {
    title: "Folio",
    dataIndex: "folio",
    key: "folio",
    render: (text, order) => (
      <Button
        type="link"
        onClick={() =>
          (window.location.href = `/packaging/detail/${order.uuid}`)
        }
      >
        {text}
      </Button>
    ),
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",
    render: (product) => <h3>{product?.name}</h3>,
  },
  {
    title: 'Volumen',
    dataIndex: 'totalVolume',
    key: 'totalVolume',
    render: (totalVolume) => <h3>{totalVolume} kg</h3>,
  },
  {
    title: "Unidades",
    dataIndex: "packagingQuantity",
    key: "packagingQuantity",
    render: (packagingQuantity, order) => (
      <h3>
        {packagingQuantity} pzs
      </h3>
    ),
  },
  {
    title: "Presentaciones",
    dataIndex: "presentations",
    key: "presentations",
    render: (presentations) => (
      <h3>{presentations?.map((p) => p.name).join(", ")}</h3>
    ),
  },
  {
    title: "Progreso",
    dataIndex: "progress",
    key: "progress",
    render: (progress, order) => (
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <div>
          Envasado: {order.packaged}/{order.packagingQuantity}
        </div>
        <div>
          <progress
            value={order.packaged}
            max={order.packagingQuantity}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    ),
  },
];

const InventoryDetails = () => {
  const [newPackaging, setNewPackaging] = useState(false);
  const [packagingOrders, setPackagingOrders] = useState([]);
  const [filters, setFilters] = useState({});
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    fetchPackagingOrders(filters).then((data) => {
      setPackagingOrders(data);
    });
  }, [shouldUpdate, filters]);

  const refresh = () => {
    setShouldUpdate(!shouldUpdate);
  };

  const renderFilters = () => {
    return (
      <Flex gap="10px" alignItems="center" style={{ marginBottom: 20 }}>
        <Input 
          placeholder="Folio"
          onChange={(e) => setFilters({ ...filters, folio: e.target.value })}
        />
        <Input
          placeholder="Producto"
          onChange={(e) => setFilters({ ...filters, product: e.target.value })}
        />
        <Input
          placeholder="Presentación"
          onChange={(e) => setFilters({ ...filters, presentation: e.target.value })}
        />
        <DatePicker.RangePicker
          style={{ width: 500, height: 40 }}
          onChange={(value) => setFilters({ ...filters, date: value })}
        />
      </Flex>
    );
  }

  return (
    <Row gutter={[24, 24]}>
      <FloatButton onClick={() => setNewPackaging(true)} />
      <NewPackagingOrder
        open={newPackaging}
        setOpen={setNewPackaging}
        refresh={refresh}
      />
      <Col span={24}>
        <Flex justifyContent="center" alignItems="center">
          <h2 className="p-15 h2">Órdenes de envasado</h2>
          {renderFilters()}
        </Flex>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: "100%" }}
              dataSource={packagingOrders}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default InventoryDetails;