import React, { useEffect, useState, useContext } from "react";
import { Typography, Button, notification, message, Modal, Tag, Flex, Steps } from "antd";
import Parse from "parse/lib/browser/Parse";
import { Printer, Envelope, Whatsapp, Pencil } from "react-bootstrap-icons";
import { UserContext } from "../../../../context/UserContext";
import moment from "moment";

import NewPurchaseOrder from "./NewPurchaseOrder";
import TabsContainer from "./PurchaseOrdersTabs/TabsContainer";

const { Title } = Typography;

const PurchaseOrderDetail = ({ open, setOpen, purchaseOrder, refresh, shouldRefresh, setPurchaseOrder }) => {
    const [tab, setTab] = useState("summary");
    const [renderId, setRenderId] = useState(0);
    const [printing, setPrinting] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [editing, setEditing] = useState(false);
    const [modalRefresh, setModalRefresh] = useState(false);
    const { permissions } = useContext(UserContext);

    const allowEdit = () => {
        if (!purchaseOrder) return false;
        if (purchaseOrders?.some((s) => s.status === 'Orden de compra')) return false;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyAll')) return true;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyOwn')) {
            if (purchaseOrder.seller.objectId === Parse.User.current().id) return true;
        }
        return false;
    }


    const fetchAllVersions = async () => {
        if (!purchaseOrder) return;
        const PurchaseOrder = Parse.Object.extend('PurchaseOrder');
        const query = new Parse.Query(PurchaseOrder);
        query.equalTo('purchaseOrderNumber', purchaseOrder.purchaseOrderNumber);
        query.ascending('createdAt');
        query.include(['supplier', 'payments', 'entries', 'comments', 'supplier.address']);
        const results = await query.find();
        console.log("📦 Results: ", results);
        setPurchaseOrders(results?.map((result) => result.toJSON()));
    }

    useEffect(() => {
       setRenderId(Math.random());
    }, [shouldRefresh]);

    useEffect(() => {
        fetchAllVersions();
        refresh();
    }, [purchaseOrder, modalRefresh]);

  



    const print = async () => {
        const numberOfProducts = purchaseOrder.products.reduce((acc, product) => acc + product.quantity, 0);

        setPrinting(true);
        const params = {
            customerName: purchaseOrder.client.fullName,
            deliveryAddress: purchaseOrder.deliveryAddress?.address,
            invoiceId: purchaseOrder.status === 'Orden de compra' ? purchaseOrder.purchaseOrderNumber + '-' + purchaseOrder.version : purchaseOrder.quoteNumber + '-' + purchaseOrder.version,
            invoiceDate: purchaseOrder.createdAt,
            items: purchaseOrder.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  purchaseOrder.deliveryPrice ? parseFloat(product.price) + (purchaseOrder.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(purchaseOrder.total),
            deliveryDate: purchaseOrder.deliveryDate.iso,
            deliveryPlace: purchaseOrder?.deliveryPlace,
            type: purchaseOrder.status === 'Orden de compra' ? 'invoice' : 'quote',
            useCardStyle: true,
        };

        try {
        const response = await fetch('http://localhost:3001/print/serviceOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const data = await response.json();
       
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        }
        catch (error) {
            // Catch if pop-up is blocked
            console.error('Error opening PDF:', error);
            notification.error({
                message: 'Error',
                description: 'Please allow pop-ups for this website',
            });
        }
        finally {
            setPrinting(false);
        }
    }

    const sendEmail = async () => {
        setSendingEmail(true);
        const numberOfProducts = purchaseOrder.products.reduce((acc, product) => acc + product.quantity, 0);

        const info = {
            customerName: purchaseOrder.client.fullName,
            deliveryAddress: purchaseOrder.deliveryAddress?.address,
            invoiceId: purchaseOrder.purchaseOrderNumber + '-' + purchaseOrder.version || purchaseOrder.quoteNumber + '-' + purchaseOrder.version,
            invoiceDate: purchaseOrder.createdAt,
            items: purchaseOrder.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  purchaseOrder.deliveryPrice ? parseFloat(product.price) + (purchaseOrder.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(purchaseOrder.total),
            deliveryDate: purchaseOrder.deliveryDate.iso,
            deliveryPlace: purchaseOrder?.deliveryPlace,
            useCardStyle: true,
        };

        const params = {
            to: purchaseOrder.client.email,
            subject: 'Cotización Dasner ' + (purchaseOrder.quoteNumber),
            text: 'Por favor, encuentre la cotización adjunta.',
            info,
            filename: 'Cotización Dasner ' + (purchaseOrder.quoteNumber) + '.pdf',
            url: 'https://app.dasnerpetcare.com/purchaseOrders/AcceptQuote/' + purchaseOrder.uuid,
            type: 'quote',
        };

        try {
        const response = await fetch('http://localhost:3002/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const data = await response.json();
        console.log("📧 Data: ", data);
        message.success('Correo enviado correctamente');
        }
        catch (error) {
            console.error('Error sending email:', error);
            notification.error({
                message: 'Error',
                description: 'Error al enviar el correo',
            });
        }
        finally {
            setSendingEmail(false);
        }
    }




    const OwnButton = ({ icon, children, ...props }) => {
        const [showLabel, setShowLabel] = useState(false);
        return <Button {...props} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>{icon}{showLabel && children}</Button>
    }

    const buttons = () => 
        <Flex justify="end" gap={10} style={{ marginBottom: 10 }}>
            {purchaseOrder?.active && <OwnButton icon={<Printer />} onClick={print} loading={printing}>Imprimir</OwnButton>}
            {purchaseOrder?.active && <OwnButton icon={<Envelope />} onClick={() => sendEmail()} loading={sendingEmail}>Enviar por correo</OwnButton>}
            {purchaseOrder?.active && <OwnButton icon={<Whatsapp />} onClick={() => console.log("📲 Enviar por WhatsApp")}>Enviar por WhatsApp</OwnButton>}
            {allowEdit() && <OwnButton icon={<Pencil />} onClick={() => setEditing(true)}>Editar</OwnButton>}
        </Flex>

if (!purchaseOrder) return null;

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(null)}
            footer={null}
            closable={false}
            style={{ top: 20, width: "90%", height: "90%" }}
            width="90%"
            height="90%"
        >
            {/* <NewPurchaseOrder open={editing} setOpen={setEditing} purchaseOrder={purchaseOrder} refresh={refresh} editing refreshPurchaseOrder={fetchAllVersions} versionNumbner={purchaseOrders.length} /> */}
            <Flex justify="between">
                <Flex justify="space-between" align="flex-start" vertical style={{ width: "100%" }}>
                    <Tag color="blue">{purchaseOrder?.status}</Tag>
                    <Title level={4}>Detalle de la orden de compra</Title>
                </Flex>
                {buttons()}
            </Flex>
            <Flex justify="between" style={{ marginBottom: 10 }}>
               
            <Flex justify="between" vertical style={{ marginBottom: 10, width: "80%" }}>
            <TabsContainer tab={tab} setTab={setTab} purchaseOrder={purchaseOrder} refresh={setModalRefresh} />
           
            </Flex>
            <Flex justify="flex-start" style={{ marginTop: 10, marginLeft: 10 }} align="start" vertical>
                <Title level={5}>Versión</Title>
                <Steps current={purchaseOrders.findIndex((s) => s.objectId === purchaseOrder.objectId)} direction="vertical" style={{ width: "100%" }}>
                    {purchaseOrders.map((s) => (
                        <Steps.Step title={moment(s.createdAt).format("DD/MM/YYYY HH:mm")} description={s.modificationComment} onClick={() => setPurchaseOrder(s)} />
                    ))}
                </Steps>
                </Flex>
            </Flex>
        </Modal>
    );
}

export default PurchaseOrderDetail;
               