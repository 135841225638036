import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Form, Input, message } from "antd";
import PresentationList from "../components/PresentationList";
import RecipeList from "../components/RecipeList";
import Parse from "parse";
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;

const NewProduct = ({ open, setOpen, refresh, product = null }) => {
    const [form] = Form.useForm();
    const [recipe, setRecipe] = useState(null);
    const [presentations, setPresentations] = useState([]);
    const [renderId, setRenderId] = useState(0);

    useEffect(() => {
        if (open) {
            if (product) {
                // Populate form and state with existing product data
                form.setFieldsValue({
                    name: product.name,
                    minPrice: product.minPrice,
                });
                setPresentations(product.presentations || []);
                setRecipe(product.recipe || null);
            } else {
                // Reset form and state for new product
                form.resetFields();
                setPresentations([]);
                setRecipe(null);
            }
        }
    }, [open, product]);

    const mapPresentationsForSaving = (presentations) => {
        const Presentation = Parse.Object.extend("Presentation");
        return presentations.map((presentation) => {
            const presentationObject = new Presentation();
            if (presentation.objectId) presentationObject.set("objectId", presentation.objectId);

            console.log(presentation.packaging);

            const packaging = presentation.packaging?.map((pack) => pack.label ? ({
                    ...pack,
                    baseCost: parseFloat(pack.baseCost.toFixed(2)),
                    quantity: parseFloat(pack.quantity),
                    total: parseFloat((pack.baseCost * pack.quantity).toFixed(2)),
                }) : null).filter((pack) => pack !== null) || [];

            presentationObject.set("name", presentation.name);
            presentationObject.set("content", presentation.content);
            presentationObject.set("packaging", packaging);
            presentationObject.set("baseCost", presentation.baseCost);
            presentationObject.set("totalCost", presentation.totalCost);
            presentationObject.set("minPrice", parseFloat(presentation.minPrice.toFixed(2)));
            presentationObject.set("uuid", presentation?.uuid ? presentation.uuid : uuidv4());
            return presentationObject;
        });
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            if (!recipe) {
                message.error("Por favor seleccione una receta.");
                return;
            }

            const { name, minPrice } = values;
            const presentationsToSave = mapPresentationsForSaving(presentations);
            const savedPresentations = await Parse.Object.saveAll(presentationsToSave);

            const Product = Parse.Object.extend("Product");
            const productToSave = product ? await new Parse.Query(Product).get(product.objectId) : new Product();

            productToSave.set("name", name);
            productToSave.set("presentations", savedPresentations);
            productToSave.set("recipe", { __type: "Pointer", className: "Recipe", objectId: recipe.objectId });
            productToSave.set("baseCost", recipe.baseCost);
            productToSave.set("minPrice", parseFloat(minPrice));
            productToSave.set("uuid", product?.uuid || uuidv4());

            await productToSave.save();

            if (!product) {
                // Update the recipe with product reference only for new products
                const Recipe = Parse.Object.extend("Recipe");
                const recipeObject = new Recipe();
                recipeObject.set("objectId", recipe.objectId);
                recipeObject.set("product", { __type: "Pointer", className: "Product", objectId: productToSave.id });
                recipeObject.set("isActive", true);
                await recipeObject.save();
            }

            message.success("Producto guardado correctamente");
            refresh();
            setOpen(false);
            form.resetFields();
        } catch (error) {
            console.error("Error saving product:", error);
            message.error("Error al guardar el producto. Por favor intente de nuevo.");
        }
    };

    return (
        <Modal
            title={product ? "Editar producto" : "Nuevo producto"}
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="cancel" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="save" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} name="newProductForm">
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: "Por favor ingrese el nombre del producto" }]}
                >
                    <Input />
                </Form.Item>

                <Title level={5}>Receta</Title>
                <RecipeList setRecipe={setRecipe} recipe={recipe || product?.recipe} />

                <Form.Item
                    label="Precio mínimo"
                    name="minPrice"
                    rules={[{ required: true, message: "Por favor ingrese el precio mínimo" }]}
                >
                    <Input type="number" />
                </Form.Item>

                <Title level={5}>Presentaciones</Title>
                <PresentationList
                    setRenderId={setRenderId}
                    recipe={recipe}
                    presentations={presentations}
                    setPresentations={setPresentations}
                />
            </Form>
        </Modal>
    );
};

export default NewProduct;