import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col } from "antd";
import { calculatePending, allowSave } from "./utils";
import { addIngredients } from "./services";
import MixingHopperIngredients from "./MixingHopperIngredients";
import SetHopperModal from "./SetHopperModal";

const ForageAdding = ({ production, refresh }) => {
    const [ingredients, setIngredients] = useState([]);
    const [mixingWeightFound, setMixingWeightFound] = useState(false);
    const [showSetHopper, setShowSetHopper] = useState(false);

    useEffect(() => {
        if (production) {
            const mixingWeightEvent = production?.productionEvents?.find((event) => event.event === "weightMixingHopper");
            if (mixingWeightEvent) {
                setMixingWeightFound(true);
            }
            const initialIngredients = production.recipe.ingredients
                .filter((ingredient) => ingredient.warehouse === "Forrajes")
                .map((ingredient) => ({ ...ingredient, pending: 0, adding: 0 }));
            const updatedIngredients = calculatePending(initialIngredients, production);
            setIngredients(updatedIngredients);
        }
    }, [production]);

    const update = () => {
        setShowSetHopper(false);
        refresh();
    };

    const handleInputChange = (e, record) => {
        const value = parseFloat(e.target.value) || 0;
        setIngredients((prev) =>
            prev.map((ingredient) =>
                ingredient.objectId === record.objectId
                    ? { ...ingredient, adding: value }
                    : ingredient
            )
        );
    };

    const handleSave = async () => {
        const ingredientsToAdd = ingredients
            .filter((ingredient) => ingredient.adding > 0)
            .map((ingredient) => ({
                objectId: ingredient.objectId,
                quantity: ingredient.adding,
                name: ingredient.name,
            }));
        await addIngredients(production, ingredientsToAdd);
        refresh();
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card title={production.mixingHopper ? production.mixingHopper.name : "Sin tolva asignada"}>
                    {production.mixingHopper ? (
                        <MixingHopperIngredients ingredients={production?.mixingHopper?.ingredients} />
                    ) : (
                        <Button type="primary" onClick={() => setShowSetHopper(true)}>
                            Asignar tolva
                        </Button>
                    )}
                </Card>
            </Col>
            <Col span={24}>
                <Table
                    dataSource={ingredients}
                    columns={[
                        {
                            title: "Ingrediente",
                            dataIndex: "name",
                            key: "name",
                        },
                        {
                            title: "Requerido",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (quantity, record) => `${quantity} ${record.basePresentation}`,
                        },
                        {
                            title: "Faltante",
                            dataIndex: "pending",
                            key: "pending",
                            render: (text, record) => `${text} ${record.basePresentation}`,
                        },
                        {
                            title: "Agregar",
                            dataIndex: "adding",
                            key: "adding",
                            render: (text, record) => (
                                <Input
                                    disabled={record.pending <= 0}
                                    type="number"
                                    suffix="kg"
                                    value={record.adding}
                                    onChange={(e) => handleInputChange(e, record)}
                                />
                            ),
                        },
                    ]}
                    pagination={false}
                />
            </Col>
            <Col span={24}>
                <Button
                    type="primary"
                    disabled={!allowSave(ingredients)}
                    onClick={handleSave}
                >
                    Agregar ingredientes
                </Button>
            </Col>
            <SetHopperModal
                open={showSetHopper}
                setOpen={update}
                production={production}
            />
        </Row>
    );
};

export default ForageAdding;