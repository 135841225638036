import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography, Statistic, Button, Flex, Input, DatePicker, Select } from "antd";
import ReactApexChart from "react-apexcharts";
import NewInventoryEntry from "./NewInventoryEntry";
import Parse from "parse";
import moment from "moment";
import ConfirmInventoryEntry from "./ConfirmInventoryEntry";

const { Title } = Typography;

const fetchSupplier = async (name) => {
  const Supplier = Parse.Object.extend("_User");
  const query = new Parse.Query(Supplier);
  query.matches("fullName", name, "i");
  const results = await query.find();
  return results
};

const fetchPurchaseOrder = async ({purchaseOrderNumber, supplier}) => {
  const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
  const query = new Parse.Query(PurchaseOrder);
  if (supplier) {
    const suppliers = await fetchSupplier(supplier);
    query.containedIn("supplier", suppliers);
  }
  if (purchaseOrderNumber) query.matches("purchaseOrderNumber", purchaseOrderNumber, "i");
  const results = await query.find();
  return results
};

const fetchIngredients = async (name) => {
  console.log(name);
  const Ingredients = Parse.Object.extend("Ingredient");
  const query = new Parse.Query(Ingredients);
  query.matches("name", name, "i");
  const results = await query.find();
  console.log(results);
  return results
};


const fetchWarehouseEntries = async (filters) => {
  console.log(filters);
  const WarehouseEntry = Parse.Object.extend("WarehouseEntry");
  const query = new Parse.Query(WarehouseEntry);
  query.include(["purchaseOrder", "purchaseOrder.supplier"]);
  if (filters.status?.length) query.containedIn("status", filters.status);
  if (filters.purchaseOrderNumber || filters.supplier) {
    const purchaseOrders = await fetchPurchaseOrder({purchaseOrderNumber: filters.purchaseOrderNumber, supplier: filters.supplier});
    query.containedIn("purchaseOrder", purchaseOrders);
  }
  if (filters.orderNumber) query.contains("orderNumber", filters.orderNumber);
  if (filters.product) {
    const ingredients = await fetchIngredients(filters.product);
    console.log(ingredients);
    query.containedIn("productPointers", ingredients);
  }
  if (filters.date) {
    const start = moment(filters.date[0].$d).startOf("day").toDate();
    const end = moment(filters.date[1].$d).endOf("day").toDate();
    query.greaterThanOrEqualTo("createdAt", start);
    query.lessThanOrEqualTo("createdAt", end);
  }
  const results = await query.find();
  return results?.map((result) => result.toJSON());
}

const WarehouseEntryList = () => {
  const [purchaseOrders, setPurchaseOrders] = React.useState([]);
  const [newEntry, setNewEntry] = React.useState(false);
  const [confirmEntry, setConfirmEntry] = React.useState(false);
  const [entryInfo, setEntryInfo] = React.useState(null);
  const [filters, setFilters] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);

  const renderActions = (record) => {
    if (record.status === 'Pendiente') {
      return <Button type="primary" onClick={() => {
        setNewEntry(true)
        setEntryInfo(record)
      }}>
        Recibir
      </Button>
    } else if (record.status === 'Recibido') {
      return <Button type="primary" onClick={() => {
        setConfirmEntry(true)
        setEntryInfo(record)
      }
      }>
        Confirmar
      </Button>
    }
  }


  useEffect(() => {
    fetchWarehouseEntries(filters).then((results) => {
      setPurchaseOrders(results);
    });
  }, [refresh, filters]);

  const columns = [
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <p>{status}</p>
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: "Orden de compra",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      render: (purchaseOrder) => <p>{purchaseOrder?.purchaseOrderNumber}</p>,
       
    },
    {
      title: "Orden de entrada",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (orderNumber) => <p>{orderNumber}</p>
    },
      
    {
      title: "Productos",
      dataIndex: "products",
      key: "products",
      render: (products) => (
          products?.map((product) => (
              <p>{product.purchaseUnitQuantity?.toLocaleString()} {product.presentation?.name?.toLocaleLowerCase()} de {product?.name}</p>
          ))
      ),
    },
    {
      title: "Proveedor",
      dataIndex: ["purchaseOrder", "supplier"],
      key: "purchaseOrder",
      render: (supplier) => <p>{supplier?.fullName}</p>,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => renderActions(record),
    }
  ];

  // // Agrupar y sumar las salidas por fecha
  // const groupedData = dataSource.reduce((acc, curr) => {
  //   const date = curr.date;
  //   if (acc[date]) {
  //     acc[date]++;
  //   } else {
  //     acc[date] = 1;
  //   }
  //   return acc;
  // }, {});

  // // Preparar datos para la gráfica de líneas de salidas por día
  // const chartData = {
  //   series: [
  //     {
  //       name: "Salidas por día",
  //       data: Object.keys(groupedData).map((date) => ({
  //         x: new Date(date).getTime(),
  //         y: groupedData[date],
  //       })),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: 'line',
  //       height: 350,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //     },
  //     yaxis: {
  //       title: {
  //         text: 'Número de Salidas',
  //       },
  //     },
  //     tooltip: {
  //       x: {
  //         format: 'dd/MM/yyyy',
  //       },
  //     },
  //   },
  // };

  // // Calcular el total de salidas
  // const totalOutputs = dataSource.length;

  const renderFilters = () => 
    <Row gutter={[24, 0]}>
       <Col lg={6} className="zindex">
       <Select
        placeholder="Estado"
        onChange={(value) => 
          setFilters({
            ...filters,
            status: value?.length ? value : ["Pendiente", "Recibido", "Confirmado"],
          })
        }
        style={{ width: 200 }}
        mode="multiple"
      >
        <Select.Option value="Pendiente">Pendiente</Select.Option>
        <Select.Option value="Recibido">Recibido</Select.Option>
        <Select.Option value="Confirmado">Confirmado</Select.Option>
      </Select>
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Orden de compra"
        onChange={(e) => setFilters({ ...filters, purchaseOrderNumber: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Orden de entrada"
        onChange={(e) => setFilters({ ...filters, orderNumber: e.target.value })}
      />
      </Col>
      <Col lg={6} className="zindex">
       <Input
        placeholder="Producto"
        onChange={(e) => setFilters({ ...filters, product: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Proveedor"
        onChange={(e) => setFilters({ ...filters, supplier: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <DatePicker.RangePicker
        style={{ width: 200 }}
        onChange={(value) => setFilters({ ...filters, date: value })}
      />
      </Col>

    </Row>
  

  return (
    <div className="layout-content">
      <NewInventoryEntry open={newEntry} setOpen={setNewEntry} entryInfo={entryInfo} refresh={setRefresh} />
      <ConfirmInventoryEntry open={confirmEntry} setOpen={setConfirmEntry} entryInfo={entryInfo} refresh={setRefresh} />
      <Row gutter={[24, 0]}>
      <Flex justify="space-between" align="center">
      <h2 className="p-15 h2">Entradas de almacén</h2>
      {renderFilters()}
      </Flex>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={purchaseOrders}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        {/* <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de entradas</h3>}
          >
            <Statistic title="Total de entradas" value={totalOutputs} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Entradas por día</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col> */}
      </Row>


    </div>
  );
};

export default WarehouseEntryList;
