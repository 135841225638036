import React, { useEffect } from 'react';
import { Row, Col, Avatar, Switch, Card, Statistic, Table, Segmented, Space, FloatButton } from 'antd';
import Parse from 'parse';
import { useLocation } from 'react-router-dom';

import MovementsTable from './IngredientTable';
import IngredientCharts from './IngredientCharts';
import NewIngredient from './NewIngredient';

const fetchIngredient = async (ingredientId) => {
  const Ingredient = Parse.Object.extend('Ingredient');
  const query = new Parse.Query(Ingredient);
  query.equalTo('objectId', ingredientId);
  const result = await query.first();
  return result.toJSON();
}

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const InventoryDetails = () => {
  const [item, setItem] = React.useState(null);
  const [editProductModal, setEditProductModal] = React.useState(false);
  const [chartGroup, setChartGroup] = React.useState('Entradas');
  const [openEdit, setOpenEdit] = React.useState(false);
  const location = useLocation();

  const refresh = async () => {
    setOpenEdit(false);
    const item = await fetchIngredient(location.state.item.objectId);
    setItem(item);
  }
  
  useEffect(() => {
    setItem(location.state.item);
  }, [location]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={8} md={12} lg={12}>
        <Card
          id="profile"
          className="card-profile-head"
          bordered={false}
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                  {/* <Avatar size={74} shape="square" src="https://http2.mlstatic.com/D_NQ_NP_961228-MLM72596894113_102023-O.webp" /> */}
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{item?.name}</h4>
                    <div style={{ color: "gray" }}>Unidad base: {item?.basePresentation}</div>
                    <div style={{ color: "gray" }}>Costo promedio: ${item?.baseCost?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <small className="font-regular mr-5">
                  Activo
                </small>
                <Switch defaultChecked onChange={onChange} checked={item?.isActive} />
              </Col>
            </Row>
          }
        ></Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Existencia" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title={item?.basePresentation}
                value={item?.stock}
                valueStyle={{ color: '#a7c957' }} />
          </Row>
        </Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Valor total" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title="Total"
                prefix="$"
                value={item?.stock * item?.baseCost}
                valueStyle={{ fontSize: 18 }} />
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <IngredientCharts item={item} />
      </Col>

      <Col span={24}>
      <MovementsTable item={item} />
      </Col>
      <NewIngredient open={openEdit} setOpen={setOpenEdit} ingredient={item} />
      <FloatButton onClick={() => setOpenEdit(true)} refresh={refresh} />
    </Row>
  )
}

export default InventoryDetails;
