import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
import Parse from 'parse';

function toTwoDecimal(input) {
    // Convert the input to a float
    const number = parseFloat(input);

    // Check if it's a valid number, return 0 if not
    if (isNaN(number)) {
        return 0;
    }

    // Round to two decimal places and return
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

const ConfirmInventoryEntry = ({ open, setOpen, entryInfo, refresh }) => {
    const [form] = Form.useForm();
    const [entry, setEntry] = useState(entryInfo);
    const [openCamera, setOpenCamera] = useState(false);

    useEffect(() => {
        if (!open) {
            setEntry(null);
            form.resetFields();
        }
        else {
            setEntry(entryInfo);
        }
    }, [open]);


    const handleSubmit = async () => {
        try {
            // Validate form fields
            const values = await form.validateFields();
            const products = [];
            const inventoryMovements = [];
    
            // Prepare the WarehouseEntry object
            const WarehouseEntry = Parse.Object.extend("WarehouseEntry");
            const entryObject = new WarehouseEntry();
            entryObject.set("objectId", entryInfo.objectId);
            entryObject.set("status", "Confirmado");
            entryObject.set("confirmedBy", Parse.User.current());
            entryObject.set("products", entry.products);

            // Save the object
            const entryResult = await entryObject.save();

            const Product = Parse.Object.extend("Ingredient")
            const query = new Parse.Query(Product)
            query.containedIn("objectId", entry.products.map(product => product.objectId))
            const productObjects = await query.find()

            const StockMovement = Parse.Object.extend("StockMovement")


            productObjects.forEach(product => {
                const entryProduct = entry.products.find(p => p.objectId === product.id)
                const newCost = ((product.get("baseCost") * product.get("stock")) + (entryProduct.baseUnitCost * entryProduct.baseUnitReceived)) / (product.get("stock") + entryProduct.baseUnitReceived).toFixed(2)
                const priceChange = {
                    "date": new Date(),
                    "newCost": toTwoDecimal(newCost),
                    "newStock": toTwoDecimal(product.get("stock") + entryProduct.baseUnitReceived),
                    "previousCost": toTwoDecimal(product.get("baseCost")),
                    "lastCost": toTwoDecimal(entryProduct.unitPrice),
                    "previousStock": toTwoDecimal(product.get("stock")),
                    "lastReceived": toTwoDecimal(entryProduct.baseUnitReceived)
                }
                const productObject = new Product()
                productObject.set("objectId", entryProduct.objectId)
                productObject.increment("stock", toTwoDecimal(entryProduct.baseUnitReceived))
                productObject.set('lastReception', new Date())
                productObject.set('baseCost',toTwoDecimal(newCost))
                productObject.addUnique('priceChange', priceChange)
                products.push(productObject)

                const movement = new StockMovement()
                movement.set("ingredient", { __type: 'Pointer', className: 'Ingredient', objectId: product.id })
                movement.set("quantity", toTwoDecimal(entryProduct.baseUnitReceived))
                movement.set("type", "Entrada por compra")
                movement.set("warehouseEntry", { __type: 'Pointer', className: 'WarehouseEntry', objectId: entryResult.id })
                movement.set("currentStock", product.get("stock") + toTwoDecimal(entryProduct.baseUnitReceived))
                movement.set("previousStock", product.get("stock"))
                movement.set("cost", toTwoDecimal(entryProduct.unitPrice))
                movement.set("user", Parse.User.current())
                movement.set("purchaseOrder", { __type: 'Pointer', className: 'PurchaseOrder', objectId: entry.purchaseOrder.objectId })
                inventoryMovements.push(movement)
            })


            await Parse.Object.saveAll([...products, ...inventoryMovements])
    
            // Success handling
            message.success('Entrada de almacén creada correctamente.');
            refresh();
            setOpen(false);
        } catch (error) {
            // Error handling
            console.error("Error during handleSubmit:", error);
    
            // Error message for form validation failure or save failure
            if (error instanceof Parse.Error) {
                message.error("Error al guardar la entrada de almacén.");
            } else {
                message.error("Error al validar los campos del formulario.");
            }
        }
    };
    
    console.log("📦 Entry: ", entry);

    useEffect(() => {
        const products = entryInfo?.products.map((product) => {
            return {
                ...product,
                
            };
        });
        setEntry({ ...entryInfo, products });
    }, [entryInfo, open]);

    return (
        <Modal
            title="Confirmar entrada de inventario"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <h2>Orden de compra: {entry?.purchaseOrder?.purchaseOrderNumber}</h2>
            <h3>Proveedor: {entry?.purchaseOrder?.supplier?.fullName}</h3>
           <Table
                dataSource={entry?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "purchaseUnitQuantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.presentation?.name?.toLowerCase()}{text !== 1 ? 's' : ''}</p>
                        ),
                    },
                    {
                        title: "Recibido",
                        dataIndex: "purchaseUnitReceived",
                        key: "received",
                        render: (text, product) => (
                            <p>{text} {product.presentation?.name?.toLowerCase()}{text !== 1 ? 's' : ''}</p>
                        ),
                    },
                    {
                        title: "Volumen estimado",
                        dataIndex: "purchaseUnitReceived",
                        key: "estimatedVolume",
                        render: (received, product) => (
                            <p>{received * product.presentation?.quantity} {product.basePresentation?.toLowerCase()}{received !== 1 ? 's' : ''}</p>
                        ),
                    },
                    {
                        title: 'Volumen total',
                        dataIndex: 'baseUnitReceived',
                        key: 'baseUnitReceived',
                        render: (text, product) => (
                            <Input 
                                type="number" 
                                suffix={product.basePresentation}
                                value={product.baseUnitReceived} 
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const newProducts = entry.products.map(p => {
                                        if (p.objectId === product.objectId) {
                                            p.baseUnitReceived = parseInt(value);
                                        }
                                        return p;
                                    });
                                    setEntry({ ...entry, products: newProducts });
                                }}
                            />
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {entry?.images && entry?.images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                    </Space>
                ))}
            </Flex>
        </Modal>
    );
}

export default ConfirmInventoryEntry;
