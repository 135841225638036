import React, { useEffect, useState } from "react";
import { Button, Table, message, Card, Row, Col } from "antd";
import { calculatePending } from "./utils";
import { saveEvent, updateProductionOrder, addStockMovement, addStock, calculateCost } from "./services";
import SetHopperModal from "./SetHopperModal";
import ConfirmProductionModal from "./ConfirmProductionModal";

const ForageAdding = ({ production, refresh }) => {
    const [hopper, setHopper] = useState(null);
    const [showSetHopper, setShowSetHopper] = useState(false);
    const [showConfirmProduction, setShowConfirmProduction] = useState(false);
    const [weights, setWeights] = useState({});

    const update = () => {
        setShowSetHopper(false);
        refresh();
    }

    useEffect(() => {
        if (production) {
            setHopper(production.storageHopper);
            let initialIngredients = production.recipe.ingredients
            production.productionEvents
            .filter((event) => event.event === "addIngredients")
            .filter((event) => event.warehouse === "Producción")
            .map((event) => event.ingredients?.forEach((ingredient) => {
                initialIngredients = initialIngredients.map((recipeIngredient) => {
                    if (recipeIngredient.objectId === ingredient.objectId) {
                        return {
                            ...recipeIngredient,
                            pending: recipeIngredient.pending - ingredient.quantity,
                        };
                    }
                    return recipeIngredient;
                });
            }))
            const ingredientsWeight = initialIngredients.reduce((acc, ingredient) => acc + ingredient.warehouse === 'Producción' ? ingredient.quantity : 0, 0);
            


            const waitingHopperWeightEvent = production?.productionEvents?.find((event) => event.event === "weightWaitingHopper");
            const waitingHopperWeight = waitingHopperWeightEvent?.weights?.waitingHopperWeight || 0;

            const storageHopperWeightEvent = production?.productionEvents?.find((event) => event.event === "setStorageHopper");

            if (storageHopperWeightEvent) {
                setWeights(storageHopperWeightEvent.weights);
            } else {
                setWeights({ waitingHopperWeight, storageHopperWeight: 0, ingredientsWeight });
            }
        }
    }, [production]);

    const confirmProduction = async () => {
        try {
            const productionCost = await calculateCost({ production });
            const savedEvent = await saveEvent({ production, weights });
            await updateProductionOrder({ production, productionEvent: savedEvent, productionCost, weights });
            await addStockMovement({ production, weights, productionEvent: savedEvent });
            await addStock({ production, weights, productionCost });

            message.success("Producción confirmada correctamente");
            setShowConfirmProduction(false);
            refresh();
        } catch (error) {
            console.error("Error confirming production:", error);
            message.error("Error al confirmar la producción.");
        }
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card title={hopper ? hopper.name : "Sin tolva asignada"}>
                    {!hopper && (
                        <Button type="primary" onClick={() => setShowSetHopper(true)}>
                            Asignar tolva
                        </Button>
                    )}
                </Card>
            </Col>
            <Col span={8}>
                <Card title="Peso en tolva de espera">{weights?.waitingHopperWeight} kg</Card>
            </Col>
            <Col span={8}>
                <Card title="Peso de grasa y digesta">{weights?.ingredientsWeight} kg</Card>
            </Col>
            <Col span={24}>
                <Button
                    type="primary"
                    onClick={() => setShowConfirmProduction(true)}
                    style={{ marginTop: "20px" }}
                    disabled={production.confirmed}
                >
                    Confirmar producción
                </Button>
            </Col>
            <SetHopperModal
                open={showSetHopper}
                setOpen={update}
                production={production}
            />
            <ConfirmProductionModal
                open={showConfirmProduction}
                setOpen={setShowConfirmProduction}
                production={production}
                weights={weights}
                onConfirm={confirmProduction}
            />
        </Row>
    );
};

export default ForageAdding;