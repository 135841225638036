import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Statistic, Button } from "antd";
import Parse from "parse";
import moment from "moment";
import ConfirmPayment from "../components/ConfirmPayment";




const fetchSellercomissionPercentages = async (sellerId) => {
    const Payments = Parse.Object.extend('Outpayments');
    const query = new Parse.Query(Payments);
    query.equalTo('seller', { __type: 'Pointer', className: '_User', objectId: sellerId });
    query.equalTo('type', 'comission');
    query.include('sales');

    const results = await query.find();
    return results.map((result) => result.toJSON());
};

const SellerComissions = ({ seller }) => {
  const [comissionPercentages, setcomissionPercentages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [outpayment, setOutpayment] = useState(null);

  useEffect(() => {
    const fetchcomissionPercentages = async () => {
      const comissionPercentages = await fetchSellercomissionPercentages(seller.objectId);
      setcomissionPercentages(comissionPercentages);
      setLoading(false);
    };
    fetchcomissionPercentages();
  }, [seller]);


  const renderActions = (record) => {
    if (record.status === 'Pendiente') {
      return <Button type="primary" onClick={() => {
        setOutpayment(record);
        setConfirmPayment(true);
      }}>
        Pagar
      </Button>
    }
    else if (record.status === 'Pagado') {
      return <Button type="primary">Descargar recibo</Button>
    }
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    
      {
        title: 'No. de Orden',
        dataIndex: 'outpaymentNumber',
        key: 'outpaymentNumber',
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => <span>{moment(date.iso).format('DD/MM/YYYY')}</span>,
      },
      {
        title: 'Ventas',
        dataIndex: 'sales',
        key: 'sales',
        render: (sales) => <span>{sales.map((sale) => sale.saleNumber).join(', ')}</span>,
      },
      {
        title: 'Monto de venta',
        dataIndex: 'base',
        key: 'base',
      },
      {
        title: 'Comisión',
        dataIndex: 'comission',
        key: 'comission',
        render: (text, record) => <span>{record.amount}</span>,
      },
      {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => renderActions(record),
      }
  ];

  console.log(seller);
    return (
        <Row gutter={[24, 0]}>
          <ConfirmPayment outpayment={outpayment} open={confirmPayment} setOpen={setConfirmPayment} />
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Comisión</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            title={<h3>Porcentaje de Comisión</h3>}
                            value={seller?.comissionPercentage}
                            formatter={(value) => <span>{value} %</span>}
                        />
                    </Col>
                </Row>
                </Card> 
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Historial de Comisiones</h2>
                    <Table columns={columns} dataSource={comissionPercentages} loading={loading} rowKey={(record) => record.objectId} />
                </Card>
            </Col>
        </Row>
    )
}

export {SellerComissions};