import React from "react";
import { Card, Form, Row, Col, Input } from "antd";

const ClientFiscalInfo = ({ client }) => (
  <Card
    id="client-sales"
    className="mb-24 header-solid"
    bordered={false}
    title={<h5 className="mb-0">Información fiscal</h5>}
  >
    <Form layout="vertical">
      <Row gutter={[30, 20]}>
        <Col span={24} lg={12}>
          <Form.Item label="Razón social">
            <Input value={client?.fiscalInformation?.businessName} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="RFC">
            <Input value={client?.fiscalInformation?.rfc} readOnly />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Régimen fiscal">
            <Input value={client?.fiscalInformation?.fiscalRegime?.children} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Card>
);

export default ClientFiscalInfo;