import React, { useEffect, useState } from "react";
import { Row, Col, Card, Steps, Progress } from "antd";
import Parse from "parse";
import { useParams } from "react-router-dom";
import ProductionSummary from "./Tabs/ProductionSummary";
import ForageAdding from "./Tabs/ForageAdding/ForageAdding";
import MixingHopper from "./Tabs/MixingHopper/MixingHopper";
import WaitingHopper from "./Tabs/WaitingHopper";
import Greasing from "./Tabs/Greasing";
import StorageHopper from "./Tabs/StorageHopper";
import Title from "antd/es/typography/Title";

// Fetch production data
const fetchProduction = async (uuid) => {
    const Production = Parse.Object.extend("ProductionOrder");
    const query = new Parse.Query(Production);
    query.equalTo("uuid", uuid);
    query.include([
        "product",
        "recipePointer",
        "ingredientPointers",
        "productionEvents",
        "shrinkage",
        "mixingHopper",
        "waitingHopper",
        "storageHopper",
    ]);
    const result = await query.first();
    return result?.toJSON();
};

const grainsPercent = (production) => {
    let percent = (production?.grainsAdded / production?.totalGrains * 100)
    percent = isNaN(percent) ? 0 : percent;
    return percent;
}

const fatPercent = (production) => {
    let percent = production?.fatAdded / production?.totalFat * 100;
    percent = isNaN(percent) ? 0 : percent;
    return percent;
}


// Calculate various progress percentages
const calculateProgress = (production, currentStep) => {
    if (!production) return 0;

    switch (currentStep) {
        case 0:
            return (
                grainsPercent(production) * 0.33 +
                fatPercent(production) * 0.33
            );
        case 1:
            return grainsPercent(production);
        case 2:
            return fatPercent(production);
        case 3:
            return production?.colors ? 33 : 0 + production?.finalWeight ? 33 : 0 + production?.retrievedFinePowders ? 34 : 0;
        default:
            return 0;
    }
};

const MoliendaTitle = () => {
    return (
        <Title level={4}>Molienda</Title>
    );
};

const ExtrusionTitle = () => {
    return (
        <Title level={4}>Extrusión</Title>
    );
};

const SecadoTitle = () => {
    return (
        <Title level={4}>Secado</Title>
    );
};

const EnfriadoTitle = () => {
    return (
        <Title level={4}>Enfriado</Title>
    );
};


// Step Definitions
const steps = [
    {
        title: "Resumen",
        content: ProductionSummary,
    },
    {
        title: "Forrajes",
        content: ForageAdding,
    },
    {
        title: "Tolva de mezcla",
        content: MixingHopper,
    },
    {
        title: "Molienda",
        content: MoliendaTitle
    },
    {
        title: "Extrusión",
        content:ExtrusionTitle
    },
    {
        title: "Secado",
        content: SecadoTitle
    },
    {
        title: "Tolva de espera",
        content: WaitingHopper,
    },
    {
        title: "Engrasado",
        content: Greasing,
    },
    {
        title: "Enfriado",
        content: EnfriadoTitle
    },
    {
        title: "Tolva de almacenamiento",
        content: StorageHopper,
    }
   
];

const ProductionDetails = () => {
    const { id } = useParams();
    const [current, setCurrent] = useState(0);
    const [production, setProduction] = useState(null);

    const refresh = () => {
        fetchProduction(id).then(setProduction);
    };

    // Update production state
    useEffect(() => {
        const processProduction = (result) => {
            if (!result) return;
            console.log('result', result);
            const ingredients = {};
            
            result.recipe.ingredients.forEach((ingredient) => {
                ingredients[ingredient.objectId] = {
                    ...ingredient,
                    added: 0,
                    total: ingredient.quantity * result.productionQuantity,
                };
            });

            result.production?.forEach((prod) => {
                prod?.ingredients?.forEach((ingredient) => {
                    if (ingredient.quantity && ingredient.quantity !== "0") {
                        ingredients[ingredient.objectId].added =
                            (ingredients[ingredient.objectId]?.added || 0) +
                            parseFloat(ingredient.quantity);
                    }
                });
            });


            const grainsAdded = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Forrajes")
                .reduce((sum, ing) => sum + ing.added, 0);

            const fatAdded = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Producción")
                .reduce((sum, ing) => sum + ing.added, 0);


            const totalGrains = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Forrajes")
                .reduce((sum, ing) => sum + ing.quantity, 0) * result.productionQuantity;

            const totalFat = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Producción")
                .reduce((sum, ing) => sum + ing.quantity, 0) * result.productionQuantity;

            setProduction({
                ...result,
                grainsAdded,
                fatAdded,
                totalGrains,
                totalFat,
                ingredients: Object.values(ingredients),
            });
        };

        fetchProduction(id).then(processProduction);
    }, [id]);

    // Handle step change
    const handleStepChange = (newStep) => {
        setCurrent(newStep);
    };

    const CurrentComponent = steps[current].content;

    return (
        <Col>
            <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Detalles de Producción</h1>
            <h2>{production?.product?.name}</h2>
            <h4>{production?.folio}</h4>
            <Row  style={{ marginBottom: "20px" }} gutter={16}>
               
                <Col span={18}>
                <CurrentComponent production={production} percent={calculateProgress(production, current)} refresh={refresh} />
                </Col>
                <Col span={6}>
                    <Steps
                        current={current}
                        onChange={handleStepChange}
                        size="small"
                        direction="vertical"
                    >
                        {steps.map((step, index) => (
                            <Steps.Step
                                key={index}
                                title={
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        {step.title}
                                        <Progress
                                            percent={calculateProgress(production, index)}
                                            format={() => ""}
                                            style={{ width: "5vw" }}
                                        />
                                    </div>
                                }
                            />
                        ))}
                    </Steps>
                </Col>
        </Row>
            
        </Col>
    );
};

export default ProductionDetails;