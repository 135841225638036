import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row, Table, Typography, FloatButton, Progress, Tag, Tooltip, Flex, Input, Select, DatePicker } from "antd";
import ReactApexChart from "react-apexcharts";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import NewQuote from "./components/NewPurchaseOrder";
import Parse from "parse";
import moment from "moment";
import dayjs from "dayjs";
import PurchaseOrderDetail from "./components/PurchaseOrderDetail";
import { UserContext } from "../../../context/UserContext";

const { Title } = Typography;

const fetchSupplier = async (name) => {
  const Supplier = Parse.Object.extend("_User");
  const query = new Parse.Query(Supplier);
  query.matches("fullName", name, "i");
  const results = await query.find();
  return results.map((result) => result.toJSON());
};


const fetchPurchaseOrders = async (filters) => {
  const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
  const query = new Parse.Query(PurchaseOrder);
  query.descending("createdAt");
  // query.equalTo("active", true);
  query.include(["supplier", "payments", "entries", "createdBy"]);
  if (filters) {
    if (filters.supplier) {
      const suppliers = await fetchSupplier(filters.supplier);
      query.containedIn("supplier", suppliers);
    }
    if (filters.purchaseNumber) {
      query.contains("purchaseOrderNumber", filters.purchaseNumber);
    }
    if (filters.status) {
      query.containedIn("status", filters.status);
    }
    if (filters.date?.length) {
      const start = filters.date[0].startOf('day').toDate();
      const end = filters.date[1].endOf('day').toDate();
      query.greaterThanOrEqualTo("createdAt", start);
      query.lessThanOrEqualTo("createdAt", end);
    }
  }
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

const PurchaseOrdersList = () => {
  const [newQuoteModal, setNewQuoteModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const { user, permissions } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    fetchPurchaseOrders(filters).then((results) => {
      setPurchaseOrders(results);
      setLoading(false);
    });
    
  }, [shouldRefresh, filters]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };


  // Calcular la suma total de los totales
  const totalPurchaseOrders = purchaseOrders?.reduce((acc, curr) => acc + curr.total, 0);

  const renderDeliveryPlace = (deliveryPlace, purchaseOrder) => {
    console.log("📍 Delivery Place: ", deliveryPlace);
    if (deliveryPlace === 'Pie de fábrica') {
      return 'Pie de fábrica';
    }
    // Get the previous term from the delivery address terms array
    let terms = purchaseOrder.deliveryAddress?.terms;
    if (terms) {
      return terms[terms.length - 3].value + ', ' + terms[terms.length - 2].value
    } else {
      return purchaseOrder.deliveryAddress?.address;
    }
  }

  const columns = [
   
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: "Folio",
      dataIndex: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
      render: (text, purchaseOrder) => <p onClick={() => {
        console.log("📦 Purchase Order: ", purchaseOrder);
        setPurchaseOrder(purchaseOrder);
        setShowDetail(true);
      }}>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    {
      title: "Proveedor",
      dataIndex: "supplier",
      key: "supplier",
      render: (supplier) => <Tooltip title={<Flex vertical gap="10px">
        <p>{supplier?.fullName}</p>
        <p>{supplier?.rfc}</p>
        <p>{supplier?.email}</p>
        <p>{supplier?.phone}</p>
      </Flex>}>
        <p>{supplier?.fullName}</p>
      </Tooltip>,
    },
   
    // {
    //   title: "Productos",
    //   dataIndex: "products",
    //   key: "products",
    //   render: (text) => <p>{text}</p>,
    // },
   
    {
      title: 'Recepciones',
      dataIndex: 'entries',
      key: 'entries',
      render: (text, entry) => {
        let receivedVolume = 0;

    // Calculate expected volume
    const expectedVolume = entry.products?.reduce((acc, product) => acc + product.purchaseUnitQuantity, 0);

    if (entry.entries) {
        console.log("🚚 Entries: ", entry.entries);

        // Calculate received volume
        receivedVolume = entry.entries.reduce((acc, entry) => {
            console.log("🚚 Entry: ", entry);
            const entryReceived = entry.products.reduce((sum, product) => {
                console.log("🚚 Product: ", product, "Current receivedVolume: ", acc + sum);
                return sum + (parseFloat(product.purchaseUnitReceived) || 0);
            }, 0);
            return acc + entryReceived;
        }, 0);
    }

    console.log("🚚 Total Expected Volume: ", expectedVolume);
    console.log("🚚 Total Received Volume: ", receivedVolume);

    // If no items have been received
    if (receivedVolume === 0) {
        return 'Sin recepciones';
    }

    // Calculate percentage
    const percentage = ((receivedVolume / expectedVolume) * 100).toFixed(0);
    console.log("🚚 Progress: ", receivedVolume, expectedVolume, percentage);

    // Render progress
    return (
        <Progress
            percent={percentage}
            style={{ width: 100 }}
            format={() =>
                receivedVolume < expectedVolume ? `${percentage}%` : 'Recibido'
            }
        />
    );
      }
    },
    {
      title: "Volumen total",
      dataIndex: "entries",
      key: "entries",
      render: (text, entry) => {

        let receivedVolume = 0;
        let expectedVolume = 0;

        // Calculate expected volume
          expectedVolume = entry.products?.reduce((acc, product) => acc + product.baseUnitQuantity || 0, 0);
    
        if (entry.entries) {
            console.log("🚚 Entries: ", entry.entries);
    
            // Calculate received volume
            receivedVolume = entry.entries.reduce((acc, entry) => {
                console.log("🚚 Entry: ", entry);
                const entryReceived = entry.products.reduce((sum, product) => {
                    console.log("🚚 Product: ", product, "Current receivedVolume: ", acc + sum);
                    return sum + (product.baseUnitReceived || 0);
                }, 0);
                return acc + entryReceived;
            }, 0);
        }
        console.log("🚚 Total Expected Volume: ", expectedVolume);
        console.log("🚚 Total Received Volume: ", receivedVolume);
    
        // If no items have been received
        if (receivedVolume === 0) {
            return 'Sin recepciones';
        }
    
        // Calculate percentage
        const percentage = ((receivedVolume / expectedVolume) * 100).toFixed(0);
        console.log("🚚 Progress: ", receivedVolume, expectedVolume, percentage);

        if (entry.closed) {
          return <><h3>{receivedVolume.toLocaleString()}</h3>{entry.closed && <Tag color="green">Cerrada</Tag>}</>
        }
    
        return<h3>{receivedVolume.toLocaleString()}/{expectedVolume.toLocaleString()}</h3>
          }
    },
    {
      title: 'Pagos',
      dataIndex: 'payments',
      key: 'payments',
      render: (text, entry) => {
        let paidAmount = entry.totalPaid || 0;
        // Percentage to 2 decimal places
        const percentage = ((paidAmount / entry.total) * 100).toFixed(2);
        if (paidAmount === 0) {
          return 'Sin pagos';
        }
        return <Progress percent={percentage} style={{ width: 100 }} format={() => '$' + paidAmount.toLocaleString()} />
      }
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>${text.toLocaleString()}</h3>,
    },
  ];

  const newPurchaseOrderButton = () => {
    console.log("🔒 Permissions: ", permissions);
    if (permissions.find(p => p?.group === 'purchases' && p?.action === 'create')) {
      return <FloatButton style={{ right: 20, bottom: 20 }} tooltip="Nueva compra" icon=<PlusOutlined /> onClick={() => setNewQuoteModal(true)} />
    }
  }

  const renderFilters = () => {
    return (
    <Flex direction="row" justify="flex-end" gap={10} style={{ marginBottom: 20, height: 40 }}>
      <Input
            placeholder="Proveedor"
            style={{ width: 200 }}
            value={filters.seller}
            onChange={(e) => setFilters({...filters, supplier: e.target.value})}
        />
        <Input
            placeholder="Folio" 
            style={{ width: 200 }}
            value={filters.saleNumber}
            onChange={(e) => setFilters({...filters, purchaseNumber: e.target.value})}
        />
        <Select 
            placeholder="Status" 
            style={{ width: 300 }} 
            value={filters.status}
            onChange={(e) => {
              if (e.length) setFilters({...filters, status: e})
              else setFilters({...filters, status: ['Pendiente', 'Confirmada']})
            }}
            allowClear
            mode="multiple"
        >
            <Select.Option value="Pendiente">Pendiente</Select.Option>
            <Select.Option value="Confirmada">Confirmada</Select.Option>
        </Select>
        <DatePicker.RangePicker 
            style={{ width: 300 }} 
            onChange={(e) => setFilters({...filters, date: e})} 
            value={filters.date} 
            defaultValue={[moment(dayjs().startOf('month')), moment(dayjs().endOf('month'))]}
        />
    </Flex>
    );
}

  return (
    <div className="layout-content">
      <NewQuote open={newQuoteModal} setOpen={setNewQuoteModal} refresh={refresh} />
      <PurchaseOrderDetail open={showDetail} setOpen={setShowDetail} purchaseOrder={purchaseOrder} refresh={refresh} shouldRefresh={shouldRefresh} setPurchaseOrder={setPurchaseOrder} />
      {newPurchaseOrderButton()}
       {/* <Row>
        <Col span={24}>
          <Card
            style={{ padding: 20 }}
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3 style={{fontSize:"25px"}}>Estadística de Compras</h3>}
          >
            <div className="ant-list-box table-responsive">
              <div className="linechart">
                <div></div>
                <div className="purchaseorders">
                  <ul>
                    <li><MinusOutlined /> Kilos</li>
                    <li><MinusOutlined /> Compra en $</li>
                  </ul>
                </div>
              </div>

              <ReactApexChart
                className="full-width"
                options={options}
                series={chartData.series}
                type="area"
                height={350}
                width={"100%"}
              />
            </div>
          </Card>
        </Col>
      </Row> */}
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
        <Flex justify="space-between" align="center">
                 <h2 className="p-15 h2">Compras</h2>
                 {renderFilters()}
               </Flex>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <div className="ant-list-box table-responsive">
              <Table
                loading={loading}
                dataSource={purchaseOrders}
                columns={columns} // Usar las columnas con la suma total
                rowKey={(record, index) => index}
                pagination={true}
                footer={() => (
                  <div style={{ textAlign: "right", fontSize:"20px" }}>
                    <strong>Total:</strong> ${totalPurchaseOrders.toLocaleString()}
                  </div>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
      
    </div>
  );
};

export default PurchaseOrdersList;
