import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchIngredients } from "../utils/query";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;

const IngredientsList = ({ form, setRenderId }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        form.getFieldValue("products") && setProducts(form.getFieldValue("products"));
    }, [form]);
    

    const onProductSelected = (objectId, product) => {
        console.log("product", product);
        const products = form.getFieldValue("products");
        if (products.find((p) => p.objectId === product.objectId)) {
            return;
        }
        let presentations = product.presentations?.map((p) => ({
            label: p.presentation,
            value: p.presentation,
            ...p,
        }));
        presentations = [...presentations, { label: product.basePresentation, value: product.basePresentation, quantity: 1 }];
        products.push({
            ...product,
            quantity: 1,
            objectId: product.objectId,
            presentations
        });
        console.log("products", products);
        form.setFieldValue("products", products);
        setProducts(products);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchIngredients}
                onChange={onProductSelected}
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={products}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Producto"
                dataIndex="name"
                key="name"
            />
            <Table.Column
                title="Presentación"
                dataIndex="presentation"
                key="presentation"
                render={(text, product, key) => 
                    <Select
                        style={{ width: 200 }}
                        onChange={(value, object) => {
                            let products = form.getFieldValue("products");
                            let product = products[key];
                            product.presentation = product.presentations?.find((p) => p.label === value);
                            product.lastPrice = product.baseCost * product.presentation.quantity;
                            products = products.map((p) => (p.objectId === object.objectId ? product : p));
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                        options={product.presentations}
                    />
                }      
            />
            
            <Table.Column
                title="Contenido"
                dataIndex="presentation"
                key="presentation"
                render={(text, product) => <p>{product?.presentation?.quantity} {product?.basePresentation}</p>}
            />
            
            {/* <Table.Column
                title="Última compra"
                dataIndex="lastPurchase"
                key="lastPurchase"
                render={(text, product) => <p>{text ? moment(text).format("DD/MM/YYYY") : "Sin compras previas"}</p>}
                colSpan={(text) => text ? 1 : 2}
            /> */}
            <Table.Column
                title="Último precio"
                dataIndex="lastPrice"
                key="lastPrice"
                render={(text) => <p>{text ? '$' + text.toLocaleString() : ""}</p>}
            />
            <Table.Column
                title="Precio"
                dataIndex="price"
                key="price"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                           
                            product.price = value;
                            product.unitPrice = value / product.presentation.quantity;
                            product.total = product.quantity * value;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                        onBlur={(e) => {
                            const value = e.target.value;
                            if (value < record.minPrice) {
                                message.error("El precio no puede ser menor al mínimo");
                                e.target.value = record.minPrice;
                                const products = form.getFieldValue("products");
                                const product = products.find((p) => p.objectId === record.objectId);
                                product.price = record.minPrice;
                                product.total = product.quantity * record.minPrice;
                                form.setFieldValue("products", products);
                                setProducts(products);
                                setRenderId((prev) => prev + 1);
                            }
                        }}
                    />
                )}
            />
             <Table.Column
                title="Precio unitario"
                dataIndex="unitPrice"
                key="unitPrice"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        suffix={(product) => product.basePresentation}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            product.unitPrice = value;
                            product.price = value * product.presentation.quantity;
                            product.total = product.quantity * product.price;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }
                    }
                    />
                )}
            />
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80, color: record.inventory < text ? "orange" : "" }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            product.quantity = value;
                            product.total = product.price * value;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            <Table.Column
                title="Total"
                dataIndex="total"
                key="total"
                render={(text) => <p>${text?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const products = form.getFieldValue("products");
                            form.setFieldValue(
                                "products",
                                products.filter((p) => p.objectId !== record.objectId)
                            );
                            setProducts(products.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        </Space>
    );
}

export default IngredientsList;
