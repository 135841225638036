import React, { useState, useEffect } from 'react';
import { Table, Select, Button, message, Flex, Input, Form, DatePicker, Tag, Typography, FloatButton } from 'antd';
import Parse from 'parse';
import moment from 'moment';
import NewRecipe from '../inventory/productInventory/modals/NewRecipe';

const fetchProducts = async (name) => {
    const query = new Parse.Query('Product');
    query.matches('name', name, 'i');
    return query.find();
}

const fetchRecipes = async (filters) => {
    const query = new Parse.Query('Recipe');
    if (filters.name) {
        query.matches('name', filters.name, 'i');
    }

    if (filters.product) {
        const products = await fetchProducts(filters.product);
        query.containedIn('product', products);
    }
    if (filters.date) {
        const start = moment(filters.date[0]).startOf('day').toDate();
        const end = moment(filters.date[1]).endOf('day').toDate();
        query.greaterThanOrEqualTo('createdAt', start);
        query.lessThanOrEqualTo('createdAt', end);
    }

    query.include('product')

    const results = await query.find();
    return results.map((result) => result.toJSON());
}

const RecipeList = () => {
    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [open, setOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [requiresProduct, setRequiresProduct] = useState(false);

    useEffect(() => {
        fetchRecipes(filters).then((results) => {
            setRecipes(results);
            setLoading(false);
        }).catch((error) => {
            console.error('Error while fetching recipes', error);
            setLoading(false);
        });
    }, [filters]);

    const refresh = () => {
        fetchRecipes(filters).then((results) => {
            setRecipes(results);
            setLoading(false);
        }).catch((error) => {
            console.error('Error while fetching recipes', error);
            setLoading(false);
        });
    }

    const columns = [
        {
            title: 'Fecha de creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => <Typography.Text>{moment(text).format('DD/MM/YYYY')}</Typography.Text>
        },
        {
            title: 'Producto',
            dataIndex: ['product', 'name'],
            key: 'product',
            render: (text) => <Typography.Text>{text}</Typography.Text>
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text, recipe) => <a onClick={() => { setOpen(true); setSelectedRecipe(recipe); }}>{text}</a>
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            render: (text) => <Typography.Text>{text}</Typography.Text>
        },
        {
            title: 'Ingredientes',
            dataIndex: 'ingredients',
            key: 'ingredients',
            render: (text) => <Typography.Text>{text?.map((ingredient) => ingredient.name).join(', ')}</Typography.Text>
        },
        {
            title: 'Costo',
            dataIndex: 'baseCost',
            key: 'cost',
            render: (text, recipe) => <Typography.Text>${text?.toLocaleString()} / {recipe.baseUnit}</Typography.Text>
        },
        {
            title: '',
            dataIndex: 'product',
            key: 'product',
            render: (product, recipe) => product && product.recipe?.objectId === recipe.objectId ? <Tag color="green">Activa</Tag> : <Tag color="red">Inactiva</Tag>
        }
    ];

    const renderFilters = () => {
        return (
            <Flex horizontal style={{ marginBottom: 24 }}>
                <Input placeholder="Producto" value={filters.product} onChange={(e) => setFilters({ ...filters, product: e.target.value })} style={{ marginRight: 8 }} allowClear />
                <Input placeholder="Receta" value={filters.name} onChange={(e) => setFilters({ ...filters, name: e.target.value })} style={{ marginRight: 8 }} allowClear />
            </Flex>
        );
    }

    return (
        <Flex style={{ padding: 24 }} vertical>
            <Typography.Title level={2}>Recetas</Typography.Title>
            {renderFilters()}
            <Table dataSource={recipes} columns={columns} loading={loading} />
            <NewRecipe open={open} setOpen={setOpen} recipe={selectedRecipe} refresh={() => {
                refresh();
                setOpen(false);
                setSelectedRecipe(null);
                setRequiresProduct(false);
            }}
            requiresProduct={requiresProduct}
            />
            <FloatButton onClick={() => { setOpen(true); setSelectedRecipe(null); setRequiresProduct(true); }}>Nueva receta</FloatButton>
        </Flex>
    );
}

export default RecipeList;