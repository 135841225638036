import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Input, Button, message } from "antd";
import Parse from "parse";
import { useParams } from "react-router-dom";

// Fetch packaging details by UUID
const fetchPackaging = async (uuid) => {
    try {
        const Packaging = Parse.Object.extend("PackagingOrder");
        const query = new Parse.Query(Packaging);
        query.equalTo("uuid", uuid);
        query.include(["product", "packagingEvents", "presentations"]);
        const result = await query.first();
        return result?.toJSON();
    } catch (error) {
        console.error("Error fetching packaging details:", error);
        throw new Error("Failed to fetch packaging details");
    }
};

// Update the packaging order with a new event
const updatePackagingOrder = async ({ packagingOrder, savedEvent }) => {
    try {
        const PackagingOrder = Parse.Object.extend("PackagingOrder");
        const query = new Parse.Query(PackagingOrder);
        query.equalTo("objectId", packagingOrder.objectId);

        const updatedOrder = await query.first();
        if (!updatedOrder) throw new Error("PackagingOrder not found");

        const totalPackaged = savedEvent.get("presentations").reduce((acc, p) => acc + p.amount, 0);

        updatedOrder.addUnique("packagingEvents", savedEvent);
        updatedOrder.increment("packaged", totalPackaged);

        await updatedOrder.save();
    } catch (error) {
        console.error("Error updating packaging order:", error);
        throw new Error("Failed to update packaging order");
    }
};

// Increment stock for a presentation
const incrementPresentationStock = async ({ presentationId, amount }) => {
    try {
        const Presentation = Parse.Object.extend("Presentation");
        const query = new Parse.Query(Presentation);
        query.equalTo("objectId", presentationId);

        const presentation = await query.first();
        if (!presentation) throw new Error("Presentation not found");

        presentation.increment("stock", amount);
        await presentation.save();
    } catch (error) {
        console.error(`Error incrementing stock for presentation ${presentationId}:`, error);
        throw new Error("Failed to increment presentation stock");
    }
};

// Decrement stock for a product
const decrementProductStock = async ({ productId, amount }) => {
    try {
        const Product = Parse.Object.extend("Product");
        const query = new Parse.Query(Product);
        query.equalTo("objectId", productId);

        const product = await query.first();
        if (!product) throw new Error("Product not found");

        product.increment("stock", -amount);
        await product.save();
    } catch (error) {
        console.error(`Error decrementing stock for product ${productId}:`, error);
        throw new Error("Failed to decrement product stock");
    }
};

// Create stock movement for a product
const makeProductStockMovement = async ({ productId, amount }) => {
    try {
        const ProductStockMovement = Parse.Object.extend("StockMovement");
        const movement = new ProductStockMovement();

        movement.set("product", { __type: "Pointer", className: "Product", objectId: productId });
        movement.set("amount", amount);
        movement.set("type", "Salida");
        movement.set("reason", "Envasado");

        await movement.save();
    } catch (error) {
        console.error(`Error creating stock movement for product ${productId}:`, error);
        throw new Error("Failed to create product stock movement");
    }
};

// Create stock movements for presentations
const makePresentationsStockMovement = async ({ presentations }) => {
    try {
        const PresentationStockMovement = Parse.Object.extend("StockMovement");
        const movements = presentations.map((presentation) => {
            const movement = new PresentationStockMovement();

            movement.set("presentation", {
                __type: "Pointer",
                className: "Presentation",
                objectId: presentation.objectId,
            });
            movement.set("amount", presentation.amount);
            movement.set("type", "Entrada");
            movement.set("reason", "Envasado");

            return movement.save();
        });

        await Promise.all(movements);
    } catch (error) {
        console.error("Error creating stock movements for presentations:", error);
        throw new Error("Failed to create presentations stock movements");
    }
};

// Create a new packaging event
const createPackagingEvent = async ({ packagingOrder, presentations, userId }) => {
    try {
        const PackagingEvent = Parse.Object.extend("PackagingEvents");
        const packagingEvent = new PackagingEvent();

        packagingEvent.set("packagingOrder", {
            __type: "Pointer",
            className: "PackagingOrder",
            objectId: packagingOrder.objectId,
        });

        packagingEvent.set("user", {
            __type: "Pointer",
            className: "_User",
            objectId: userId,
        });

        packagingEvent.set(
            "presentations",
            presentations.map((presentation) => ({
                objectId: presentation.objectId,
                amount: presentation.inputQuantity,
            }))
        );

        return await packagingEvent.save();
    } catch (error) {
        console.error("Error creating packaging event:", error);
        throw new Error("Failed to create packaging event");
    }
}

const PackagingDetails = () => {
    const { id } = useParams();
    const [packaging, setPackaging] = useState(null);

    // Refresh the packaging data
    const refresh = () => {
        fetchPackaging(id).then(processPackaging);
    };

    // Process and add pending amounts directly into presentations
    const processPackaging = (result) => {
        if (!result) return;

        const packagingEvents = result.packagingEvents || [];
        const presentations = result.presentations.map((presentation) => {
            const total = presentation.quantity;
            const packaged = packagingEvents.reduce((acc, event) => {
                event?.presentations?.forEach((p) => {
                    if (p.objectId === presentation.objectId) {
                        acc += p.amount;
                    }
                });
                return acc;
            }, 0);
            return {
                ...presentation,
                pending: total - packaged,
                inputQuantity: 0, // Initialize inputQuantity
            };
        });

        setPackaging({ ...result, presentations });
    };

    useEffect(() => {
        refresh();
    }, [id]);

    const handleSave = async () => {
        try {
            const { product, presentations } = packaging;

            // Validate input quantities
            const validPresentations = presentations.filter((p) => p.inputQuantity > 0);
            if (validPresentations.length === 0) {
                message.warning("No hay cantidades válidas para guardar.");
                return;
            }

            // Create the packaging event
            const savedEvent = await createPackagingEvent({
                packagingOrder: packaging,
                presentations: validPresentations,
                userId: Parse.User.current().id,
            });

            // Update the packaging order
            await updatePackagingOrder({ packagingOrder: packaging, savedEvent });

            // Update presentation stocks
            await Promise.all(
                validPresentations.map((presentation) =>
                    incrementPresentationStock({
                        presentationId: presentation.objectId,
                        amount: presentation.inputQuantity,
                    }
                ))
            );

            // Decrease product stock
            const totalVolume = validPresentations.reduce((acc, p) => acc + p.inputQuantity * p.content, 0);
            const totalPackagingAmount = validPresentations.reduce((acc, p) => acc + p.inputQuantity, 0);
            await decrementProductStock({ productId: product.objectId, amount: totalVolume });

            // Create stock movements
            await makeProductStockMovement({ productId: product.objectId, amount: totalPackagingAmount });
            await makePresentationsStockMovement({ presentations: validPresentations });

            message.success("Evento de envasado guardado correctamente");
            refresh();
        } catch (error) {
            console.error("Error saving packaging event:", error);
            message.error("Error al guardar el evento de envasado.");
        }
    };

    // Handle input changes for `inputQuantity`
    const handleInputChange = (value, record) => {
        const updatedPresentations = packaging.presentations.map((presentation) =>
            presentation.objectId === record.objectId
                ? { ...presentation, inputQuantity: value }
                : presentation
        );
        setPackaging({ ...packaging, presentations: updatedPresentations });
    };

    return (
        <Col>
            <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Detalles de Envasado</h1>
            <h2>{packaging?.product?.name}</h2>
            <h4>{packaging?.folio}</h4>
            <Row style={{ marginBottom: "20px" }} gutter={16}>
                <Col span={18}>
                    <Card title="Presentaciones">
                        <Table
                            dataSource={packaging?.presentations}
                            rowKey="objectId"
                            columns={[
                                {
                                    title: "Nombre",
                                    dataIndex: "name",
                                    key: "name",
                                },
                                {
                                    title: "Cantidad",
                                    dataIndex: "quantity",
                                    key: "quantity",
                                },
                                {
                                    title: "Pendiente",
                                    dataIndex: "pending",
                                    key: "pending",
                                },
                                {
                                    title: "Cantidad a agregar",
                                    key: "inputQuantity",
                                    render: (text, record) => (
                                        <Input
                                            disabled={record.pending <= 0}
                                            type="number"
                                            min={0}
                                            max={record.pending}
                                            value={record.inputQuantity}
                                            onChange={(e) =>
                                                handleInputChange(parseFloat(e.target.value) || 0, record)
                                            }
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
            <Button type="primary" onClick={handleSave}>
                Guardar
            </Button>
        </Col>
    );
};

export default PackagingDetails;